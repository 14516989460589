import { useTranslation } from 'react-i18next';
import { BaseStepIdEnum, IndividualRoleEnum } from 'shared-domain';
import { useStore } from 'frontend-common';

import { GroupControl, Select } from '../form';

export const SetApplicant = () => {
  const { t, i18n } = useTranslation();
  const { individuals, updateIndividual, getIndividual } = useStore();

  const defaultValue = individuals.find((individual) =>
    individual.roles.includes(IndividualRoleEnum.applicant),
  );

  const selectApplicant = (id: string) => {
    for (const individual of individuals) {
      individual.id &&
        updateIndividual(individual.id, {
          ...individual,
          roles: individual.roles.filter(
            (role: IndividualRoleEnum) => role !== IndividualRoleEnum.applicant,
          ),
        });
    }

    const individual = getIndividual(id);
    if (individual) {
      updateIndividual(id, {
        ...individual,
        roles: [...individual.roles, IndividualRoleEnum.applicant],
      });
    }
  };

  return (
    <GroupControl
      isRequired={true}
      label={
        t(`steps.individuals_list.set_applicant.label`) ||
        'Amongst these individuals, which one are you?'
      }
      helper={
        i18n.exists(`steps.individuals_list.set_applicant.helper`)
          ? t(`steps.individuals_list.set_applicant.helper`)
          : null
      }
    >
      <Select
        stepId={BaseStepIdEnum.individuals_list}
        name="set_applicant"
        defaultValue={defaultValue?.id ? defaultValue.id : ''}
        options={individuals.map((individual) => {
          return {
            value: individual.id as string,
            label: `${individual.first_name} ${individual.last_name}`,
          };
        })}
        onChange={(id: string) => selectApplicant(id)}
        isTranslatableOptions={false}
      />
    </GroupControl>
  );
};
