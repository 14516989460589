import {
  CompanyInput,
  CustomField,
  CustomProperties,
  FieldEntityTypeEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
  IndividualInput,
  Metadata,
} from 'shared-domain';

export const preSubmitHelper = (
  fields: CustomField[],
  formData: any,
  entityType?: 'company' | 'individual',
): {
  companyData: CompanyInput;
  individualData: IndividualInput;
  caseMetadata: Metadata;
  caseCustomProperties: CustomProperties;
} => {
  const companyData: any = {};
  const individualData: any = {};
  const caseMetadata: any = {};
  const caseCustomProperties: any = {};

  Object.entries(formData).forEach(([key, value]) => {
    if (value !== undefined && value !== null && value !== '') {
      if (
        typeof value === 'object' &&
        !Array.isArray(value) &&
        ![
          'registration_date',
          'birth_date',
          'banking_information',
          'address',
        ].includes(key)
      ) {
        Object.entries(value).forEach(([subKey, subValue]) => {
          const field = fields.find(
            (field: CustomField) => field.id === subKey,
          );

          if (
            (field?.entityType === FieldEntityTypeEnum.individual &&
              field?.propertyType === FieldPropertyTypeEnum.custom) ||
            entityType === 'individual'
          ) {
            if (
              !Array.isArray(subValue) &&
              field?.propertyType === FieldPropertyTypeEnum.custom &&
              (field?.type === FieldTypeEnum.select ||
                field?.type === FieldTypeEnum.country)
            ) {
              individualData[key] = {
                ...individualData[key],
                [subKey]: [subValue],
              };
            } else {
              individualData[key] = {
                ...individualData[key],
                [subKey]: subValue,
              };
            }
          } else if (
            (field?.entityType === FieldEntityTypeEnum.company &&
              field?.propertyType === FieldPropertyTypeEnum.custom) ||
            entityType === 'company'
          ) {
            if (
              !Array.isArray(subValue) &&
              field?.propertyType === FieldPropertyTypeEnum.custom &&
              (field?.type === FieldTypeEnum.select ||
                field?.type === FieldTypeEnum.country)
            ) {
              companyData[key] = {
                ...companyData[key],
                [subKey]: [subValue],
              };
            } else {
              companyData[key] = {
                ...companyData[key],
                [subKey]: subValue,
              };
            }
          }
        });
      } else {
        const field = fields.find(
          (field: CustomField) => field.id === key || field.nested === key,
        );

        if (field?.propertyType === FieldPropertyTypeEnum.metadata) {
          caseMetadata[key] = value;
        } else if (
          field?.entityType === FieldEntityTypeEnum.case &&
          field?.propertyType === FieldPropertyTypeEnum.custom
        ) {
          if (
            !Array.isArray(value) &&
            (field?.type === FieldTypeEnum.select ||
              field?.type === FieldTypeEnum.country)
          ) {
            caseCustomProperties[key] = [value];
          } else {
            caseCustomProperties[key] = value;
          }
        } else if (
          entityType === 'individual' ||
          (field?.entityType === FieldEntityTypeEnum.individual &&
            field?.propertyType === FieldPropertyTypeEnum.default)
        ) {
          individualData[key] = value;
        } else if (
          entityType === 'company' ||
          (field?.entityType === FieldEntityTypeEnum.company &&
            field?.propertyType === FieldPropertyTypeEnum.default)
        ) {
          companyData[key] = value;
        }
      }
    }
  });

  return { companyData, individualData, caseMetadata, caseCustomProperties };
};
