import { CheckResultEnum } from '../../types';

export const documentAnalysisDetailedResultsDefinition: Record<
  CheckResultEnum,
  { labelKey: string; color: string; iconName: string }
> = {
  [CheckResultEnum.approved]: {
    labelKey: 'domain.check.result.approved',
    color: 'green.700',
    iconName: 'check-circle-2',
  },
  [CheckResultEnum.error]: {
    labelKey: 'domain.check.result.error',
    color: 'gray.700',
    iconName: 'circle-slash-2',
  },
  [CheckResultEnum.rejected]: {
    labelKey: 'domain.check.result.rejected',
    color: 'red.700',
    iconName: 'x-circle',
  },
};
