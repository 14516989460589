import { CustomField } from '../types';
import { FieldPropertyTypeEnum } from '../types';

export const defaultMetadataValuesHelper = (
  individualFields: CustomField[],
  metadata: { [key: string]: string | number | boolean | string[] | null },
  firstName: string,
  lastName: string,
) => {
  return (
    individualFields
      // @TODO - OPS-554 - Individual form
      .filter(
        (f: CustomField) => f.propertyType === FieldPropertyTypeEnum.metadata,
      )
      .reduce((acc: any, cur: CustomField) => {
        const arrayMetadata = metadata[cur.id]?.toString().split(' | ');
        const selectedMetadata = arrayMetadata?.find((m: string) =>
          m.includes(`${firstName} ${lastName}: `),
        );
        const value = selectedMetadata
          ?.split(`${firstName} ${lastName}: `)
          .pop();

        if (cur.hasOtherOption) {
          const arrayMetadataOther = metadata[`${cur.id}_other`]
            ?.toString()
            .split(' | ');
          const selectedMetadataOther = arrayMetadataOther?.find((m: string) =>
            m.includes(`${firstName} ${lastName}: `),
          );
          const otherValue = selectedMetadataOther
            ?.split(`${firstName} ${lastName}: `)
            .pop();

          return {
            ...acc,
            [cur.id]: value || '',
            [`${cur.id}_other`]: otherValue || '',
          };
        }
        return { ...acc, [cur.id]: value || '' };
      }, {})
  );
};
