export const resolveRedirectUrlHelper = (
  redirectUrl: string | null,
  caseId: string,
  externalId: string | null,
): string | null => {
  if (!redirectUrl) {
    return null;
  }

  let result = redirectUrl;

  if (redirectUrl.includes('CASE_ID')) {
    if (caseId) {
      result = result.replace('CASE_ID', caseId);
    } else {
      return null;
    }
  }

  if (redirectUrl.includes('EXTERNAL_ID')) {
    if (externalId) {
      result = result.replace('EXTERNAL_ID', externalId);
    } else {
      return null;
    }
  }

  return result;
};
