// @TODO - OPS-9 - Replace Yup by Zod

import * as Yup from 'yup';
import { isRequiredField, optionalStringRule } from './schema.helper';

import { CompanyStatusEnum } from '../types';
import { CustomField } from '../types';
import {
  COMPANY_COMMERCIAL_NAME_MAX_LENGTH,
  COMPANY_NAME_MAX_LENGTH,
} from '../constants';

// Mandatory fields to create a company
const mandatoryFields = Yup.object({
  name: Yup.string().max(COMPANY_NAME_MAX_LENGTH).required().label('Name'),
  registration_number: Yup.string().required().label('Registration number'),
  country: Yup.string().length(2).uppercase().required().label('Country'),
});

export const companySchema = (companyField: CustomField[]) => {
  return mandatoryFields.shape({
    commercial_name: isRequiredField(companyField, 'commercial_name')
      ? Yup.string()
          .max(COMPANY_COMMERCIAL_NAME_MAX_LENGTH)
          .required()
          .label('Commercial name')
      : optionalStringRule
          .max(COMPANY_COMMERCIAL_NAME_MAX_LENGTH)
          .label('Commercial name'),
    legal_form: isRequiredField(companyField, 'legal_form')
      ? Yup.string().required().label('Legal form')
      : optionalStringRule.label('Legal form'),
    status: isRequiredField(companyField, 'status')
      ? Yup.mixed()
          .oneOf(Object.values(CompanyStatusEnum))
          .required()
          .label('Status')
      : Yup.mixed()
          .oneOf(Object.values(CompanyStatusEnum))
          .optional()
          .default(CompanyStatusEnum.not_reported)
          .transform((v) => (v === '' ? CompanyStatusEnum.not_reported : v))
          .label('Status'),
    registration_date: isRequiredField(companyField, 'registration_date')
      ? Yup.date()
          .max(new Date(), 'Registration date must be at earlier than today')
          .required()
          .label('Registration date')
      : Yup.date()
          .max(new Date(), 'Registration date must be at earlier than today')
          .optional()
          .nullable()
          .transform((v) => (!isNaN(v) && v instanceof Date ? v : null))
          .label('Registration date'),
    address: Yup.object({
      street_address: isRequiredField(companyField, 'street_address')
        ? Yup.string().required().label('Street address')
        : optionalStringRule.label('Street address'),
      street_address_2: isRequiredField(companyField, 'street_address_2')
        ? Yup.string().required().label('Street address 2')
        : optionalStringRule.label('Street address 2'),
      postal_code: isRequiredField(companyField, 'postal_code')
        ? Yup.string().required().label('Postal code')
        : optionalStringRule.label('Postal code'),
      city: isRequiredField(companyField, 'city')
        ? Yup.string().required().label('City')
        : optionalStringRule.label('City'),
      state: isRequiredField(companyField, 'state')
        ? Yup.string().required().label('State')
        : optionalStringRule.label('State'),
      region: isRequiredField(companyField, 'region')
        ? Yup.string().required().label('Region')
        : optionalStringRule.label('Region'),
      country: isRequiredField(companyField, 'country', 'address')
        ? Yup.string().length(2).uppercase().required().label('Country')
        : optionalStringRule.length(2).uppercase().label('Country'),
    }),
    banking_information: Yup.object().shape(
      {
        iban: isRequiredField(companyField, 'iban')
          ? Yup.string().min(15).required().label('IBAN')
          : optionalStringRule.min(15).label('IBAN'),
        bic: isRequiredField(companyField, 'bic')
          ? Yup.string().min(8).required().label('BIC')
          : optionalStringRule.min(8).label('BIC'),
      },
      [['iban', 'bic']],
    ),
    tax_identification_number: isRequiredField(
      companyField,
      'tax_identification_number',
    )
      ? Yup.string().required().label('Tax ID')
      : optionalStringRule.label('Tax ID'),
    website_url: isRequiredField(companyField, 'website_url')
      ? Yup.string()
          .url('Website must be a valid URL with protocol (http or https)')
          .required()
          .label('Website')
      : Yup.string()
          .url('Website must be a valid URL with protocol (http or https)')
          .optional()
          .nullable()
          .default(null)
          .label('Website'),
    employer_identification_number: isRequiredField(
      companyField,
      'employer_identification_number',
    )
      ? Yup.string().required().label('Employer ID')
      : optionalStringRule.label('Employer ID'),
    share_capital: isRequiredField(companyField, 'share_capital')
      ? Yup.string().required().label('Share capital')
      : optionalStringRule.label('Share capital'),
    classifications: Yup.array()
      .transform((value, original) => {
        return original;
      })
      .of(
        Yup.object({
          code: isRequiredField(companyField, 'code', 'classifications')
            ? Yup.string().required().label('Classification code')
            : optionalStringRule.label('Classification code'),
          description: isRequiredField(
            companyField,
            'description',
            'classifications',
          )
            ? Yup.string().required().label('Classification description')
            : optionalStringRule.label('Classification description'),
        }),
      )
      .nullable(),
  });
};
