const brandColors = {
  primary: '#5D21D2',
  secondary: '#5D21D2',
};

const sidebarColors = {
  backgroundColor: '#F6F0FD',
  color: '#040222',
  dotfileLogo: '#040222',
};

const linkColors = {
  color: '#0176FF',
};

export const tokens = {
  brand: brandColors,
  sidebar: sidebarColors,
  link: linkColors,
};
