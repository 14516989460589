import { Heading, Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { XCircle } from 'lucide-react';

export const UnexpectedError = () => {
  const { t } = useTranslation();

  return (
    <Box textAlign="center" py={10} px={6} mt={20}>
      <Box display="inline-block">
        <XCircle size={62} />
      </Box>
      <Heading as="h2" size="2xl" textAlign="center">
        {t('domain.unexpected_error.title')}
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        {t('domain.unexpected_error.description')}
      </Text>
    </Box>
  );
};
