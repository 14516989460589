import { DataComparisonLabel } from './data-comparison-label';
import { ValidAge } from './valid-age';
import { AnalysisDetailedResult } from './types';
import {
  DocumentAnalysisRegistrationCertificateDetailedResultsModel,
  DocumentAnalysisRegistrationCertificateInformationModel,
  DocumentAnalysisModelEnum,
  getDocumentAnalysisDefinition,
} from 'shared-domain';
import { nameFormat } from 'shared-common';
import { ValidDocumentType } from './valid-document-type';

const definition = getDocumentAnalysisDefinition(
  DocumentAnalysisModelEnum.registration_certificate,
);

export const analysisRegistrationCertificateDetailedResults: AnalysisDetailedResult<
  DocumentAnalysisRegistrationCertificateDetailedResultsModel,
  DocumentAnalysisRegistrationCertificateInformationModel
> = {
  valid_age_in_days: ({ detailedResult, information }) => (
    <ValidAge
      detailedResult={detailedResult}
      issuingDate={information.issuing_date}
      informationDefinition={definition.information.issuing_date}
    />
  ),
  name_matching: ({ detailedResult, entity }) => (
    <DataComparisonLabel
      informationDefinition={definition.information.name}
      detailedResult={detailedResult}
      entityName={
        entity.company?.name ??
        nameFormat(entity.individual?.first_name, entity.individual?.last_name)
      }
    />
  ),
  registration_number_matching: ({ detailedResult, entity }) => (
    <DataComparisonLabel
      informationDefinition={definition.information.registration_number}
      detailedResult={detailedResult}
      entityName={
        entity.company?.name ??
        nameFormat(entity.individual?.first_name, entity.individual?.last_name)
      }
    />
  ),
  address_matching: ({ detailedResult, entity }) => (
    <DataComparisonLabel
      informationDefinition={definition.information.address}
      detailedResult={detailedResult}
      entityName={
        entity.company?.name ??
        nameFormat(entity.individual?.first_name, entity.individual?.last_name)
      }
    />
  ),
  valid_document_type: ({ detailedResult, entity }) => (
    <ValidDocumentType
      definition={definition}
      detailedResult={detailedResult}
      entity={entity}
    />
  ),
};
