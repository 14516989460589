import { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  allAcceptedFileTypes,
  Check,
  CheckStatusEnum,
  CheckTypeEnum,
  Company,
  Individual,
  MAX_FILE_PER_DOCUMENT_DATA,
} from 'shared-domain';
import { useApi, useConfigContext, useStore } from 'frontend-common';
import { FormDocumentList } from '../form/form-document-list';
import { FormDocumentItem } from '../form/form-document-item';
import { datadogRum } from '@datadog/browser-rum';
import { DocumentAnalysisReport } from './document-analysis';
import { Accept } from 'react-dropzone';

export interface FilesFormValues {
  files: { file: File | string }[];
}

const validationSchema = Yup.object().shape({
  files: Yup.array(Yup.object({ file: Yup.string() }))
    .optional()
    .min(1)
    .max(MAX_FILE_PER_DOCUMENT_DATA),
});

type ModalDocumentProps = {
  currentCheck: Check;
  currentEntity: Individual | Company;
  fetchMyAPI: () => void;
  onDocumentAnalysisUpdate: (check: Check) => void;
  isOpen: boolean;
  onClose: () => void;
};

export const ModalDocument = (props: ModalDocumentProps) => {
  const { currentCheck, currentEntity, fetchMyAPI, isOpen, onClose } = props;
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const api = useApi();
  const [check, setCheck] = useState(currentCheck);
  const store = useStore();
  const { documentAnalysisConfig } = useConfigContext();

  const defaultValues: FilesFormValues = {
    files:
      check.type === CheckTypeEnum.id_document
        ? [{ file: '' }, { file: '' }]
        : [{ file: '' }],
  };

  const methods = useForm<FilesFormValues>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues,
    // @TODO - OPS-9 - Replace Yup by Zod
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isValid, isDirty, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<FilesFormValues> = async () => {
    const formData = new FormData();
    formData.append('checkId', check.id);
    formData.append('type', check.type);

    // document analysis settings from config
    const documentAnalysisConfigForThisCheck =
      documentAnalysisConfig?.[
        check.subtype as keyof typeof documentAnalysisConfig
      ]?.(store) ?? null;

    if (documentAnalysisConfigForThisCheck) {
      formData.append(
        'documentAnalysis',
        JSON.stringify({
          document_analysis: documentAnalysisConfigForThisCheck,
        }),
      );
    }

    for (let i = 0; i < getValues('files').length; i++) {
      if (getValues('files')[i].file) {
        formData.append('files[]', getValues('files')[i].file);
      }
    }

    const { data } = await api
      .post<Check>(
        check.type === CheckTypeEnum.id_document
          ? `/dotfile/identity_documents`
          : `/dotfile/documents`,
        formData,
      )
      .then((response) => {
        setCheck(response.data);
        return response;
      })
      .catch((err) => {
        const error = {
          type: err.response.status?.toString(),
          message: err.response.data.message,
        };
        datadogRum.addError(error);

        reset(defaultValues);
        onClose();
        toast({
          title: 'An error occurred',
          description: 'Unable to add the file.',
          variant: 'toast_error',
          status: 'error',
          isClosable: false,
          position: 'bottom-right',
        });
        return { data: null };
      });

    reset(defaultValues);

    if (data) {
      props.onDocumentAnalysisUpdate(data);
    }

    if (data?.status !== CheckStatusEnum.in_progress) {
      await fetchMyAPI();
      onClose();
    }
  };

  const exactType =
    check.type === CheckTypeEnum.document && check.subtype
      ? check.subtype.split(':')[1]
      : check.type;

  // @NOTE Add a fallback title when a custom document type created after the onboarding flow init
  const checkTitleFallBack = useMemo(() => {
    if (
      check.type === CheckTypeEnum.document &&
      check.subtype?.includes('custom_document_type')
    ) {
      return check.data.settings.custom_document_type?.label ?? '';
    }

    return check.type;
    // eslint-disable-next-line
  }, [check.type]);

  const accept = useMemo(() => {
    // Only accept image and pdf for id_document
    if (check.type === CheckTypeEnum.id_document) {
      const acceptedIDDFiles: Accept = {
        'image/*': allAcceptedFileTypes
          .filter((aft) => aft.mimeType.startsWith('image/'))
          .flatMap((aft) => aft.extensions),
        'application/pdf': [],
      };

      return acceptedIDDFiles;
    }

    // accept all document type
    return undefined;
  }, [check.type]);

  return (
    <Modal
      isOpen={isOpen}
      size={['full', 'full', 'xl']}
      onClose={() => {
        reset(defaultValues);
        onClose();
      }}
      isCentered
      scrollBehavior="outside"
      closeOnEsc={!isSubmitting}
      closeOnOverlayClick={!isSubmitting}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t(`checks.${exactType}.title`, checkTitleFallBack as string)}
        </ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody>
          <FormControl>
            <FormProvider {...methods}>
              <DocumentAnalysisReport
                submitLoading={isSubmitting}
                entity={currentEntity}
                check={check}
                onClose={onClose}
                refetch={async (check) => {
                  setCheck(check);
                  await fetchMyAPI();
                }}
              />
              <FormLabel>
                {t(`checks.${exactType}.title`, checkTitleFallBack as string)}
              </FormLabel>
              {i18n.exists(`checks.${exactType}.description`) && (
                <FormHelperText mt="0" mb="2">
                  {t(`checks.${exactType}.description`)}
                </FormHelperText>
              )}

              {i18n.exists(`checks.${exactType}.callout`) && (
                <Alert status="info" mb="6">
                  <AlertIcon />
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: t(`checks.${exactType}.callout`) ?? '',
                    }}
                  ></Text>
                </Alert>
              )}
              {check.type === CheckTypeEnum.id_document && (
                <>
                  <FormDocumentItem
                    index={0}
                    accept={accept}
                    label={t('upload_document_front')}
                  />
                  <FormDocumentItem
                    index={1}
                    accept={accept}
                    label={t('upload_document_back')}
                  />
                </>
              )}
              {check.type === CheckTypeEnum.document && (
                <FormDocumentList
                  accept={accept}
                  label={t('upload_document')}
                  maxItem={MAX_FILE_PER_DOCUMENT_DATA}
                />
              )}
            </FormProvider>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Stack direction={['column-reverse', 'row']} spacing={4}>
            <Button variant="outline" onClick={onClose}>
              {t('domain.form.cancel')}
            </Button>
            <Button
              variant="solid"
              isLoading={isSubmitting}
              isDisabled={!isValid || !isDirty}
              onClick={handleSubmit(onSubmit)}
            >
              {t('send_documents')}
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
