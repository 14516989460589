import { format } from 'date-fns';
import { CustomProperties, IndividualInput, Metadata } from 'shared-domain';
import { useStore } from './use-store';
import { useSubmit } from './use-submit';

type SubmitIndividualForm = {
  individualData: IndividualInput;
  individualId?: string | null;
  caseMetadata?: Metadata;
  caseCustomProperties?: CustomProperties;
  onClose?: () => void;
};

export const useSubmitIndividualForm = (): {
  submitIndividualForm: (input: SubmitIndividualForm) => void;
} => {
  const submitStep = useSubmit();
  const {
    metadata,
    customProperties,
    updateIndividual,
    addIndividual,
    updateMetadata,
    updateCustomProperties,
  } = useStore();

  const submitIndividualForm = (input: SubmitIndividualForm) => {
    // Save individual data
    const { birth_date, ...individualData } = input.individualData;
    let birthDateFormatted = null;
    if (birth_date) {
      birthDateFormatted = format(Date.parse(birth_date), 'yyyy-MM-dd');
    }
    if (input.individualId) {
      if (birthDateFormatted) {
        updateIndividual(input.individualId, {
          ...individualData,
          birth_date: birthDateFormatted,
        });
      } else {
        updateIndividual(input.individualId, input.individualData);
      }
    } else {
      if (birthDateFormatted) {
        addIndividual({
          ...individualData,
          birth_date: birthDateFormatted,
        });
      } else {
        addIndividual(input.individualData);
      }
    }

    // Save case metadata
    if (input.caseMetadata) {
      if (Object.keys(input.caseMetadata).length > 0) {
        const newMetadata = {};
        Object.entries(input.caseMetadata).forEach(([key, value]) => {
          if (value) {
            if (metadata[key]) {
              const arrayMetadata = metadata[key]?.toString().split(' | ');
              if (arrayMetadata) {
                const selectedMetadata = arrayMetadata.findIndex((m: string) =>
                  m.includes(
                    `${input.individualData.first_name} ${input.individualData.last_name}: `,
                  ),
                );
                if (selectedMetadata !== -1) {
                  arrayMetadata.splice(selectedMetadata, 1);
                }

                if (arrayMetadata.length > 0) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  newMetadata[key] = `${arrayMetadata.join(' | ')} | ${
                    input.individualData.first_name
                  } ${input.individualData.last_name}: ${value}`;
                } else {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  newMetadata[key] =
                    `${input.individualData.first_name} ${input.individualData.last_name}: ${value}`;
                }
              }
            } else {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              newMetadata[key] =
                `${input.individualData.first_name} ${input.individualData.last_name}: ${value}`;
            }
          }
        });

        updateMetadata({ ...metadata, ...newMetadata });
      }
    }

    // Save case custom properties
    if (input.caseCustomProperties) {
      updateCustomProperties({
        ...customProperties,
        ...input.caseCustomProperties,
      });
    }

    // Submit step
    if (input.onClose) {
      input.onClose();
    } else {
      submitStep();
    }
  };

  return { submitIndividualForm };
};
