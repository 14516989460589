import { useState } from 'react';
import { Select, useToken } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { datadogRum } from '@datadog/browser-rum';
import { useConfigContext, useEnvironmentContext } from 'frontend-common';
import { EnvEnum } from 'shared-domain';

export const SelectLang = () => {
  const config = useConfigContext();
  const { t } = useTranslation();
  const environment = useEnvironmentContext();
  const [selectedLang, setSelectedLang] = useState(i18next.languages[0]);
  const [sidebarBgColor, sidebarColor] = useToken('colors', [
    'sidebar.backgroundColor',
    'sidebar.color',
  ]);

  const changeHandler = async (e: any) => {
    setSelectedLang(e.target.value);
    await i18next.changeLanguage(e.target.value);
    if (environment.env !== EnvEnum.dev) {
      datadogRum.setGlobalContext({
        language: e.target.value,
      });
    }
  };

  return (
    <Select
      border="0"
      color="sidebar.color"
      variant="flushed"
      value={selectedLang}
      onChange={changeHandler}
      name="lang"
    >
      {config.languages.map((language: string, i: number) => (
        <option
          key={i}
          value={language}
          style={{ background: sidebarBgColor, color: sidebarColor }}
        >
          {t(`domain.languages.${language}`)}
        </option>
      ))}
    </Select>
  );
};
