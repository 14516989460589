import { Box, Input } from '@chakra-ui/react';
import { generatedFieldName } from 'frontend-common';
import { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledInputProps } from '../type';
import { Checkbox, GroupController } from '../../form';
import { OTHER } from './utils';

export const ControlledCheckbox: FunctionComponent<ControlledInputProps> = ({
  stepId,
  field,
}) => {
  const { getValues, setValue, control, watch } = useFormContext();
  const { t } = useTranslation();

  const fieldName = generatedFieldName(field);
  const value = watch(fieldName);
  if (value && !value.includes(OTHER) && getValues(`${fieldName}_${OTHER}`)) {
    setValue(`${fieldName}_${OTHER}`, '');
  }

  return (
    <Box w="100%" key={fieldName}>
      <GroupController
        name={fieldName}
        label={`${t(`steps.${stepId}.${fieldName}.label`) || fieldName}`}
        helper={
          field.hasHelper ? t(`steps.${stepId}.${fieldName}.helper`) : null
        }
        control={control}
        isRequired={field.isRequired}
        render={(f) => (
          <Checkbox
            stepId={stepId}
            name={fieldName}
            onChange={(values: string[]) => {
              setValue(fieldName, values ?? [], {
                shouldDirty: true,
                shouldValidate: true,
              });
            }}
            options={field.options || []}
            defaultValue={f.value}
            hasOtherOption={field.hasOtherOption}
            isRequired={field.isRequired}
            forceAllChecked={field.forceAllChecked}
          />
        )}
      />
      {value && value.includes(OTHER) && (
        <GroupController
          mt={5}
          name={`${fieldName}_${OTHER}`}
          label={t(`steps.${stepId}.${fieldName}.${OTHER}_option.label`)}
          isRequired={true}
          control={control}
          render={(f) => {
            return <Input type="text" maxW="400px" {...f} />;
          }}
        />
      )}
    </Box>
  );
};
