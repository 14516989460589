export enum DocumentTypeEnum {
  articles_of_association = 'articles_of_association',
  registration_certificate = 'registration_certificate',
  ubo_registry_extract = 'ubo_registry_extract',
  financial_statements = 'financial_statements',
  proof_of_insurance = 'proof_of_insurance',
  id_document = 'id_document',
  driving_license = 'driving_license',
  proof_of_address = 'proof_of_address',
  visa = 'visa',
  social_security_card = 'social_security_card',
  birth_certificate = 'birth_certificate',
  vehicle_registration_certificate = 'vehicle_registration_certificate',
  bank_details = 'bank_details',
  criminal_record_extract = 'criminal_record_extract',
  tax_notice = 'tax_notice',
}
