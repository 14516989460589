import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { PropsWithChildren, ReactElement } from 'react';

type EmotionCacheProviderProps = PropsWithChildren<{
  nonce: string;
}>;

export const EmotionCacheProvider = ({
  nonce,
  children,
}: EmotionCacheProviderProps): ReactElement => {
  const cache = createCache({ key: 'dot-ds', nonce });
  return <CacheProvider value={cache}>{children}</CacheProvider>;
};
