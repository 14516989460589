import React, {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';

export type Loading = {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

export const LoadingContext = createContext<Loading>({
  loading: false,
  setLoading: () => false,
});

export const useLoadingContext = (): Loading => {
  const loading = useContext(LoadingContext);

  if (!loading) {
    const error = new Error(
      'useEnvironmentContext: `environment` is falsy. Seems you forgot to wrap component within the EnvironmentContextProvider or initialize the context value',
    );
    error.name = 'EnvironmentContextError';
    Error.captureStackTrace?.(error, useContext);
    throw error;
  }

  return loading;
};

export const LoadingContextProvider = ({
  children,
}: PropsWithChildren): ReactElement => {
  const [loading, setLoading] = useState(false);
  const value = { loading, setLoading };

  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  );
};
