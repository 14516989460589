import { useTranslation } from 'react-i18next';
import { BaseStepIdEnum } from 'shared-domain';
import { useStore } from 'frontend-common';
import { GroupControl, Select } from '../form';

export const SetDelegator = () => {
  const { t, i18n } = useTranslation();
  const { individuals, updateIndividual, getIndividual } = useStore();

  const defaultValue = individuals.find(
    (individual) => individual?.is_delegator === true,
  );

  const selectSetDelegator = (id: string) => {
    for (const individual of individuals) {
      individual.id &&
        updateIndividual(individual.id, {
          ...individual,
          is_delegator: false,
        });
    }

    const individual = getIndividual(id);
    if (individual) {
      updateIndividual(id, {
        ...individual,
        is_delegator: true,
      });
    }
  };

  return (
    <GroupControl
      isRequired={true}
      label={
        i18n.exists(`steps.individuals_list.set_delegator.label`)
          ? t(`steps.individuals_list.set_delegator.label`)
          : 'If you are not a legal representative, who gave you power of attorney? (Select yourself if you are a legal representative)'
      }
      helper={
        i18n.exists(`steps.individuals_list.set_delegator.helper`)
          ? t(`steps.individuals_list.set_delegator.helper`)
          : null
      }
    >
      <Select
        stepId={BaseStepIdEnum.individuals_list}
        name="set_delegator"
        defaultValue={defaultValue?.id ? defaultValue.id : ''}
        options={individuals.map((individual) => {
          return {
            value: individual.id as string,
            label: `${individual.first_name} ${individual.last_name}`,
          };
        })}
        onChange={(id: string) => selectSetDelegator(id)}
        isTranslatableOptions={false}
      />
    </GroupControl>
  );
};
