import { upperFirst, camelCase } from 'lodash';
import { ReactNode } from 'react';
import {
  Check,
  CheckResultEnum,
  DocumentAnalysisEntity,
  DocumentAnalysisInformationModel,
  DocumentAnalysisModelEnum,
  documentAnalysisDetailedResultsDefinition,
  DocumentAnalysisDetailedResultModel,
} from 'shared-domain';
import { getAnalysisDetailedResults } from './get-analysis-detailed-results';
import { HStack, Icon, VStack } from '@chakra-ui/react';
import * as lucide from 'lucide-react';

const { createLucideIcon /* omitted */, ...lucideIcons } = lucide;

export const DocumentCheckAnalysisDetailedResults = ({
  check,
  entity,
}: {
  entity: DocumentAnalysisEntity;
  check: Check;
}) => {
  const checkData = check.data;

  const detailedResults = checkData?.detailed_results?.document_analysis;
  const information = checkData?.information?.document_analysis;
  const settings = checkData?.settings?.document_analysis;

  if (!detailedResults || !information || !settings) return null;

  const model = settings.parameters.model as DocumentAnalysisModelEnum;

  const definition = getAnalysisDetailedResults(model);
  const results: {
    component: ReactNode;
    key: string;
    result: CheckResultEnum | null;
  }[] = Object.keys(definition).map((key) => {
    const k = key as keyof typeof definition;
    return {
      key,
      component:
        /**
         * We need to redefine definition function to accept
         * any DocumentAnalysisInformationModel as information.
         */
        (
          definition[k] as (data: {
            detailedResult: DocumentAnalysisDetailedResultModel;
            information: DocumentAnalysisInformationModel;
            entity: DocumentAnalysisEntity;
          }) => ReactNode
        )({
          detailedResult: detailedResults[k],
          information,
          entity,
        }),
      result: detailedResults[k].result,
    };
  });

  return (
    <VStack pl="2.5" width="full" gap={2}>
      {results
        .filter(
          (
            data,
          ): data is {
            component: ReactNode;
            key: string;
            result: CheckResultEnum;
          } => data.result !== null,
        )
        .filter(({ result }) => result !== CheckResultEnum.approved)
        .map(({ key, component, result }) => {
          const iconName = upperFirst(
            camelCase(
              documentAnalysisDetailedResultsDefinition[result].iconName,
            ),
          );
          const icon =
            iconName in lucideIcons
              ? lucideIcons[iconName as keyof typeof lucideIcons]
              : null;

          if (!icon) {
            console.warn(`Could not find icon with name '${iconName}'`);
          }

          return (
            <HStack key={key} width="full" px="2">
              <Icon
                boxSize={4}
                as={icon as lucide.LucideIcon}
                color={documentAnalysisDetailedResultsDefinition[result].color}
                mr="2"
              />
              {component}
            </HStack>
          );
        })}
    </VStack>
  );
};
