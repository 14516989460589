import { Heading, Text, Box, Button, Code, Link } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useConfigContext } from 'frontend-common';

type NotFoundProps = { errorCode?: string };

export const NotFound = (props: NotFoundProps) => {
  const { errorCode } = props;

  const config = useConfigContext();
  const { t } = useTranslation();

  const url = new URL(window.location.origin);
  url.searchParams.set('new', 'true');

  return (
    <Box
      borderRadius="8"
      w="100%"
      borderColor="gray.700"
      border="1px"
      p="6"
      bg="gray.100"
    >
      <Heading mb="4" size="md">
        {t('domain.not_found.title')}
      </Heading>
      <Box mb="6">
        <Text>
          {errorCode && config.supportLink
            ? t('domain.not_found.code.description')
            : t('domain.not_found.description')}
        </Text>
        <Code>{errorCode}</Code>
      </Box>
      {errorCode && config.supportLink ? (
        <Button as={Link} href={config.supportLink} target="_blank">
          {t('domain.not_found.code.button')}
        </Button>
      ) : (
        <Button onClick={() => (window.location.href = url.href)}>
          {t('domain.not_found.button')}
        </Button>
      )}
    </Box>
  );
};
