import { useTranslation } from 'react-i18next';
import { Flex, Text, useToken, VStack } from '@chakra-ui/react';
import { useConfigContext, useStore } from 'frontend-common';

import { DotfileLogo } from '../assets';
import { CopyableText } from '../data-display';
import { SelectLang } from './select-lang';

export const Footer = () => {
  const { t } = useTranslation();
  const config = useConfigContext();
  const [logoColor] = useToken('colors', ['sidebar.dotfileLogo']);
  const { caseId } = useStore();

  return (
    <VStack background="sidebar.backgroundColor" p="6" spacing="4" mt="auto">
      {caseId && (
        <CopyableText
          label={t('steps.checks_list.copy.label')}
          value={`${window.location.origin}?caseId=${caseId}`}
        />
      )}
      {config.languages.length > 1 && <SelectLang />}
      {config.showPoweredLogo && (
        <Flex alignItems="center">
          <Text
            color="sidebar.color"
            mr="5px"
            fontSize="12px"
            lineHeight="2Opx"
            fontWeight="500"
            display="inline-block"
          >
            <b>Powered by</b>
          </Text>
          <DotfileLogo color={logoColor} />
        </Flex>
      )}
    </VStack>
  );
};
