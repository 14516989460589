import {
  RadioGroup,
  Radio as ChakraRadio,
  VStack,
  Icon,
  Tooltip,
} from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

type RadioProps = {
  stepId: string;
  name: string;
  defaultValue?: string;
  value?: string;
  options: string[];
  onChange: any;
};

export const Radio = (props: RadioProps) => {
  const { stepId, name, defaultValue, value, options, onChange } = props;

  const { t, i18n } = useTranslation();

  const showTooltip = (option: string) =>
    i18n.exists(`steps.${stepId}.${name}.tooltips.${option}`);

  return (
    <RadioGroup
      onChange={(value) => onChange(value)}
      name={name}
      defaultValue={defaultValue}
      value={value}
    >
      <VStack spacing="5" alignItems="start">
        {options.map((option: string) => (
          <ChakraRadio key={option} value={option}>
            {t(`steps.${stepId}.${name}.options.${option}`)}
            {showTooltip(option) && (
              <Tooltip label={t(`steps.${stepId}.${name}.tooltips.${option}`)}>
                <Icon style={{ marginLeft: '8px' }}>
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
                </Icon>
              </Tooltip>
            )}
          </ChakraRadio>
        ))}
      </VStack>
    </RadioGroup>
  );
};
