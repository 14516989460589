import { useEffect, useState } from 'react';
import { EditIcon } from 'lucide-react';
import { SimpleGrid, Button, Text, Radio, Stack, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { datadogRum } from '@datadog/browser-rum';
import { CompanySearch } from 'shared-domain';
import { useApi, useStore } from 'frontend-common';

import { LoadingSpinner } from '../feedback';

export const CompaniesList = () => {
  const { t } = useTranslation();
  const {
    queryParams,
    company,
    companies,
    increaseCurrentStep,
    updateCompany,
    addIndividuals,
    addAffiliatedCompanies,
    resetAffiliatedCompanies,
    resetIndividuals,
    addCompanies,
    updateQueryParams,
  } = useStore();
  const api = useApi();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getCompanies = async () => {
    const params = {
      ...(company?.country && {
        country: company.country,
      }),
      ...(company?.registration_number && {
        registration_number: company.registration_number,
      }),
      ...(company?.name && {
        name: company.name,
      }),
    };

    await api
      .get(`dotfile/companies?${new URLSearchParams(params).toString()}`)
      .then((response) => {
        if (
          response.data.data.length === 0 &&
          queryParams.registrationNumber &&
          company?.registration_number
        ) {
          // Go to the search company step when the registration number in query param is bad
          // Reset registration number to avoid looping on company search and list
          // previousStep();
          updateQueryParams({ ...queryParams, registrationNumber: null });
        } else if (response.data.data.length > 0) {
          addCompanies(response.data.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        addCompanies([]);
        const error = {
          type: err.response?.status?.toString(),
          message: err.response?.data?.message,
        };
        datadogRum.addError(error);
      });
  };

  useEffect(() => {
    if (!companies || companies.length === 0) {
      getCompanies();
    } else {
      setIsLoading(false);
    }
  }, []);

  const selectCompany = async (searchRef: string | null) => {
    updateCompany({
      name: company ? company.name : null,
      country: company ? company.country : null,
      registration_number: null,
    });
    resetAffiliatedCompanies();
    resetIndividuals();
    if (searchRef) {
      setIsLoading(true);

      const response = await api.get(`/dotfile/companies/${searchRef}`);
      updateCompany({ ...company, ...response.data });
      addIndividuals(response.data.merged_individuals);
      addAffiliatedCompanies(response.data.merged_affiliated_companies);

      setIsLoading(false);
    }
    increaseCurrentStep();
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Stack spacing="10" pt="2">
      <SimpleGrid columns={1} spacing="5">
        {companies.length === 0 ? (
          <Text pb="5">{t('steps.company_list.no_result')}</Text>
        ) : (
          companies.map((company: CompanySearch) => (
            <Button
              key={company.search_ref}
              variant="select"
              onClick={() => selectCompany(company.search_ref)}
              justifyContent="flex"
              isTruncated
              h="auto"
            >
              <Radio value="1" py="3">
                <Text textAlign="left" isTruncated>
                  {company.name}{' '}
                  {company.address?.postal_code &&
                    `(${company.address.postal_code})`}{' '}
                </Text>
                <Text color="gray.500" textAlign="left">
                  {company.registration_number}
                </Text>
              </Radio>
            </Button>
          ))
        )}
      </SimpleGrid>
      <Box>
        {companies.length > 0 && (
          <Text pb={5}>{t('steps.company_list.not_found')}</Text>
        )}
        <Button
          leftIcon={<EditIcon size="16" />}
          variant="fill"
          onClick={() => selectCompany(null)}
        >
          {t('steps.company_list.button')}
        </Button>
      </Box>
    </Stack>
  );
};
