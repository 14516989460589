import { useCallback } from 'react';
import { useConfigContext } from '../contexts';
import { useStore } from './use-store';

export const useProgress = (): {
  saveProgress: () => void;
  getProgress: () => void;
  removeProgress: () => void;
  saveConfig: () => void;
} => {
  const {
    totalSteps,
    currentStep,
    metadata,
    customProperties,
    company,
    affiliatedCompanies,
    individuals,
    updateTotalSteps,
    updateCurrentStep,
    updateMetadata,
    updateCustomProperties,
    updateCompany,
    addAffiliatedCompanies,
    addIndividuals,
  } = useStore();
  const config = useConfigContext();

  const saveConfig = useCallback(() => {
    localStorage.setItem('config', JSON.stringify(config));
  }, [config]);

  const saveProgress = useCallback(() => {
    const state = {
      totalSteps,
      currentStep,
      metadata,
      customProperties,
      company,
      affiliatedCompanies,
      individuals,
    };
    localStorage.setItem('progress', JSON.stringify(state));
    saveConfig();
  }, [
    totalSteps,
    currentStep,
    metadata,
    customProperties,
    company,
    affiliatedCompanies,
    individuals,
    saveConfig,
  ]);

  const getProgress = useCallback(() => {
    const progress = localStorage.getItem('progress');
    const storedConfig = localStorage.getItem('config');

    if (progress) {
      const state = JSON.parse(progress);

      if (storedConfig && JSON.stringify(config) !== storedConfig) {
        // Redirect user to the first step when the config has changed
        updateCurrentStep(1);
        updateTotalSteps(config.stepsConfig.length);
      } else {
        updateTotalSteps(state.totalSteps);
        updateCurrentStep(state.currentStep);
      }

      updateMetadata(state.metadata);
      updateCustomProperties(state.customProperties);
      updateCompany(state.company);
      addAffiliatedCompanies(state.affiliatedCompanies);
      addIndividuals(state.individuals);
    }
  }, [config]);

  const removeProgress = useCallback(() => {
    localStorage.removeItem('progress');
    localStorage.removeItem('config');
  }, []);

  return { saveProgress, getProgress, removeProgress, saveConfig };
};
