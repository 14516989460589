import { z } from 'zod';
import { CUSTOM_PROPERTY_TEXT_VALUE_MAX_LENGTH } from '../constants';

export const customPropertiesSchema = z.record(
  z.string(),
  z
    .union([
      z.string().max(CUSTOM_PROPERTY_TEXT_VALUE_MAX_LENGTH),
      z.boolean(),
      z.number(),
      z.array(z.string()).nullable(),
    ])
    .nullish(),
);
