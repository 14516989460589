import React, { useEffect, ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Flex, Stack, useMediaQuery } from '@chakra-ui/react';
import type { StepConfig } from 'shared-domain';
import { match } from 'ts-pattern';
import { BaseStepIdEnum, StepTypeEnum } from 'shared-domain';
import {
  useConfigContext,
  useProgress,
  useNavigation,
  useStore,
  useSubmit,
  useDetailedCase,
  useRequiredParams,
} from 'frontend-common';

import {
  AffiliatedCompaniesList,
  CompaniesList,
  CompanyEdit,
  CompanySearch,
  CustomForm,
  IndividualEdit,
  IndividualsList,
  PdfViewer,
} from './steps';
import { LoadingSpinner } from './feedback';
import { Footer, Header } from './layout';
import { ChecksList } from './checks';
import { NotFound } from './error';

type Step = { id: string; content: ReactNode };

export const App = () => {
  const [searchParams] = useSearchParams();
  const config = useConfigContext();
  const { t, i18n } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 48em)');
  const {
    queryParams,
    currentStep,
    totalSteps,
    caseId,
    metadata,
    company,
    showHeader,
    resumeFlow,
    updateQueryParams,
    updateTotalSteps,
    updateCaseId,
    updateCompany,
  } = useStore();
  const submitStep = useSubmit();
  const { saveProgress, getProgress, removeProgress } = useProgress();
  const { fetchCase, error: fetchError, loading } = useDetailedCase();

  const { skipCompanySearchStep, skipCompanyEditStep } = useNavigation();

  const { error } = useRequiredParams();

  useEffect(() => {
    fetchCase(queryParams.externalId || caseId, !!searchParams.get('caseId'));
    // fetchCase as dependency trigger a render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.externalId, caseId]);

  useEffect(() => {
    if (searchParams.get('externalId')) {
      localStorage.removeItem('caseId');
    }
  }, [queryParams, searchParams]);

  useEffect(() => {
    if (searchParams.get('new')) {
      localStorage.removeItem('caseId');
      removeProgress();
    }
  }, [queryParams, removeProgress, searchParams]);

  useEffect(() => {
    updateQueryParams({
      caseId: searchParams.get('caseId') || null,
      email: searchParams.get('email') || null,
      company: searchParams.get('company') || null,
      registrationNumber: searchParams.get('registrationNumber') || null,
      country: searchParams.get('country') || null,
      externalId: searchParams.get('externalId') || null,
      lng: searchParams.get('lng') || null,
      templateId: searchParams.get('templateId') || null,
      firstName: searchParams.get('firstName') || null,
      lastName: searchParams.get('lastName') || null,
    });

    updateCaseId(
      searchParams.get('new') === 'true'
        ? null
        : searchParams.get('caseId') || localStorage.getItem('caseId'),
    );

    const companyEditStep = config.stepsConfig.find(
      (step: StepConfig) => step.id === BaseStepIdEnum.company_edit,
    );

    if (companyEditStep && currentStep === 1) {
      let registrationNumber = searchParams.get('registrationNumber');
      const country = searchParams.get('country');

      // Only for FRENCH companies, transform the SIRET(14) to SIREN(9) and store it in the registrationNumber
      if (country === 'FR' && registrationNumber?.length === 14) {
        registrationNumber = registrationNumber.slice(0, -5);
      }

      updateCompany({
        name: searchParams.get('company'),
        country: country ? country.toUpperCase() : null,
        registration_number: registrationNumber,
      });

      // Skip company edit step when query params filled all fields
      //  it's useful for Defacto
      if (steps[currentStep - 1]?.id === BaseStepIdEnum.company_edit) {
        skipCompanyEditStep();
      }
    }
  }, [searchParams]);

  useEffect(() => {
    // Skip search company step when the registration number is set in query param and there is no caseId
    !caseId && skipCompanySearchStep();
  }, [company, currentStep, queryParams, caseId, skipCompanySearchStep]);

  useEffect(() => {
    // Submit onboarding when last step
    if (!caseId && currentStep > totalSteps) {
      submitStep();
    }
    if (currentStep > 1) {
      saveProgress();
    }
  }, [caseId, currentStep, metadata]);

  useEffect(() => {}, [currentStep]);

  useEffect(() => {
    if (currentStep === 1) {
      getProgress();
    }
  }, [totalSteps, getProgress]);

  useEffect(() => {
    // if (caseId && resumeFlow) {
    //   // Remove progress in local storage
    //   removeProgress();
    //   updateCurrentStep(1);
    //   updateTotalSteps(1);
    // } else {
    updateTotalSteps(config.stepsConfig.length);
    // }
  }, [caseId, config.stepsConfig]);

  let steps: Step[] = [];

  if (!caseId || resumeFlow) {
    steps = config.stepsConfig.map((step: StepConfig) => {
      return (
        match(step)
          // By type
          .with({ type: StepTypeEnum.custom_component }, (step) => {
            const Component = step.component;
            return {
              ...step,
              content: <Component />,
            };
          })
          .with({ type: StepTypeEnum.terms_and_conditions }, (step) => ({
            ...step,
            content: <PdfViewer pdfUrl={step.config.pdfUrl} />,
          }))
          .with({ type: StepTypeEnum.form }, (step) => ({
            ...step,
            content: <CustomForm fields={step.config.fields} />,
          }))
          .with({ type: StepTypeEnum.text }, (step) => ({
            ...step,
            content: (
              // @TODO - TDB - Specific component for text-only step
              <CustomForm fields={[]} />
            ),
          }))
          // By base id
          .with({ id: BaseStepIdEnum.company_search }, (step) => ({
            ...step,
            content: <CompanySearch />,
          }))
          .with({ id: BaseStepIdEnum.company_list }, (step) => ({
            ...step,
            content: <CompaniesList />,
          }))
          .with({ id: BaseStepIdEnum.company_edit }, (step) => ({
            ...step,
            content: <CompanyEdit />,
          }))
          .with({ id: BaseStepIdEnum.affiliated_companies_list }, (step) => ({
            ...step,
            content: <AffiliatedCompaniesList />,
          }))
          .with({ id: BaseStepIdEnum.individuals_list }, (step) => ({
            ...step,
            content: (
              <IndividualsList
                hasApplicant={step.config?.hasApplicant ?? true}
                hasSignatory={step.config?.hasSignatory ?? false}
                hasDelegator={step.config?.hasDelegator ?? false}
              />
            ),
          }))
          .with({ id: BaseStepIdEnum.individual_edit }, (step) => ({
            ...step,
            content: <IndividualEdit />,
          }))
          .exhaustive()
      );
    });
  } else {
    steps.push({
      id: 'checks_list',
      content: <ChecksList />,
    });
  }

  return (
    <>
      <Flex width="100%" ml={['0', '0', '25vw']}>
        <Stack
          width="100%"
          maxW={{ base: 'auto', md: '880px' }}
          py={[5, 10, 10]}
          pl={[5, 5, 20]}
          pr={[5, 5, 5]}
        >
          <Stack spacing={4}>
            {!loading && !error && !fetchError && showHeader && (
              <Header
                progress={(currentStep / steps.length) * 100}
                hasBackButton={currentStep !== 1 && !!steps[currentStep - 1]}
                isCheckStep={!!caseId}
                title={
                  steps[currentStep - 1]
                    ? t(`steps.${steps[currentStep - 1].id}.title`)
                    : null
                }
                subtitle={
                  steps[currentStep - 1]
                    ? i18n.exists(
                        `steps.${steps[currentStep - 1]?.id}.subtitle`,
                      )
                      ? `steps.${steps[currentStep - 1]?.id}.subtitle`
                      : null
                    : null
                }
              />
            )}
            {loading && <LoadingSpinner />}
            {(fetchError || error) && (
              <NotFound errorCode={error ?? fetchError ?? undefined} />
            )}
            {!loading && !error && !fetchError && (
              <React.Fragment key={steps[currentStep - 1]?.id}>
                {steps[currentStep - 1]?.content}
              </React.Fragment>
            )}
          </Stack>
        </Stack>
      </Flex>
      {isMobile && <Footer />}
    </>
  );
};
