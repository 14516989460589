import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Show,
  Spacer,
  Stack,
  Tag,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { EditIcon, PlusSquareIcon, Trash2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import type { MergedAffiliatedCompaniesModel } from 'shared-domain';
import { IndividualRoleEnum, affiliatedCompanySchema } from 'shared-domain';
import {
  useConfigContext,
  useProgress,
  useStore,
  useSubmit,
} from 'frontend-common';

import { ModalAffiliatedCompany } from '../modal';
import { Country } from '../data-display';

export const AffiliatedCompaniesList = () => {
  const { t } = useTranslation();
  const submitStep = useSubmit();
  const config = useConfigContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { affiliatedCompanies, removeAffiliatedCompany } = useStore();
  const { saveProgress } = useProgress();

  const [affiliatedCompaniesAreValid, setAffiliatedCompaniesAreValid] =
    useState<boolean>(true);
  const [affiliatedCompaniesIsValid, setAffiliatedCompaniesIsValid] = useState<
    { id: string; isValid: boolean }[]
  >([]);
  const [selectedAffiliatedCompanyId, setSelectedAffiliatedCompanyId] =
    useState<string | null>(null);

  const validAffiliatedCompanies = async () => {
    let createAffiliatedCompaniesIsValid: { id: string; isValid: boolean }[] =
      [];
    setAffiliatedCompaniesAreValid(true);

    for (const affiliatedCompany of affiliatedCompanies) {
      const isValid = await affiliatedCompanySchema(
        config.affiliatedCompanyFields,
      ).isValid(affiliatedCompany);

      if (!isValid) {
        setAffiliatedCompaniesAreValid(false);
      }

      createAffiliatedCompaniesIsValid = [
        ...createAffiliatedCompaniesIsValid,
        {
          id: affiliatedCompany.id,
          isValid,
        },
      ];
    }

    setAffiliatedCompaniesIsValid(createAffiliatedCompaniesIsValid);
  };

  useEffect(() => {
    validAffiliatedCompanies();
    saveProgress();
  }, [affiliatedCompanies]);

  return (
    <Stack spacing={5} pt={2}>
      {affiliatedCompanies.map(
        (affiliatedCompany: MergedAffiliatedCompaniesModel) => {
          return (
            <Box
              borderWidth="1px"
              borderRadius="lg"
              background="white"
              boxShadow="1px 1px 16px rgba(153, 153, 153, 0.1)"
              padding={5}
              key={affiliatedCompany.id}
            >
              <Flex
                direction={{ base: 'column', sm: 'row' }}
                alignItems={{ base: 'start', sm: 'center' }}
              >
                <Box>
                  <Heading size="sm" textAlign="left">
                    {affiliatedCompany.name}
                  </Heading>
                  <Text mt="2" color="gray.500" textAlign="left">
                    <Country
                      code={affiliatedCompany.country ?? '???'}
                      flagOnly
                      mr="2"
                    />
                    {affiliatedCompany.registration_number}
                  </Text>
                </Box>
                <Spacer />
                {affiliatedCompaniesIsValid.length > 0 &&
                  affiliatedCompaniesIsValid.find(
                    (t) => t.id === affiliatedCompany.id,
                  ) &&
                  !affiliatedCompaniesIsValid[
                    affiliatedCompaniesIsValid.findIndex(
                      (t) => t.id === affiliatedCompany.id,
                    )
                  ].isValid && (
                    <Tag
                      colorScheme="yellow"
                      mr={4}
                      mt={{ base: '12px', md: '0' }}
                    >
                      {t('steps.affiliated_companies_list.missing_data')}
                    </Tag>
                  )}
                <Show above="sm">
                  <Box mt={{ base: '12px', md: '0' }}>
                    <Button
                      mr={5}
                      leftIcon={<EditIcon size={16} />}
                      size="sl"
                      onClick={() => {
                        setSelectedAffiliatedCompanyId(affiliatedCompany.id);
                        onOpen();
                      }}
                      variant="secondary"
                    >
                      {t('steps.affiliated_companies_list.edit')}
                    </Button>
                    <Button
                      leftIcon={<Trash2 size={16} />}
                      onClick={() =>
                        removeAffiliatedCompany(affiliatedCompany.id)
                      }
                      size="sl"
                      variant="secondary"
                    >
                      {t('steps.affiliated_companies_list.delete')}
                    </Button>
                  </Box>
                </Show>
              </Flex>
              {affiliatedCompany.roles &&
                affiliatedCompany.roles
                  .filter(
                    (role: IndividualRoleEnum) =>
                      role !== IndividualRoleEnum.applicant,
                  )
                  .map((role: IndividualRoleEnum) => (
                    <Tag key={role} mt={4} mr={4}>
                      {t(`domain.individual.roles.${role}`)}
                    </Tag>
                  ))}
              <Show below="sm">
                <Box mt={{ base: '12px', md: '0' }}>
                  <Button
                    mr={5}
                    leftIcon={<EditIcon size={16} />}
                    size="sl"
                    onClick={() => {
                      setSelectedAffiliatedCompanyId(affiliatedCompany.id);
                      onOpen();
                    }}
                    variant="secondary"
                  >
                    {t('steps.affiliated_companies_list.edit')}
                  </Button>
                  <Button
                    leftIcon={<Trash2 size={16} />}
                    onClick={() =>
                      removeAffiliatedCompany(affiliatedCompany.id)
                    }
                    size="sl"
                    variant="secondary"
                  >
                    {t('steps.affiliated_companies_list.delete')}
                  </Button>
                </Box>
              </Show>
            </Box>
          );
        },
      )}
      <Box>
        <Button
          variant="add_individual"
          leftIcon={<PlusSquareIcon size={16} />}
          onClick={() => {
            setSelectedAffiliatedCompanyId(null);
            onOpen();
          }}
        >
          {t('steps.affiliated_companies_list.add_affiliated_company')}
        </Button>
      </Box>
      <Box>
        <Button
          variant="next"
          onClick={submitStep}
          isDisabled={!affiliatedCompaniesAreValid}
        >
          {t('domain.form.next')}
        </Button>
      </Box>
      {isOpen && (
        <ModalAffiliatedCompany
          selectedAffiliatedCompanyId={selectedAffiliatedCompanyId}
          onClose={onClose}
          validAffiliatedCompanies={validAffiliatedCompanies}
        />
      )}
    </Stack>
  );
};
