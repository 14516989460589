import { Company, DocumentAnalysisModelEnum, Individual } from '../../types';

type Definition = { label: string };

type RequiredCompanyField = Pick<
  Company,
  'address' | 'name' | 'banking_information' | 'registration_number'
>;

type RequiredIndividualField = Pick<
  Individual,
  'address' | 'banking_information' | 'first_name' | 'last_name' | 'birth_date'
>;

export type DocumentAnalysisEntity =
  | {
      company: RequiredCompanyField;
      individual?: never;
    }
  | {
      company?: never;
      individual: RequiredIndividualField;
    };

export abstract class DocumentAnalysisDefinition<
  TInformation extends { model: DocumentAnalysisModelEnum },
> {
  abstract get model(): DocumentAnalysisModelEnum;

  abstract get information(): Record<
    keyof Omit<TInformation, 'model'>,
    Definition
  >;
}
