import {
  CustomField,
  FieldEntityTypeEnum,
  FieldPropertyTypeEnum,
} from 'shared-domain';

export const generatedFieldName = (field: CustomField): string => {
  if (field.nested) {
    if (field.nested === 'classifications') {
      return `${field.nested}.0.${field.id}`;
    }
    return `${field.nested}.${field.id}`;
  }

  if (
    field.propertyType === FieldPropertyTypeEnum.custom &&
    field.entityType !== FieldEntityTypeEnum.case
  ) {
    return `custom_properties.${field.id}`;
  }

  return field.id;
};
