import { useMemo } from 'react';
import {
  ChakraStylesConfig,
  Select as ChakraSelect,
} from 'chakra-react-select';
import { useTranslation } from 'react-i18next';
import { OTHER } from '../steps/controlled-fields';

export type SelectOption = {
  value: string;
  label: string;
};

type SelectProps = {
  stepId: string;
  name: string;
  defaultValue: string | string[];
  options: string[] | SelectOption[];
  onChange: any;
  placeholder?: string;
  isTranslatableOptions?: boolean;
  isMulti?: boolean;
  hasOtherOption?: boolean;
  isCustomProperty?: boolean;
};

export const Select = (props: SelectProps) => {
  const {
    stepId,
    name,
    defaultValue,
    options,
    onChange,
    placeholder,
    isTranslatableOptions = true,
    isMulti = false,
    hasOtherOption = false,
    isCustomProperty = false,
  } = props;

  const { t } = useTranslation();

  const chakraStyles: ChakraStylesConfig = {
    container: (provided) => ({
      ...provided,
      maxW: '400px',
    }),
  };

  const resolvedOptions = useMemo(() => {
    const preparedOptions = options.map((option, index) => {
      if (isTranslatableOptions && typeof option === 'string') {
        return {
          label: t(`steps.${stepId}.${name}.options.${option}`),
          value: option,
        };
      }

      if (typeof option === 'string') {
        return {
          label: option,
          value: `${index}`,
        };
      }

      return option;
    });
    if (hasOtherOption) {
      preparedOptions.push({
        label: t(`steps.${stepId}.${name}.${OTHER}_option.option`),
        value: OTHER,
      });
    }
    return preparedOptions;
  }, [options]);

  const resolvedDefaultValue = useMemo(() => {
    if (typeof defaultValue === 'string') {
      return resolvedOptions.find(
        (o: SelectOption) => o.value === (defaultValue as unknown),
      );
    }
    if (Array.isArray(defaultValue)) {
      return defaultValue.map((value) =>
        resolvedOptions.find((o: SelectOption) => o.value === value),
      );
    }
    return defaultValue;
  }, [defaultValue]);

  return (
    <ChakraSelect
      placeholder={placeholder ?? t('domain.form.select')}
      useBasicStyles
      chakraStyles={chakraStyles}
      value={resolvedDefaultValue || ''}
      isClearable={true}
      isMulti={isMulti}
      closeMenuOnSelect={!isMulti}
      hideSelectedOptions={false}
      selectedOptionStyle="check"
      options={resolvedOptions}
      onChange={(option) => {
        if (Array.isArray(option)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange(option.map((o: SelectOption) => o.value));
        } else if (isCustomProperty) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange(option ? [option.value] : []);
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange(option ? option.value : '');
        }
      }}
    />
  );
};
