import { RumInitConfiguration } from '@datadog/browser-rum';

export const defaultRumConfig: RumInitConfiguration = {
  applicationId: process.env['DATADOG_APPLICATION_ID'] || '',
  clientToken: process.env['DATADOG_CLIENT_TOKEN'] || '',
  version: process.env['RENDER_GIT_COMMIT'] || '',
  site: 'datadoghq.eu',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
};
