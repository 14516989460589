import {
  DocumentAnalysisRegistrationCertificateInformationModel,
  DocumentAnalysisModelEnum,
  DocumentAnalysisRegistrationCertificateDocumentTypesEnum,
} from '../../../types';
import { DocumentAnalysisDefinition } from '../document-analysis-definition';

export class DocumentAnalysisRegistrationCertificateDefinition extends DocumentAnalysisDefinition<DocumentAnalysisRegistrationCertificateInformationModel> {
  override get model(): DocumentAnalysisModelEnum {
    return DocumentAnalysisModelEnum.registration_certificate;
  }

  override get information(): Record<
    keyof Omit<
      DocumentAnalysisRegistrationCertificateInformationModel,
      'model'
    >,
    { label: string; translateKey: string }
  > {
    return {
      address: {
        label: 'Address',
        translateKey: 'steps.checks_list.document_analysis.information.address',
      },
      issuing_date: {
        label: 'Issuing date',
        translateKey:
          'steps.checks_list.document_analysis.information.issuing_date',
      },
      name: {
        label: 'Name',
        translateKey: 'steps.checks_list.document_analysis.information.name',
      },
      country: {
        label: 'Country',
        translateKey: 'steps.checks_list.document_analysis.information.country',
      },
      registration_number: {
        label: 'Registration number',
        translateKey:
          'steps.checks_list.document_analysis.information.registration_number',
      },
      document_type: {
        label: 'Document type',
        translateKey:
          'steps.checks_list.document_analysis.information.document_type',
      },
      other_document_type: {
        label: 'Other Document type',
        translateKey:
          'steps.checks_list.document_analysis.information.other_document_type',
      },
    };
  }

  get documentTypes(): Record<
    DocumentAnalysisRegistrationCertificateDocumentTypesEnum,
    { label: string; translateKey: string }
  > {
    return {
      kbis: {
        label: 'KBIS',
        translateKey: 'steps.checks_list.document_analysis.document_types.kbis',
      },
      handelsregister: {
        label: 'Handelsregister',
        translateKey:
          'steps.checks_list.document_analysis.document_types.handelsregister',
      },
      infonet_extract: {
        label: 'Infonet extract',
        translateKey:
          'steps.checks_list.document_analysis.document_types.infonet_extract',
      },
      inscription_rne: {
        label: 'Inscription RNE',
        translateKey:
          'steps.checks_list.document_analysis.document_types.inscription_rne',
      },
      pappers_extract: {
        label: 'Papper extract',
        translateKey:
          'steps.checks_list.document_analysis.document_types.pappers_extract',
      },
      repertoire_metiers_extract: {
        label: 'Repertoire metiers extract',
        translateKey:
          'steps.checks_list.document_analysis.document_types.repertoire_metiers_extract',
      },
      sirene_extract: {
        label: 'Sirene extract',
        translateKey:
          'steps.checks_list.document_analysis.document_types.sirene_extract',
      },
      visura_camerale: {
        label: 'Visura camerale',
        translateKey:
          'steps.checks_list.document_analysis.document_types.visura_camerale',
      },
      other: {
        label: 'Other',
        translateKey:
          'steps.checks_list.document_analysis.document_types.other',
      },
    };
  }
}

export const documentAnalysisRegistrationCertificateDefinition =
  new DocumentAnalysisRegistrationCertificateDefinition();
