import React, {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
} from 'react';
import type { AppConfig } from 'shared-domain';
import {
  BaseStepIdEnum,
  FieldTypeEnum,
  IndividualRoleEnum,
} from 'shared-domain';

import { appConfigSchema } from './configs.schema';

type ConfigProviderProps = PropsWithChildren<{
  value: Partial<AppConfig>;
}>;

const defaultConfigs: Omit<AppConfig, 'environment'> = {
  websiteTitle: 'Dotfile',
  showPoweredLogo: true,
  hasKyb: true,
  queryParamsRequired: [],
  supportLink: '',
  redirectUrl: null,
  languages: ['fr', 'en', 'de', 'es', 'it', 'nl'],
  countries: [],
  companyFields: [
    {
      id: 'name',
      isRequired: true,
      isEnabled: true,
    },
    {
      id: 'registration_number',
      isRequired: true,
      isEnabled: true,
    },
    {
      id: 'country',
      type: FieldTypeEnum.country,
      isRequired: true,
      isEnabled: true,
    },
  ],
  affiliatedCompanyFields: [
    {
      id: 'name',
      isRequired: true,
      isEnabled: true,
    },
    {
      id: 'registration_number',
      isRequired: true,
      isEnabled: true,
    },
    {
      id: 'country',
      type: FieldTypeEnum.country,
      isRequired: true,
      isEnabled: true,
    },
  ],
  individualFields: [
    {
      id: 'first_name',
      isRequired: true,
      isEnabled: true,
    },
    {
      id: 'last_name',
      isRequired: true,
      isEnabled: true,
    },
    {
      id: 'roles',
      type: FieldTypeEnum.checkbox,
      isRequired: true,
      isEnabled: true,
      options: [
        IndividualRoleEnum.beneficial_owner,
        IndividualRoleEnum.legal_representative,
        IndividualRoleEnum.shareholder,
      ],
    },
  ],
  stepsConfig: [
    {
      id: BaseStepIdEnum.company_search,
    },
    {
      id: BaseStepIdEnum.company_list,
    },
    {
      id: BaseStepIdEnum.company_edit,
    },
    {
      id: BaseStepIdEnum.individuals_list,
      config: { hasApplicant: true },
    },
  ],
};

export const ConfigContext = createContext<Omit<
  AppConfig,
  'environment'
> | null>(defaultConfigs);

/**
 * Return the config from the context
 * @returns Config
 */
export const useConfigContext = (): Omit<AppConfig, 'environment'> => {
  const config = useContext(ConfigContext);

  if (!config) {
    const error = new Error(
      'useConfigContext: `config` is falsy. Seems you forgot to wrap component within the ConfigContextProvider or initialize the context value',
    );
    error.name = 'ConfigContextError';
    Error.captureStackTrace?.(error, useContext);
    throw error;
  }

  return config;
};

export const ConfigContextProvider = ({
  children,
  ...props
}: ConfigProviderProps): ReactElement => {
  const mergedConfigs = { ...defaultConfigs, ...props.value };

  const validation = appConfigSchema.safeParse(mergedConfigs);
  if (!validation.success) {
    const error = new Error(
      validation.error.issues.map((err) => err.message).toString(),
    );
    error.name = 'ConfigContextError';
    Error.captureStackTrace?.(error, useContext);
    throw error;
  }

  return (
    <ConfigContext.Provider value={mergedConfigs}>
      {children}
    </ConfigContext.Provider>
  );
};
