import { useState } from 'react';
import { Button, VStack, Box, Input } from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChevronRightIcon, ArrowRightIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  useConfigContext,
  useStore,
  useSubmitCompanyForm,
} from 'frontend-common';

import { GroupController, CountrySelect } from '../form';
import { CustomField } from 'shared-domain';
import { useSearchParams } from 'react-router-dom';

interface FormValues {
  country: string;
  name: string;
  registration_number: string;
}

// @TODO - OPS-9 - Replace Yup by Zod
const validationSchema = Yup.object().shape(
  {
    country: Yup.string().required().label('Country'),
    name: Yup.string()
      .label('Name')
      .when('registration_number', {
        is: (val: string) => val === '',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      }),
    registration_number: Yup.string()
      .label('Registration number')
      .when('name', {
        is: (val: string) => val === '',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      }),
  },
  [['name', 'registration_number']],
);

export const CompanySearch = () => {
  const { t } = useTranslation();
  const config = useConfigContext();
  const { submitCompanyForm } = useSubmitCompanyForm();
  const {
    company,
    metadata,
    addCompanies,
    updateCompany,
    resetAffiliatedCompanies,
    resetIndividuals,
    updateMetadata,
  } = useStore();

  const [searchByName, setSearchByName] = useState<boolean>(true);

  const [searchParams] = useSearchParams();

  const defaultValues: FormValues = {
    country:
      company?.country || searchParams.get('country')?.toUpperCase() || '',
    name: company?.name || searchParams.get('company') || '',
    registration_number: company?.registration_number || '',
  };

  const methods = useForm<FormValues>({
    mode: 'all',
    criteriaMode: 'all',
    // @TODO - OPS-9 - Replace Yup by Zod
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    updateCompany({
      name: company ? company.name : null,
      country: company ? company.country : null,
      registration_number: null,
    });
    resetAffiliatedCompanies();
    resetIndividuals();

    // Remove metadata which are extra values of a company
    const companyMetadataValues = config.companyFields
      // @TODO - OPS-551 - Hooks - Update submit hooks
      //.filter((f: CustomField) => f.isExtraValue)
      .map((f: CustomField) => f.id);
    const companyMetadata = metadata;
    companyMetadataValues.forEach((id: string) => delete companyMetadata[id]);
    updateMetadata(companyMetadata);

    // Remove metadata which are extra values of an individual
    const individualMetadataValues = config.individualFields
      // @TODO - OPS-551 - Hooks - Update submit hooks
      //.filter((f: CustomField) => f.isExtraValue)
      .map((f: CustomField) => f.id);
    const individualMetadata = metadata;
    individualMetadataValues.forEach(
      (id: string) => delete individualMetadata[id],
    );
    updateMetadata(individualMetadata);

    addCompanies([]);
    if (searchByName) formData.registration_number = undefined;
    submitCompanyForm({ companyData: formData });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        <GroupController
          data-testid="country-select"
          name="country"
          label={t(`steps.company_search.country.label`) || 'Country'}
          isRequired={true}
          control={control}
          render={(field) => (
            <CountrySelect
              onChange={(value: string) => {
                setValue('country', value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              countries={config.countries}
              defaultValue={field.value}
            />
          )}
        />

        {searchByName && (
          <Box w="100%">
            <GroupController
              name="name"
              label={t(`steps.company_search.name.label`) || 'Name'}
              isRequired={true}
              control={control}
              render={(field) => <Input type="text" maxW="400px" {...field} />}
            />
            <Button
              variant="ghost"
              onClick={() => {
                setValue('name', '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
                setSearchByName(false);
              }}
              size="sm"
              rightIcon={<ArrowRightIcon size={16} />}
            >
              {t('steps.company_search.search_by_registration_number')}
            </Button>
          </Box>
        )}

        {!searchByName && (
          <Box w="100%">
            <GroupController
              name="registration_number"
              label={
                t(`steps.company_search.registration_number.label`) ||
                'Registration number'
              }
              isRequired={true}
              control={control}
              render={(field) => <Input type="text" maxW="400px" {...field} />}
            />
            <Button
              variant="ghost"
              onClick={() => {
                setValue('registration_number', '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
                setSearchByName(true);
              }}
              size="sm"
              rightIcon={<ArrowRightIcon size={16} />}
            >
              {t('steps.company_search.search_by_name')}
            </Button>
          </Box>
        )}

        <Box>
          <Button
            variant="next"
            rightIcon={<ChevronRightIcon size={16} />}
            isDisabled={!isValid}
            type="submit"
          >
            {t('steps.company_search.button')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
