import { Alert, AlertIcon, Box, FormLabel, Text } from '@chakra-ui/react';
import { generatedFieldName, useStore } from 'frontend-common';
import { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { FieldPropertyTypeEnum } from 'shared-domain';
import { GroupController, Select } from '../../form';
import { useTranslation } from 'react-i18next';
import { ControlledInputProps } from '../type';

export const ControlledAffiliatedSelect: FunctionComponent<
  ControlledInputProps
> = ({ stepId, field }) => {
  const { setValue, control } = useFormContext();
  const { t } = useTranslation();

  const { affiliatedCompanies } = useStore();

  const fieldName = generatedFieldName(field);

  return (
    <Box w="100%" key={fieldName}>
      {affiliatedCompanies.length === 0 && (
        <>
          <FormLabel>
            {t(`steps.${stepId}.${fieldName}.label`) || fieldName}
          </FormLabel>
          <Alert status="info" mb="6">
            <AlertIcon />
            <Text>{t(`steps.${stepId}.${fieldName}.alert`)}</Text>
          </Alert>
        </>
      )}
      {affiliatedCompanies.length > 0 && (
        <GroupController
          data-testid={`select-${field.id}`}
          name={fieldName}
          label={t(`steps.${stepId}.${fieldName}.label`) || fieldName}
          helper={
            field.hasHelper ? t(`steps.${stepId}.${fieldName}.helper`) : null
          }
          isRequired={field.isRequired}
          control={control}
          render={(f) => (
            <Select
              stepId={stepId}
              name={fieldName}
              onChange={(value: string[] | string) => {
                setValue(
                  fieldName,
                  value ??
                    (field.isMulti ||
                    field.propertyType === FieldPropertyTypeEnum.custom
                      ? []
                      : ''),
                  {
                    shouldDirty: true,
                    shouldValidate: true,
                  },
                );
              }}
              options={
                affiliatedCompanies.map((x) => {
                  return {
                    label: x.name,
                    value: x.registration_number,
                  };
                }) || []
              }
              defaultValue={f.value}
              isMulti={field.isMulti}
              placeholder={
                field.hasPlaceholder
                  ? t(`steps.${stepId}.${fieldName}.placeholder`)
                  : undefined
              }
            />
          )}
        />
      )}
    </Box>
  );
};
