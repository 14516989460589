import { useEffect, useMemo } from 'react';
import { Box, Button, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { createYupSchema, isObjectEmpty } from 'shared-common';
import {
  CustomField,
  customFieldsExtendValidation,
  FieldEntityTypeEnum,
} from 'shared-domain';
import {
  defaultCaseCustomPropertiesValues,
  defaultCaseMetadataValues,
  defaultEntityCustomPropertiesValues,
  preSubmitHelper,
  useStepId,
  useStore,
  useSubmitCustomStepForm,
} from 'frontend-common';
import { ControlledFields } from './controlled-fields';
import { ControlledTextInput } from './controlled-fields/controlled-text-input';

type CustomFormProps = {
  fields: CustomField[];
};

const DefaultCustomFormInput = ControlledTextInput;

export const CustomForm = (props: CustomFormProps) => {
  const { fields } = props;

  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { metadata, individuals, company, customProperties } = useStore();
  const stepId = useStepId();

  const dynamicValidationSchema = useMemo(() => {
    // Extend fields with our validations
    const dynamicFormData = customFieldsExtendValidation(fields, t, stepId);
    // Create schema based on added validations
    // @TODO - OPS-9 - Replace Yup by Zod
    const customFieldsSchema = dynamicFormData.reduce(createYupSchema, {});
    // Create Yup schema
    return Yup.object().shape(customFieldsSchema);
  }, [fields, stepId, t]);

  const defaultValues = useMemo(() => {
    let defaultCompanyValues = {};
    if (
      fields.find(
        (field: CustomField) =>
          field.entityType === FieldEntityTypeEnum.company,
      )
    ) {
      const defaultCompanyCustomPropertiesValues =
        defaultEntityCustomPropertiesValues(fields, company);
      defaultCompanyValues = {
        ...company,
        custom_properties: defaultCompanyCustomPropertiesValues,
      };
    }

    let defaultIndividualValues = {};
    if (
      fields.find(
        (field: CustomField) =>
          field.entityType === FieldEntityTypeEnum.individual,
      )
    ) {
      const individual = individuals[0];
      const defaultIndividualCustomPropertiesValues =
        defaultEntityCustomPropertiesValues(fields, individual);
      defaultIndividualValues = {
        ...individual,
        custom_properties: defaultIndividualCustomPropertiesValues,
      };
    }

    return {
      ...defaultCompanyValues,
      ...defaultIndividualValues,
      ...defaultCaseMetadataValues(fields, metadata),
      ...defaultCaseCustomPropertiesValues(fields, customProperties),
    };
  }, [company, customProperties, fields, individuals, metadata]);

  const methods = useForm({
    mode: 'all',
    criteriaMode: 'all',
    // @TODO - OPS-9 - Replace Yup by Zod
    resolver: yupResolver(dynamicValidationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { isValid, isSubmitting },
  } = methods;

  useEffect(() => {
    Object.entries(defaultValues).forEach(([key, value]) => {
      if (value && typeof value === 'object' && !isObjectEmpty(value)) {
        Object.entries(value).forEach(([subKey, subValue]) => {
          setValue(`custom_properties.${subKey}`, subValue);
        });
      } else {
        setValue(key, value);
      }
    });
  }, [defaultValues, setValue]);

  const onSubmit: SubmitHandler<any> = async (formData) => {
    const { companyData, individualData, caseMetadata, caseCustomProperties } =
      preSubmitHelper(fields, formData);

    submitCustomStepForm({
      caseMetadata,
      caseCustomProperties,
      companyData,
      individualData,
      individualId: individuals[0]?.id,
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing="6" alignItems="start">
          {fields.map((field: CustomField, idx) => (
            <ControlledFields
              key={idx}
              field={field}
              stepId={stepId}
              Fallback={DefaultCustomFormInput}
            ></ControlledFields>
          ))}
          <Box>
            <Button
              variant="next"
              isLoading={isSubmitting}
              isDisabled={!isValid}
              type="submit"
            >
              {t('domain.form.next')}
            </Button>
          </Box>
        </VStack>
      </form>
    </FormProvider>
  );
};
