import { useEffect, useMemo } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  BaseStepIdEnum,
  CustomField,
  customFieldsExtendValidation,
  defaultMetadataValuesHelper,
  FieldPropertyTypeEnum,
  IndividualRoleEnum,
  individualSchema,
} from 'shared-domain';
import {
  defaultCaseCustomPropertiesValues,
  defaultEntityCustomPropertiesValues,
  preSubmitHelper,
  useConfigContext,
  useStore,
  useSubmitIndividualForm,
} from 'frontend-common';
import { createYupSchema } from 'shared-common';

import { IndividualForm } from '../shared';
import { IndividualEditFormValues } from './type';

export const IndividualEdit = () => {
  const config = useConfigContext();
  const { t } = useTranslation();
  const { submitIndividualForm } = useSubmitIndividualForm();
  const { queryParams } = useStore();
  const { individuals, metadata, customProperties } = useStore();

  const individual = individuals[0];

  const defaultValues = useMemo(() => {
    const defaultCustomPropertiesValues = defaultEntityCustomPropertiesValues(
      config.individualFields,
      individual,
    );

    const defaultIndividualValues: IndividualEditFormValues = {
      first_name: individual?.first_name || queryParams.firstName || '',
      middle_name: individual?.middle_name || '',
      last_name: individual?.last_name || queryParams.lastName || '',
      maiden_name: individual?.maiden_name || '',
      email: individual?.email || queryParams.email || '',
      roles: individual?.roles || [],
      birth_date: individual?.birth_date || '',
      birth_country: individual?.birth_country || '',
      birth_place: individual?.birth_place || '',
      holdings: '',
      address: {
        street_address: individual?.address?.street_address || '',
        street_address_2: individual?.address?.street_address_2 || '',
        postal_code: individual?.address?.postal_code || '',
        city: individual?.address?.city || '',
        state: individual?.address?.state || '',
        region: individual?.address?.region || '',
        country: individual?.address?.country || '',
      },
      banking_information: {
        iban: individual?.banking_information?.iban || '',
        bic: individual?.banking_information?.bic || '',
      },
      tax_identification_number: individual?.tax_identification_number || '',
      social_security_number: individual?.social_security_number || '',
      phone_number: individual?.phone_number || '',
      position: individual?.position || '',
      ownership_percentage: individual?.ownership_percentage ?? null,
      custom_properties: defaultCustomPropertiesValues,
    };

    return {
      ...defaultIndividualValues,
      ...defaultMetadataValuesHelper(
        config.individualFields,
        metadata,
        defaultIndividualValues.first_name,
        defaultIndividualValues.last_name,
      ),
      ...defaultCaseCustomPropertiesValues(
        config.individualFields,
        customProperties,
      ),
    };
  }, [individual, queryParams]);

  const dynamicValidationSchema = useMemo(() => {
    // Extend fields with our validations
    const dynamicFormData = customFieldsExtendValidation(
      config.individualFields.filter(
        (f: CustomField) =>
          f.propertyType === FieldPropertyTypeEnum.metadata ||
          f.propertyType === FieldPropertyTypeEnum.custom,
      ),
      t,
      BaseStepIdEnum.individual_edit,
    );
    // Create schema based on added validations
    // @TODO - OPS-9 - Replace Yup by Zod
    return dynamicFormData.reduce(createYupSchema, {});
  }, [config.individualFields]);

  const methods = useForm<IndividualEditFormValues>({
    mode: 'all',
    criteriaMode: 'all',
    // @TODO - OPS-9 - Replace Yup by Zod
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(
      individualSchema(
        config.individualFields.filter(
          (f: CustomField) =>
            f.propertyType !== FieldPropertyTypeEnum.metadata &&
            f.propertyType !== FieldPropertyTypeEnum.custom,
        ),
      ).shape(dynamicValidationSchema),
    ),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [reset, queryParams]);

  const onSubmit: SubmitHandler<any> = (formData) => {
    const { individualData, caseMetadata, caseCustomProperties } =
      preSubmitHelper(config.individualFields, formData, 'individual');

    submitIndividualForm({
      individualData,
      individualId: individual?.id,
      caseMetadata,
      caseCustomProperties,
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <IndividualForm
          isApplicant={
            individual?.roles?.includes(IndividualRoleEnum.applicant) || false
          }
        />
        <Box mt="6">
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};
