export enum LogLevelEnum {
  OFF = 'OFF',
  SILLY = 'SILLY',
  VERBOSE = 'VERBOSE',
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR',
  FATAL = 'FATAL',
}
