// @TODO - OPS-9 - Replace Yup by Zod

import * as Yup from 'yup';
import type { BaseField, NestedKeys } from '../types';

export const optionalStringRule = Yup.string()
  .optional()
  .nullable()
  .default(null)
  .transform((v) => (v === '' ? null : v));

export const isRequiredField = (
  fields: BaseField[],
  fieldId: string,
  nested?: NestedKeys,
): boolean => {
  return fields.filter((f: BaseField) =>
    nested ? f.id === fieldId && f.nested === nested : f.id === fieldId,
  )[0].isRequired;
};
