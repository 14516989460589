// @TODO - OPS-9 - Replace Yup by Zod

import * as yup from 'yup';

// This function creates the dynamic Yup schema
export function createYupSchema(schema: any, config: any) {
  const { id, validationType, validations = [] } = config;

  if (!(yup as any)[validationType]) {
    return schema;
  }

  let validator = (yup as any)[validationType]();

  validations.forEach((validation: any) => {
    const { params, type } = validation;
    if (!validator[type]) {
      return;
    }
    validator = validator[type](...params);
  });

  if (id.includes('.')) {
    const splitId = id.split('.');
    schema[splitId[0]] = yup.object().shape({
      ...schema[splitId[0]]?.fields,
      [splitId[1]]: validator,
    });
  } else {
    schema[id] = validator;
  }
  return schema;
}
