import { useTranslation } from 'react-i18next';
import { DataComparisonLabel } from './data-comparison-label';
import { nameFormat } from 'shared-common';
import {
  DocumentAnalysisDetailedResultModel,
  DocumentAnalysisEntity,
} from 'shared-domain';

export function ValidDocumentType({
  definition,
  detailedResult,
  entity,
}: {
  definition: {
    information: { document_type: { label: string; translateKey: string } };
    documentTypes: Record<string, { label: string; translateKey: string }>;
  };

  detailedResult: DocumentAnalysisDetailedResultModel;
  entity: DocumentAnalysisEntity;
}) {
  const { t } = useTranslation();

  const documentTypeExpectedDefinition =
    detailedResult.expected &&
    detailedResult.expected in definition.documentTypes &&
    definition.documentTypes[detailedResult.expected];

  const expected =
    documentTypeExpectedDefinition &&
    t(documentTypeExpectedDefinition.translateKey, {
      defaultValue: documentTypeExpectedDefinition.label,
    });

  const documentTypeReceivedDefinition =
    detailedResult.received &&
    detailedResult.received in definition.documentTypes &&
    definition.documentTypes[detailedResult.received];

  const received =
    documentTypeReceivedDefinition &&
    t(documentTypeReceivedDefinition.translateKey, {
      defaultValue: documentTypeReceivedDefinition.label,
    });

  return (
    <DataComparisonLabel
      informationDefinition={definition.information.document_type}
      detailedResult={{
        ...detailedResult,
        expected: expected || detailedResult.expected,
        received: received || detailedResult.received,
      }}
      entityName={
        entity.company?.name ??
        nameFormat(entity.individual?.first_name, entity.individual?.last_name)
      }
    />
  );
}
