import React from 'react';
import { UnexpectedError } from './unexpected-error';

export class ErrorBoundary extends React.Component<{
  children: React.ReactElement;
}> {
  override state = { error: null };

  public static getDerivedStateFromError(error: unknown) {
    return { error };
  }

  public override componentDidCatch(err: unknown) {
    console.error(err);
  }

  public override render() {
    if (this.state.error) {
      return <UnexpectedError />;
    }

    return this.props.children;
  }
}
