import { CustomField, FieldPropertyTypeEnum, Metadata } from 'shared-domain';

export const defaultCaseMetadataValues = (
  fields: CustomField[],
  metadata: Metadata,
): CustomField[] => {
  return fields
    .filter(
      (f: CustomField) => f.propertyType === FieldPropertyTypeEnum.metadata,
    )
    .reduce((acc: any, cur: CustomField) => {
      if (cur.hasOtherOption) {
        return {
          ...acc,
          [cur.id]: metadata[cur.id] || '',
          [`${cur.id}_other`]: metadata[`${cur.id}_other`] || '',
        };
      }
      return { ...acc, [cur.id]: metadata[cur.id] || '' };
    }, {});
};
