import { useConfigContext, useLoadingContext } from '../contexts';
import { useStore } from './use-store';
import { useApi } from './use-api';
import { useProgress } from './use-progress';
import { useState } from 'react';
import { CaseDetails } from 'shared-domain';

export function useDetailedCase(): {
  error: string | null;
  loading: boolean;
  fetchCase: (
    id: string | null,
    hasCaseIdParam?: boolean,
  ) => Promise<CaseDetails | null>;
} {
  const api = useApi();
  const { loading, setLoading } = useLoadingContext();
  const config = useConfigContext();
  const { removeProgress } = useProgress();
  const [error, setError] = useState<string | null>(null);

  const {
    caseId,
    updateCurrentStep,
    updateFlags,
    updateMetadata,
    updateCompany,
    addIndividuals,
    updateCaseId,
    updateExternalId,
    addAffiliatedCompanies,
    updateResumeFlow,
    addChildrenCases,
    updateCustomProperties,
  } = useStore();

  const fetchCase = async function (
    id: string | null,
    hasCaseIdParam?: boolean,
  ) {
    if (id) {
      setLoading(true);

      return await api
        .get(`/dotfile/cases/${id}`)
        .then((response) => {
          if (response.data.id) {
            const allowResumingFlow =
              response.data.allowing_resume_flow === true;

            updateCaseId(response.data?.id);
            updateExternalId(response.data?.external_id);
            updateFlags(response.data.flags);
            updateMetadata(response.data.metadata);
            updateCompany(
              response.data.companies.filter(
                // @TODO - TDB - Improve DTO typing
                // This company comes from libs/backend/api/src/lib/dto/company.dto.ts
                (company: { type: 'main' | 'affiliated' }) =>
                  company.type === 'main',
              )[0],
            );
            addIndividuals(response.data.individuals);

            updateCustomProperties({
              ...response.data?.custom_properties,
            });

            addAffiliatedCompanies(
              response.data.companies.filter(
                // @TODO - TDB - Improve DTO typing
                // This company comes from libs/backend/api/src/lib/dto/company.dto.ts
                (company: { type: 'main' | 'affiliated' }) =>
                  company.type === 'affiliated',
              ),
            );

            addChildrenCases(response.data.children_cases);

            if (allowResumingFlow === true) {
              updateResumeFlow(allowResumingFlow);
            }
            // Remove progress in local storage
            removeProgress();

            // checks_list step
            updateCurrentStep(1);
          }

          return response.data;
        })
        .catch(() => {
          if (config.caseMustExist && config.caseMustExist === true)
            setError('not_found');
          if (caseId && hasCaseIdParam) {
            setError('not_found');
            localStorage.removeItem('caseId');
            updateCaseId(null);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    return Promise.resolve(null);
  };

  return { loading, error, fetchCase };
}
