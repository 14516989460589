import { useTranslation } from 'react-i18next';
import { BaseStepIdEnum } from 'shared-domain';
import { useStore } from 'frontend-common';
import { GroupControl, Select } from '../form';

export const SetSignatory = () => {
  const { t, i18n } = useTranslation();
  const { individuals, updateIndividual, getIndividual } = useStore();

  const defaultValue = individuals.find(
    (individual) => individual?.custom_properties?.['is_signatory'] === true,
  );

  const selectSetSignatory = (id: string) => {
    for (const individual of individuals) {
      individual.id &&
        updateIndividual(individual.id, {
          ...individual,
          custom_properties: {
            ...individual.custom_properties,
            is_signatory: false,
          },
        });
    }

    const individual = getIndividual(id);
    if (individual) {
      updateIndividual(id, {
        ...individual,
        custom_properties: {
          ...individual.custom_properties,
          is_signatory: true,
        },
      });
    }
  };

  return (
    <GroupControl
      isRequired={true}
      label={
        t(`steps.individuals_list.set_signatory.label`) ||
        'If you are not a legal representative, who will be the signatory? (Select yourself if you are a legal representative)"'
      }
      helper={
        i18n.exists(`steps.individuals_list.set_signatory.helper`)
          ? t(`steps.individuals_list.set_signatory.helper`)
          : null
      }
    >
      <Select
        stepId={BaseStepIdEnum.individuals_list}
        name="set_signatory"
        defaultValue={defaultValue?.id ? defaultValue.id : ''}
        options={individuals.map((individual) => {
          return {
            value: individual.id as string,
            label: `${individual.first_name} ${individual.last_name}`,
          };
        })}
        onChange={(id: string) => selectSetSignatory(id)}
        isTranslatableOptions={false}
      />
    </GroupControl>
  );
};
