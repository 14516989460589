import { useCallback, useState } from 'react';
import { UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { Trash } from 'lucide-react';
import { Icon, IconButton, HStack, Input } from '@chakra-ui/react';
import { FilesFormValues } from '../modal';
import { GroupController } from './group-controller';
import { UploadDropzone } from './upload-dropzone';
import { Accept } from 'react-dropzone';

type FormDocumentItemProps = {
  index: number;
  accept?: Accept;
  label: string;
  remove?: UseFieldArrayRemove;
};

export const FormDocumentItem = (props: FormDocumentItemProps) => {
  const { index, accept, label, remove } = props;

  const { control, setValue, resetField } = useFormContext<FilesFormValues>();
  const [fileName, setFileName] = useState<string | undefined>(undefined);

  const handleInputChange = useCallback(
    async (file: File): Promise<void> => {
      setFileName(file.name);
      resetField(`files.${index}.file`);
      setValue(`files.${index}.file`, file, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [resetField, index, setValue, setFileName],
  );

  return (
    <>
      <GroupController
        name={`files.${index}.file` as any}
        control={control}
        render={(field) => <Input type="hidden" {...field} />}
      />
      <HStack
        align="start"
        borderColor="gray.200"
        borderWidth="1px"
        borderStyle="solid"
        borderRadius="md"
        p="3"
        mb="4"
      >
        <UploadDropzone
          type="file"
          width="full"
          accept={accept}
          onDrop={handleInputChange}
          description={label}
          fileName={fileName}
          isFocused={false}
        />
        {remove && (
          <IconButton
            type="button"
            aria-label="delete"
            variant="ghost"
            size="sm"
            icon={<Icon as={Trash} />}
            onClick={() => {
              remove(index);
            }}
          />
        )}
      </HStack>
    </>
  );
};
