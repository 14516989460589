import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { UploadCloud } from 'lucide-react';

import { BoxProps, Icon, Text, useToast } from '@chakra-ui/react';
import { MAX_FILE_SIZE, allAcceptedFileTypes } from 'shared-domain';
import { DropzoneProps, Dropzone } from './dropzone';

type UploadDropzoneProps = Omit<BoxProps, 'onDrop' | 'onKeyDown'> & {
  onDrop?: <T extends File>(file: T) => void;
  fileName?: string;
  description?: string;
  helper?: string;
  isFocused?: boolean;
  uploadProgress?: number;
  type: DropzoneProps['type'];
  isOnError?: boolean;
  maxSize?: number;
  accept?: DropzoneOptions['accept'];
  isDisabled?: boolean;
};

export const UploadDropzone = ({
  onDrop,
  fileName,
  description,
  helper,
  uploadProgress,
  isFocused,
  type,
  isOnError,
  isDisabled,
  maxSize = MAX_FILE_SIZE,
  // @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  accept = {
    'image/*': allAcceptedFileTypes
      .filter((aft) => aft.mimeType.startsWith('image/'))
      .flatMap((aft) => aft.extensions),
    'text/*': allAcceptedFileTypes
      .filter((aft) => aft.mimeType.startsWith('text/'))
      .flatMap((aft) => aft.extensions),
    // application/* mime type must be manually specified, application/* is not valid
    ...Object.fromEntries(
      allAcceptedFileTypes
        .filter((aft) => aft.mimeType.startsWith('application/'))
        .map((aft) => [aft.mimeType, aft.extensions]),
    ),
  },
  ...props
}: UploadDropzoneProps) => {
  const toast = useToast();

  // @doc https://react-dropzone.js.org/#src
  const { getRootProps, getInputProps, open, rootRef, isDragAccept } =
    useDropzone({
      onDrop: (acceptedFiles) => {
        if (acceptedFiles.length === 0) {
          toast({
            title: 'Uploaded file is too big. Maximum 17 Mo.',
            status: 'error',
          });
        } else {
          onDrop && onDrop<File>(acceptedFiles[0]);
        }
      },
      maxSize,
      accept,
      maxFiles: 1,
      multiple: false,
      disabled: !onDrop,
    });

  return (
    <Dropzone
      alignItems="center"
      justifyContent="center"
      isFocused={isFocused}
      uploadProgress={uploadProgress}
      type={type}
      {...props}
      h={props.h ?? '100'}
      {...getRootProps({ isDragAccept })}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          rootRef.current?.blur();
        }
        if (e.code === 'Space' || e.key === 'Enter') {
          open();
        }
      }}
    >
      <input {...getInputProps()} />

      <Icon w="5" h="5" as={UploadCloud} />
      <Text noOfLines={4} lineHeight="24px" wordBreak="break-word">
        {fileName ?? description}
      </Text>
      {helper && <Text color="gray.300">{helper}</Text>}
    </Dropzone>
  );
};
