import { useCallback } from 'react';
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  Modal,
  Text,
  AlertIcon,
  Alert,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'lucide-react';
import { Check } from 'shared-domain';
import { useLoadSDK } from 'frontend-common';
import { LoadingSpinner } from '../feedback';
import { UbbleIframe } from '../data-display';

type ModalIdVerificationProps = {
  currentCheck: Check | null;
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  fetchMyAPI: () => void;
};

export const ModalIdVerification = (props: ModalIdVerificationProps) => {
  const { currentCheck, isOpen, onClose, fetchMyAPI, isLoading } = props;
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const { loaded: sdkLoaded } = useLoadSDK();

  const onComplete = useCallback(async () => {
    fetchMyAPI();
    onClose();
  }, [fetchMyAPI, onClose]);

  const onAbort = useCallback(async () => {
    toast({
      variant: 'toast_warning',
      title: 'Identity verification aborted',
      description: 'You have aborted your identity verification',
      status: 'warning',
      isClosable: false,
      position: 'bottom-right',
      duration: 3000,
    });
    onComplete();
  }, [onComplete, toast]);

  const onExpired = useCallback(async () => {
    toast({
      variant: 'toast_warning',
      title: 'Identity verification expired',
      description: 'Your identity verification has expired',
      status: 'warning',
      isClosable: false,
      position: 'bottom-right',
      duration: 3000,
    });
    onComplete();
  }, [onComplete, toast]);

  return (
    <Modal
      isOpen={isOpen}
      size="full"
      onClose={onComplete}
      isCentered
      scrollBehavior="inside"
      closeOnEsc={false}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>{t('verify_identity')}</ModalHeader>
        <ModalCloseButton color="white" />
        {(isLoading || !currentCheck) && <LoadingSpinner />}
        {!isLoading && currentCheck && (
          <ModalBody position="relative">
            {i18n.exists(`checks.id_verification.callout`) && (
              <Alert status="info" mb="6">
                <AlertIcon />
                <Text
                  dangerouslySetInnerHTML={{
                    __html: t(`checks.id_verification.callout`) ?? '',
                  }}
                ></Text>
              </Alert>
            )}

            {sdkLoaded && currentCheck.data.vendor.verification_url ? (
              <UbbleIframe
                url={currentCheck.data.vendor.verification_url}
                onComplete={onComplete}
                onAbort={onAbort}
                onExpired={onExpired}
              />
            ) : (
              <Loader />
            )}
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};
