import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Show,
  Spacer,
  Stack,
  Tag,
  useDisclosure,
} from '@chakra-ui/react';
import { EditIcon, PlusSquareIcon, Trash2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import {
  IndividualRoleEnum,
  individualSchema,
  fullNameHelper,
  FieldPropertyTypeEnum,
  CustomField,
  customFieldsExtendValidation,
  BaseStepIdEnum,
} from 'shared-domain';
import {
  useConfigContext,
  useProgress,
  useStore,
  useSubmit,
} from 'frontend-common';
import { createYupSchema } from 'shared-common';
import { SetApplicant, SetSignatory, SetDelegator } from '../shared';
import { ModalIndividual } from '../modal';

type IndividualsListProps = {
  hasApplicant: boolean;
  hasSignatory?: boolean;
  hasDelegator?: boolean;
};

export const IndividualsList = (props: IndividualsListProps) => {
  const { hasApplicant, hasSignatory, hasDelegator } = props;

  const { t } = useTranslation();
  const submitStep = useSubmit();
  const config = useConfigContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { individuals, metadata, customProperties, removeIndividual } =
    useStore();
  const { saveProgress } = useProgress();

  const [individualsAreValid, setIndividualsAreValid] = useState<boolean>(true);
  const [individualIsValid, setIndividualIsValid] = useState<
    { id: string; isValid: boolean }[]
  >([]);
  const [selectedIndividualId, setSelectedIndividualId] = useState<
    string | null
  >(null);

  const dynamicValidationSchema = useMemo(() => {
    // Extend fields with our validations
    const dynamicFormData = customFieldsExtendValidation(
      config.individualFields.filter(
        (f: CustomField) =>
          f.propertyType === FieldPropertyTypeEnum.metadata ||
          f.propertyType === FieldPropertyTypeEnum.custom,
      ),
      t,
      BaseStepIdEnum.individuals_list,
    );
    // Create schema based on added validations
    // @TODO - OPS-9 - Replace Yup by Zod
    return dynamicFormData.reduce(createYupSchema, {});
  }, [config.individualFields]);

  const validIndividuals = async () => {
    let createIndividualIsValid: { id: string; isValid: boolean }[] = [];
    setIndividualsAreValid(true);

    for (const individual of individuals) {
      let isValid = await individualSchema(
        config.individualFields.filter(
          (f: CustomField) =>
            f.propertyType !== FieldPropertyTypeEnum.metadata &&
            f.propertyType !== FieldPropertyTypeEnum.custom,
        ),
      )
        .shape(dynamicValidationSchema)
        .isValid({ ...individual, ...metadata, ...customProperties });

      if (individual.roles.includes(IndividualRoleEnum.applicant))
        isValid = isValid && !!individual.email;

      if (!isValid) {
        setIndividualsAreValid(false);
      }

      createIndividualIsValid = [
        ...createIndividualIsValid,
        {
          id: individual.id as string,
          isValid,
        },
      ];
    }

    setIndividualIsValid(createIndividualIsValid);
  };

  useEffect(() => {
    validIndividuals();
    saveProgress();
  }, [individuals]);

  const noSelectedSignatory = useMemo(
    () =>
      hasSignatory &&
      individuals.findIndex(
        (individual) => individual.custom_properties?.['is_signatory'] === true,
      ) === -1,
    [individuals, hasSignatory],
  );

  const noSelecteDelegator = useMemo(
    () =>
      hasDelegator &&
      individuals.findIndex(
        (individual) => individual.is_delegator === true,
      ) === -1,
    [individuals, hasDelegator],
  );

  const noSelectedApplicant = useMemo(
    () =>
      hasApplicant &&
      individuals.findIndex((individual) =>
        individual.roles.includes(IndividualRoleEnum.applicant),
      ) === -1,
    [individuals, hasApplicant],
  );

  return (
    <Stack spacing={5} pt={2}>
      {individuals.map((individual) => {
        return (
          <Box
            borderWidth="1px"
            borderRadius="lg"
            background="white"
            boxShadow="1px 1px 16px rgba(153, 153, 153, 0.1)"
            padding={5}
            key={individual.id}
          >
            <Flex
              direction={{ base: 'column', sm: 'row' }}
              alignItems={{ base: 'start', sm: 'center' }}
            >
              <Heading size="sm">{fullNameHelper(individual)}</Heading>
              <Spacer />
              {individualIsValid.length > 0 &&
                individualIsValid.find((t) => t.id === individual.id) &&
                !individualIsValid[
                  individualIsValid.findIndex((t) => t.id === individual.id)
                ].isValid && (
                  <Tag
                    colorScheme="yellow"
                    mr={4}
                    mt={{ base: '12px', md: '0' }}
                  >
                    {t('steps.individuals_list.missing_data')}
                  </Tag>
                )}
              <Show above="sm">
                <Box mt={{ base: '12px', md: '0' }}>
                  <Button
                    mr={5}
                    leftIcon={<EditIcon size={16} />}
                    size="sl"
                    onClick={() => {
                      setSelectedIndividualId(individual.id as string);
                      onOpen();
                    }}
                    variant="secondary"
                  >
                    {t('steps.individuals_list.edit')}
                  </Button>
                  <Button
                    leftIcon={<Trash2 size={16} />}
                    onClick={() => removeIndividual(individual.id as string)}
                    size="sl"
                    variant="secondary"
                  >
                    {t('steps.individuals_list.delete')}
                  </Button>
                </Box>
              </Show>
            </Flex>
            {individual.roles &&
              individual.roles
                // .filter(
                //   (role: IndividualRoleEnum) =>
                //     role !== IndividualRoleEnum.applicant,
                // )
                .map((role: IndividualRoleEnum) => (
                  <Tag key={role} mt={4} mr={4}>
                    {t(`domain.individual.roles.${role}`)}
                  </Tag>
                ))}
            <Show below="sm">
              <Box mt={{ base: '12px', md: '0' }}>
                <Button
                  mr={5}
                  leftIcon={<EditIcon size={16} />}
                  size="sl"
                  onClick={() => {
                    setSelectedIndividualId(individual.id as string);
                    onOpen();
                  }}
                  variant="secondary"
                >
                  {t('steps.individuals_list.edit')}
                </Button>
                <Button
                  leftIcon={<Trash2 size={16} />}
                  onClick={() => removeIndividual(individual.id as string)}
                  size="sl"
                  variant="secondary"
                >
                  {t('steps.individuals_list.delete')}
                </Button>
              </Box>
            </Show>
          </Box>
        );
      })}
      <Box>
        <Button
          variant="add_individual"
          leftIcon={<PlusSquareIcon size={16} />}
          onClick={() => {
            setSelectedIndividualId(null);
            onOpen();
          }}
        >
          {t('steps.individuals_list.add_individual')}
        </Button>
      </Box>
      {hasApplicant && individuals.length > 0 && <SetApplicant />}
      {hasSignatory && individuals.length > 0 && <SetSignatory />}
      {hasDelegator && individuals.length > 0 && <SetDelegator />}
      <Box>
        <Button
          variant="next"
          onClick={submitStep}
          isDisabled={
            individuals.length === 0 ||
            !individualsAreValid ||
            noSelectedApplicant ||
            noSelectedSignatory ||
            noSelecteDelegator
          }
        >
          {t('domain.form.next')}
        </Button>
      </Box>
      {isOpen && (
        <ModalIndividual
          selectedIndividualId={selectedIndividualId}
          onClose={onClose}
        />
      )}
    </Stack>
  );
};
