// @TODO - OPS-9 - Replace Yup by Zod

import * as Yup from 'yup';
import { isRequiredField, optionalStringRule } from './schema.helper';

import { IndividualRoleEnum } from '../types';
import { AffiliatedCompanyFields } from '../types';
import { COMPANY_NAME_MAX_LENGTH } from '../constants';

// Mandatory fields to create a company
const mandatoryFields = Yup.object({
  name: Yup.string().max(COMPANY_NAME_MAX_LENGTH).required().label('Name'),
  registration_number: Yup.string().required().label('Registration number'),
  country: Yup.string().length(2).uppercase().required().label('Country'),
});

export const affiliatedCompanySchema = (
  affiliatedCompanyField: AffiliatedCompanyFields[],
) => {
  return mandatoryFields.shape({
    legal_form: isRequiredField(affiliatedCompanyField, 'legal_form')
      ? Yup.string().required().label('Legal form')
      : optionalStringRule.label('Legal form'),
    registration_date: isRequiredField(
      affiliatedCompanyField,
      'registration_date',
    )
      ? Yup.date()
          .max(new Date(), 'Registration date must be at earlier than today')
          .required()
          .label('Registration date')
      : Yup.date()
          .max(new Date(), 'Registration date must be at earlier than today')
          .optional()
          .nullable()
          .transform((v) => (!isNaN(v) && v instanceof Date ? v : null))
          .label('Registration date'),
    address: Yup.object({
      street_address: isRequiredField(affiliatedCompanyField, 'street_address')
        ? Yup.string().required().label('Street address')
        : optionalStringRule.label('Street address'),
      street_address_2: isRequiredField(
        affiliatedCompanyField,
        'street_address_2',
      )
        ? Yup.string().required().label('Street address 2')
        : optionalStringRule.label('Street address 2'),
      postal_code: isRequiredField(affiliatedCompanyField, 'postal_code')
        ? Yup.string().required().label('Postal code')
        : optionalStringRule.label('Postal code'),
      city: isRequiredField(affiliatedCompanyField, 'city')
        ? Yup.string().required().label('City')
        : optionalStringRule.label('City'),
      state: isRequiredField(affiliatedCompanyField, 'state')
        ? Yup.string().required().label('State')
        : optionalStringRule.label('State'),
      region: isRequiredField(affiliatedCompanyField, 'region')
        ? Yup.string().required().label('Region')
        : optionalStringRule.label('Region'),
      country: isRequiredField(affiliatedCompanyField, 'country', 'address')
        ? Yup.string().length(2).uppercase().required().label('Country')
        : optionalStringRule.length(2).uppercase().label('Country'),
    }),
    roles: isRequiredField(affiliatedCompanyField, 'roles')
      ? Yup.array(Yup.mixed().oneOf(Object.values(IndividualRoleEnum)))
          .min(1)
          .required()
          .label('Roles')
      : Yup.array(Yup.mixed().oneOf(Object.values(IndividualRoleEnum))).label(
          'Roles',
        ),
    position: isRequiredField(affiliatedCompanyField, 'position')
      ? Yup.string().required().label('Position')
      : optionalStringRule.label('Position'),
    ownership_percentage: isRequiredField(
      affiliatedCompanyField,
      'ownership_percentage',
    )
      ? Yup.number()
          .min(0)
          .max(100)
          .transform((value) => (isNaN(value) || value === '' ? null : value))
          .required()
          .label('Ownership percentage')
      : Yup.number()
          .min(0)
          .max(100)
          .transform((value) => (isNaN(value) || value === '' ? null : value))
          .optional()
          .nullable()
          .default(null)
          .label('Ownership percentage'),
    classifications: Yup.array()
      .transform((value, original) => {
        return original;
      })
      .of(
        Yup.object({
          code: isRequiredField(
            affiliatedCompanyField,
            'code',
            'classifications',
          )
            ? Yup.string().required().label('Classification code')
            : optionalStringRule.label('Classification code'),
          description: isRequiredField(
            affiliatedCompanyField,
            'description',
            'classifications',
          )
            ? Yup.string().required().label('Classification description')
            : optionalStringRule.label('Classification description'),
        }),
      )
      .nullable(),
  });
};
