import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChakraStylesConfig, Select } from 'chakra-react-select';
import { COUNTRIES, Country } from 'shared-domain';
import { SelectOption } from './select';

type CountrySelectProps = {
  onChange: any;
  countries?: Country[];
  defaultValue: string | string[];
  isMulti?: boolean;
  placeholder?: string;
  isCustomProperty?: boolean;
};

export const CountrySelect = (props: CountrySelectProps) => {
  const {
    onChange,
    countries,
    defaultValue,
    isMulti = false,
    isCustomProperty = false,
    placeholder,
  } = props;

  const { t } = useTranslation();

  const countryList =
    countries && countries?.length > 0 ? countries : COUNTRIES;

  const options = countryList.map((c) => {
    return { value: c.code, label: `${c.emoji} ${c.name}` };
  });

  const resolvedDefaultValue = useMemo(() => {
    if (typeof defaultValue === 'string') {
      return options.find((o) => o.value === (defaultValue as unknown));
    }
    if (Array.isArray(defaultValue)) {
      return defaultValue.map((value) =>
        options.find((o) => o.value === value),
      );
    }
    return defaultValue;
  }, [defaultValue]);

  const chakraStyles: ChakraStylesConfig = {
    container: (provided) => ({
      ...provided,
      maxW: '400px',
    }),
  };

  return (
    <Select
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      placeholder={placeholder ?? t('domain.form.select')}
      useBasicStyles
      chakraStyles={chakraStyles}
      defaultValue={resolvedDefaultValue}
      isClearable={true}
      isMulti={isMulti}
      closeMenuOnSelect={!isMulti}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options={options}
      onChange={(option) => {
        if (Array.isArray(option)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange(option.map((o: SelectOption) => o.value));
        } else if (isCustomProperty) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange(option ? [option.value] : []);
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange(option ? option.value : '');
        }
      }}
    />
  );
};
