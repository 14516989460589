import { useSearchParams } from 'react-router-dom';
import { useConfigContext } from '../contexts';
import * as Yup from 'yup';
import { COUNTRIES, QueryParam } from 'shared-domain';

export function useRequiredParams(): { error: string | null } {
  const config = useConfigContext();
  const [searchParams] = useSearchParams();

  // Query params validation
  if (
    config.queryParamsRequired?.length > 0 &&
    (!searchParams.get('caseId') ||
      config.queryParamsRequired.includes('caseId'))
  ) {
    const queryParamsMissing: string[] = [];

    config.queryParamsRequired.forEach((queryParamRequired: QueryParam) => {
      // Query params missing
      if (!searchParams.has(queryParamRequired)) {
        queryParamsMissing.push(queryParamRequired);
      }

      // Query param email not valid
      if (
        searchParams.has(queryParamRequired) &&
        queryParamRequired === 'email'
      ) {
        const valid = Yup.string()
          .email()
          .isValidSync(searchParams.get('email'));

        if (!valid) {
          queryParamsMissing.push(queryParamRequired);
        }
      }

      // Query param country not valid
      if (
        searchParams.has(queryParamRequired) &&
        queryParamRequired === 'country'
      ) {
        const valid = Yup.string()
          .uppercase()
          .oneOf(COUNTRIES.map((country) => country.code))
          .length(2)
          .isValidSync(searchParams.get('country'));

        if (!valid) {
          queryParamsMissing.push(queryParamRequired);
        }
      }
    });
    if (
      queryParamsMissing.length > 0 &&
      (!searchParams.get('caseId') ||
        config.queryParamsRequired.includes('caseId'))
    ) {
      return { error: `Missing query param: ${queryParamsMissing.join(', ')}` };
    }
  }

  return { error: null };
}
