import { Box, Input } from '@chakra-ui/react';
import { generatedFieldName } from 'frontend-common';
import { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { FieldPropertyTypeEnum } from 'shared-domain';
import { GroupController, Select } from '../../form';
import { useTranslation } from 'react-i18next';
import { ControlledInputProps } from '../type';
import { OTHER } from './utils';

export const ControlledSelect: FunctionComponent<ControlledInputProps> = ({
  stepId,
  field,
}) => {
  const { getValues, setValue, control, watch } = useFormContext();
  const { t } = useTranslation();

  const fieldName = generatedFieldName(field);
  const value = watch(fieldName);
  if (
    value &&
    !value.includes(OTHER) &&
    getValues(`${fieldName}_${OTHER}`) &&
    field.hasOtherOption
  ) {
    setValue(`${fieldName}_${OTHER}`, '');
  }

  return (
    <Box w="100%" key={fieldName}>
      <GroupController
        data-testid={`select-${field.id}`}
        name={fieldName}
        label={t(`steps.${stepId}.${fieldName}.label`) || fieldName}
        helper={
          field.hasHelper ? t(`steps.${stepId}.${fieldName}.helper`) : null
        }
        isRequired={field.isRequired}
        control={control}
        render={(f) => (
          <Select
            stepId={stepId}
            name={fieldName}
            onChange={(value: string[] | string) => {
              setValue(
                fieldName,
                value ??
                  (field.isMulti ||
                  field.propertyType === FieldPropertyTypeEnum.custom
                    ? []
                    : ''),
                {
                  shouldDirty: true,
                  shouldValidate: true,
                },
              );
            }}
            options={field.options || []}
            defaultValue={f.value}
            isMulti={field.isMulti}
            isCustomProperty={
              field.propertyType === FieldPropertyTypeEnum.custom
            }
            hasOtherOption={field.hasOtherOption}
            placeholder={
              field.hasPlaceholder
                ? t(`steps.${stepId}.${fieldName}.placeholder`)
                : undefined
            }
          />
        )}
      />
      {value?.includes(OTHER) && field.hasOtherOption && (
        <GroupController
          mt={5}
          name={`${fieldName}_${OTHER}`}
          label={t(`steps.${stepId}.${fieldName}.${OTHER}_option.label`)}
          isRequired={true}
          control={control}
          render={(f) => {
            return <Input type="text" maxW="400px" {...f} />;
          }}
        />
      )}
    </Box>
  );
};
