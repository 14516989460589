import {
  AffiliatedCompanyInput,
  CustomProperties,
  Metadata,
} from 'shared-domain';
import { useStore } from './use-store';
import { useSubmit } from './use-submit';

type SubmitAffiliatedCompanyForm = {
  affiliatedCompanyData: AffiliatedCompanyInput;
  affiliatedCompanyId?: string | null;
  caseMetadata?: Metadata;
  caseCustomProperties?: CustomProperties;
  onClose?: () => void;
};

export const useSubmitAffiliatedCompanyForm = (): {
  submitAffiliatedCompanyForm: (input: SubmitAffiliatedCompanyForm) => void;
} => {
  const submitStep = useSubmit();
  const {
    metadata,
    customProperties,
    updateAffiliatedCompany,
    addAffiliatedCompany,
    updateMetadata,
    updateCustomProperties,
  } = useStore();

  const submitAffiliatedCompanyForm = (input: SubmitAffiliatedCompanyForm) => {
    // Save affiliated company data
    if (input.affiliatedCompanyId) {
      updateAffiliatedCompany(
        input.affiliatedCompanyId,
        input.affiliatedCompanyData,
      );
    } else {
      addAffiliatedCompany(input.affiliatedCompanyData);
    }

    // Save case metadata
    if (input.caseMetadata) {
      updateMetadata({ ...metadata, ...input.caseMetadata });
    }

    // Save case custom properties
    if (input.caseCustomProperties) {
      updateCustomProperties({
        ...customProperties,
        ...input.caseCustomProperties,
      });
    }

    // Submit step
    if (input.onClose) {
      input.onClose();
    } else {
      submitStep();
    }
  };

  return { submitAffiliatedCompanyForm };
};
