import { FunctionComponent } from 'react';
import { ControlledInputProps } from '../type';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatedFieldName } from 'frontend-common';
import { Textarea } from '@chakra-ui/react';
import { GroupController } from '../../form';

export const ControlledTextArea: FunctionComponent<ControlledInputProps> = ({
  stepId,
  field,
}) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  const fieldName = generatedFieldName(field);
  return (
    <GroupController
      key={fieldName}
      name={fieldName}
      label={t(`steps.${stepId}.${fieldName}.label`) || fieldName}
      helper={field.hasHelper ? t(`steps.${stepId}.${fieldName}.helper`) : null}
      isRequired={field.isRequired}
      control={control}
      render={(f) => {
        return (
          <Textarea
            maxW="400px"
            resize="vertical"
            placeholder={
              field.hasPlaceholder
                ? t(`steps.${stepId}.${fieldName}.placeholder`)
                : undefined
            }
            {...f}
          />
        );
      }}
    />
  );
};
