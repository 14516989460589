import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Tag,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  CheckCircle2,
  DownloadIcon,
  ExternalLinkIcon,
  PlayCircle,
  Timer,
  XCircle,
} from 'lucide-react';
import { useTranslation } from 'react-i18next';
import {
  Check,
  CheckStatusEnum,
  CheckTypeEnum,
  Company,
  Individual,
} from 'shared-domain';

import { ModalIdVerification } from '../modal';
import { useApi } from 'frontend-common';

type CheckItemProps = {
  entity: Company | Individual | null;
  check: Check;
  currentCheck: Check | null;
  selectCheck: (check: Check) => void;
  setCurrentEntity: Dispatch<SetStateAction<Company | Individual | null>>;
  onOpen: () => void;
  fetchMyAPI: () => void;
};

export const CheckItem = (props: CheckItemProps) => {
  const {
    check,
    currentCheck,
    selectCheck,
    setCurrentEntity,
    entity,
    onOpen,
    fetchMyAPI,
  } = props;
  const [isLoadingIdv, setIsLoadingIdv] = useState(true);

  const api = useApi();
  const { t, i18n } = useTranslation();
  const {
    isOpen: isOpenIdv,
    onOpen: onOpenIdv,
    onClose: onCloseIdv,
  } = useDisclosure();

  const onClickIdv = useCallback(
    async (currentCheck: Check) => {
      // refresh and restart on rejected
      const { data }: { data: Check } = await api.post(
        `/dotfile/identity-verifications/${currentCheck.id}`,
      );

      setCurrentEntity(entity);
      selectCheck(data);
      setIsLoadingIdv(false);
    },
    [api, setCurrentEntity, selectCheck, entity],
  );

  const exactType = (currentCheck: Check) => {
    return currentCheck.type === CheckTypeEnum.document && currentCheck.subtype
      ? currentCheck.subtype.split(':')[1]
      : currentCheck.type;
  };

  // @NOTE Add a fallback title when a custom document type created after the onboarding flow init
  const checkTitleFallBack = useMemo(() => {
    if (
      check.type === CheckTypeEnum.document &&
      check.subtype?.includes('custom_document_type')
    ) {
      return check.data.settings.custom_document_type?.label ?? '';
    }

    return check.type;
  }, [check]);

  return (
    <>
      <Box px="4" py="6" width="100%">
        <Flex
          direction={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'stretch', md: 'center' }}
          justifyContent="space-between"
        >
          <Box>
            <Heading size="sm">
              {t(`checks.${exactType(check)}.title`, checkTitleFallBack)}
            </Heading>
            {i18n.exists(`checks.${exactType(check)}.helper`) && (
              <Text
                color="gray.600"
                lineHeight="normal"
                fontSize="sm"
                mt="1"
                mb="2"
                dangerouslySetInnerHTML={{
                  __html: t(`checks.${exactType(check)}.helper`) ?? '',
                }}
              ></Text>
            )}
          </Box>

          <Flex
            direction={{ base: 'column', md: 'row' }}
            alignItems={{ base: 'stretch', md: 'center' }}
            ml={{ base: '0', md: '3' }}
          >
            <Box>
              {check.status === CheckStatusEnum.rejected && (
                <Tag
                  mr={{ base: 0, md: 5 }}
                  mt={{ base: 3, md: 0 }}
                  colorScheme="red"
                >
                  <Box as="span" mr="2">
                    <XCircle size={16} />
                  </Box>
                  {t('domain.check.status.rejected')}
                </Tag>
              )}
              {check.status === CheckStatusEnum.approved && (
                <Tag
                  mr={{ base: 0, md: 5 }}
                  mt={{ base: 3, md: 0 }}
                  colorScheme="green"
                >
                  <Box as="span" mr="2">
                    <CheckCircle2 size={16} />
                  </Box>
                  {t('domain.check.status.approved')}
                </Tag>
              )}
              {(check.status === CheckStatusEnum.need_review ||
                check.status === CheckStatusEnum.processing) && (
                <Tag
                  mr={{ base: 0, md: 5 }}
                  mt={{ base: 3, md: 0 }}
                  colorScheme="blue"
                >
                  <Box as="span" mr="2">
                    <PlayCircle size={16} />
                  </Box>
                  {t('domain.check.status.need_review')}
                </Tag>
              )}
              {check.status === CheckStatusEnum.expired && (
                <Tag
                  mr={{ base: 0, md: 5 }}
                  mt={{ base: 3, md: 0 }}
                  colorScheme="yellow"
                >
                  <Box as="span" mr="2">
                    <Timer size={16} />
                  </Box>
                  {t('domain.check.status.expired')}
                </Tag>
              )}
            </Box>

            {check.type === CheckTypeEnum.id_verification &&
              (check.status === CheckStatusEnum.in_progress ||
                check.status === CheckStatusEnum.rejected) && (
                <Button
                  variant="outline"
                  leftIcon={<ExternalLinkIcon size={16} />}
                  width={['100%', 'auto', 'auto']}
                  id={check.id}
                  name={check.type}
                  onClick={() => {
                    onOpenIdv();
                    onClickIdv(check);
                  }}
                  mt={{ base: '12px', md: '0' }}
                >
                  {t('verify_identity')}
                </Button>
              )}

            {check.type !== CheckTypeEnum.id_verification &&
              [
                CheckStatusEnum.expired,
                CheckStatusEnum.rejected,
                CheckStatusEnum.in_progress,
              ].includes(check.status) && (
                <Button
                  leftIcon={<DownloadIcon size={16} />}
                  width={['100%', 'auto', 'auto']}
                  id={check.id}
                  name={check.type}
                  variant="outline"
                  onClick={() => {
                    selectCheck(check);
                    setCurrentEntity(entity);
                    onOpen();
                  }}
                  mt={{ base: '12px', md: '0' }}
                >
                  {t('upload_document')}
                </Button>
              )}
          </Flex>
        </Flex>
        {check.status === CheckStatusEnum.rejected && (
          <Text color="red.600" mt={{ base: '12px', md: '0' }}>
            {check.data.review.comment}
          </Text>
        )}
      </Box>

      {isOpenIdv && check.type === CheckTypeEnum.id_verification && (
        <ModalIdVerification
          isLoading={isLoadingIdv}
          currentCheck={currentCheck}
          isOpen={isOpenIdv}
          onClose={onCloseIdv}
          fetchMyAPI={fetchMyAPI}
        />
      )}
    </>
  );
};
