import { ComponentType } from 'react';
import { Box, Flex, Text, useToken } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { useConfigContext } from 'frontend-common';

import { DotfileLogo } from '../assets';
import { SelectLang } from './select-lang';

type SidebarProps = {
  logo: ComponentType<{
    css?: string;
  }>;
};

export const Sidebar = (props: SidebarProps) => {
  const config = useConfigContext();
  const [logoColor, backgroundImage, backgroundSize] = useToken('colors', [
    'sidebar.dotfileLogo',
    'sidebar.backgroundImage',
    'sidebar.backgroundSize',
  ]);
  const { t } = useTranslation();

  const Logo = props.logo;

  return (
    <Flex
      minH="100%"
      position="fixed"
      background="sidebar.backgroundColor"
      backgroundImage={backgroundImage}
      backgroundSize={backgroundSize}
      direction="column"
      p="2vw"
      w="25vw"
    >
      <Flex pt={10} pb={10}>
        <Logo />
      </Flex>
      <Box flexGrow={1}>
        <Text
          fontWeight={700}
          color="sidebar.color"
          fontSize={{ base: '3xl', sm: '3xl', md: '3xl' }}
        >
          <Trans t={t} i18nKey="brand.title">
            A brand motto with some
            <Text
              as="span"
              fontWeight={700}
              color="brand.secondary"
              fontSize={{ base: '3xl', sm: '3xl', md: '3xl' }}
            >
              accent words
            </Text>
            if needed.
          </Trans>
        </Text>
      </Box>
      {config.showPoweredLogo && (
        <Flex alignItems="center" mb="5vh">
          <Text
            color="sidebar.color"
            mr="10px"
            fontSize="14px"
            lineHeight="2Opx"
            fontWeight="500"
            display="inline-block"
          >
            <b>Powered by</b>
          </Text>
          <DotfileLogo color={logoColor} />
        </Flex>
      )}
      {config.languages?.length > 1 && <SelectLang />}
    </Flex>
  );
};
