// @TODO - OPS-9 - Replace Yup by Zod

import * as Yup from 'yup';
import { parsePhoneNumber } from 'libphonenumber-js';
import { isRequiredField, optionalStringRule } from './schema.helper';

import { IndividualRoleEnum } from '../types';
import { CustomField } from '../types';

const rEmail =
  // eslint-disable-next-line
  /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

const EMAIL_NOT_VALID = 'Email must be a valid email';
Yup.addMethod(
  Yup.string,
  'email',
  function validateEmail(message = EMAIL_NOT_VALID) {
    return this.matches(
      // re-use the same regex as yup@0.33 used to be compliant with dotfile external-api
      // we have to override the existing regex since Yup change his way to validate an email: see https://github.com/jquense/yup#stringemailmessage-string--function-schema
      // eslint-disable-next-line no-control-regex
      rEmail,
      {
        message,
        name: 'email',
        excludeEmptyString: true,
      },
    );
  },
);

// Mandatory fields to create an individual
const mandatoryFields = Yup.object({
  first_name: Yup.string().required().label('First name'),
  last_name: Yup.string().required().label('Last name'),
});

const PHONE_NUMBER_ERROR_MESSAGE =
  'Phone number should follow international standard format';

export const individualSchema = (individualField: CustomField[]) => {
  return mandatoryFields.shape({
    middle_name: isRequiredField(individualField, 'middle_name')
      ? Yup.string().required().label('Middle name')
      : optionalStringRule.label('Middle name'),
    maiden_name: isRequiredField(individualField, 'maiden_name')
      ? Yup.string().required().label('Maiden name')
      : optionalStringRule.label('Maiden name'),
    email: isRequiredField(individualField, 'email')
      ? Yup.string()
          .email()
          .transform((v) => (v === '' ? null : v))
          .required()
          .label('Email')
      : Yup.string()
          .email()
          .required()
          .when('roles', {
            is: (roles: IndividualRoleEnum[]) =>
              roles && roles.includes(IndividualRoleEnum.applicant),
            then: (schema) =>
              schema
                .transform((v) => (v === '' ? null : v))
                .required()
                .label('Email'),
            otherwise: (schema) =>
              schema
                .optional()
                .nullable()
                .default(null)
                .transform((v) => (v === '' ? null : v))
                .label('Email'),
          }),
    roles: isRequiredField(individualField, 'roles')
      ? Yup.array(Yup.mixed().oneOf(Object.values(IndividualRoleEnum)))
          .min(1)
          .required()
          .label('Roles')
      : Yup.array(Yup.mixed().oneOf(Object.values(IndividualRoleEnum))).label(
          'Roles',
        ),
    birth_date: isRequiredField(individualField, 'birth_date')
      ? Yup.date()
          .max(new Date(), 'Birth date must be at earlier than today')
          .required()
          .label('Birth date')
      : Yup.date()
          .max(new Date(), 'Birth date must be at earlier than today')
          .optional()
          .nullable()
          .transform((v) => (!isNaN(v) && v instanceof Date ? v : null))
          .label('Birth date'),
    birth_country: isRequiredField(individualField, 'birth_country')
      ? Yup.string().length(2).uppercase().required().label('Birth country')
      : optionalStringRule.length(2).uppercase().label('Birth country'),
    birth_place: isRequiredField(individualField, 'birth_place')
      ? Yup.string().required().label('Birth place')
      : optionalStringRule.label('Birth place'),
    address: Yup.object({
      street_address: isRequiredField(individualField, 'street_address')
        ? Yup.string().required().label('Street address')
        : optionalStringRule.label('Street address'),
      street_address_2: isRequiredField(individualField, 'street_address_2')
        ? Yup.string().required().label('Street address 2')
        : optionalStringRule.label('Street address 2'),
      postal_code: isRequiredField(individualField, 'postal_code')
        ? Yup.string().required().label('Postal code')
        : optionalStringRule.label('Postal code'),
      city: isRequiredField(individualField, 'city')
        ? Yup.string().required().label('City')
        : optionalStringRule.label('City'),
      state: isRequiredField(individualField, 'state')
        ? Yup.string().required().label('State')
        : optionalStringRule.label('State'),
      region: isRequiredField(individualField, 'region')
        ? Yup.string().required().label('Region')
        : optionalStringRule.label('Region'),
      country: isRequiredField(individualField, 'country', 'address')
        ? Yup.string().length(2).uppercase().required().label('Country')
        : optionalStringRule.length(2).uppercase().label('Country'),
    }),
    banking_information: Yup.object().shape(
      {
        iban: isRequiredField(individualField, 'iban')
          ? Yup.string().min(15).required().label('IBAN')
          : Yup.string()
              .default(null)
              .min(15)
              .transform((v) => (v === '' ? null : v))
              .when('bic', {
                is: (val: string) => val,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.nullable().optional(),
              })
              .label('IBAN'),
        bic: isRequiredField(individualField, 'bic')
          ? Yup.string().min(8).required().label('BIC')
          : Yup.string()
              .default(null)
              .min(8)
              .transform((v) => (v === '' ? null : v))
              .when('iban', {
                is: (val: string) => val,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.nullable().optional(),
              })
              .label('BIC'),
      },
      [['iban', 'bic']],
    ),
    tax_identification_number: isRequiredField(
      individualField,
      'tax_identification_number',
    )
      ? Yup.string().required().label('Tax ID')
      : optionalStringRule.label('Tax ID'),
    social_security_number: isRequiredField(
      individualField,
      'social_security_number',
    )
      ? Yup.string().required().label('Social security number')
      : optionalStringRule.label('Social security number'),
    phone_number: isRequiredField(individualField, 'phone_number')
      ? Yup.string()
          .test('phoneNumber', PHONE_NUMBER_ERROR_MESSAGE, (value) => {
            try {
              const parsed = parsePhoneNumber(value ?? '');

              return !!parsed && !!value && parsed.isValid();
            } catch (error) {
              return false;
            }
          })
          .required()
          .label('Phone number')
      : optionalStringRule
          .test('phoneNumber', PHONE_NUMBER_ERROR_MESSAGE, (value) => {
            if (!value) {
              return true;
            }

            try {
              const parsed = parsePhoneNumber(value ?? '');

              return !!parsed && !!value && parsed.isValid();
            } catch (error) {
              return false;
            }
          })
          .label('Phone number'),
    position: isRequiredField(individualField, 'position')
      ? Yup.string().required().label('Position')
      : optionalStringRule.label('Position'),
    ownership_percentage: isRequiredField(
      individualField,
      'ownership_percentage',
    )
      ? Yup.number()
          .min(0)
          .max(100)
          .transform((value) => (isNaN(value) || value === '' ? null : value))
          .required()
          .label('Ownership percentage')
      : Yup.number()
          .min(0)
          .max(100)
          .transform((value) => (isNaN(value) || value === '' ? null : value))
          .optional()
          .nullable()
          .default(null)
          .label('Ownership percentage'),
  });
};
