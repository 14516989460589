import { ComponentType } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  ChakraProvider,
  DeepPartial,
  extendTheme,
  Flex,
  useMediaQuery,
} from '@chakra-ui/react';
import { ColorModeScript } from '@chakra-ui/react';
import { ChakraTheme } from '@chakra-ui/theme';
import { Colors, RecursiveObject } from '@chakra-ui/theme/dist/theme.types';
import { AppConfig, EnvEnum, Environment, Locales } from 'shared-domain';
import {
  configurationI18n,
  LoadingContextProvider,
  configurationDatadog,
  EnvironmentContextProvider,
  ConfigContextProvider,
  nonceId,
} from 'frontend-common';
import { components, styles, swatch } from 'frontend-ui';

import { ErrorBoundary } from './error';
import { MobileHeader, Sidebar } from './layout';
import { App } from './app';
import { HelmetProvider } from 'react-helmet-async';
import {
  CSPHeaders,
  EmotionCacheProvider,
  extractObjectSrcFromStepConfig,
} from './security';

type DotfileOnboardingAppProps = {
  theme: {
    tokens: Colors;
    fonts: RecursiveObject<string>;
  };
  translations: Locales;
  config: Partial<Omit<AppConfig, 'environment'>> & {
    environment: Environment;
  };
  logo: ComponentType<{
    css?: string;
  }>;
};

export const DotfileOnboardingApp = (props: DotfileOnboardingAppProps) => {
  const { theme, translations, config, logo } = props;

  const [isMobile] = useMediaQuery('(max-width: 48em)');
  document.title = config.websiteTitle || '';

  const colors = {
    ...swatch,
    ...theme.tokens,
  };
  const extensions: DeepPartial<ChakraTheme> = {
    colors,
    fonts: theme.fonts,
    styles,
    components,
    // @NOTE: Cast to support Dropzone theming
  } as DeepPartial<ChakraTheme>;

  const extendedTheme = extendTheme(extensions);

  const initI18n = {
    debug: config.environment.env !== EnvEnum.production,
    lng: config.languages?.length === 1 ? config.languages[0] : undefined,
    translations,
    languages: config.languages || ['fr', 'en', 'de', 'es', 'it', 'nl'],
  };
  configurationI18n(initI18n);

  configurationDatadog(config.environment);

  const extraObjectSrc = extractObjectSrcFromStepConfig(config.stepsConfig);

  return (
    <HelmetProvider>
      <CSPHeaders
        environment={config.environment}
        nonce={nonceId}
        extraObjectSrc={extraObjectSrc}
        allowDatadogRUM
        allowUbble
      />
      <EnvironmentContextProvider value={config.environment}>
        <ConfigContextProvider value={config}>
          <LoadingContextProvider>
            <ColorModeScript />
            <EmotionCacheProvider nonce={nonceId}>
              <ChakraProvider theme={extendedTheme}>
                <ErrorBoundary>
                  <Router>
                    <Flex
                      direction={{ base: 'column', md: 'row' }}
                      minH="100vh"
                    >
                      {isMobile ? (
                        <MobileHeader logo={logo} />
                      ) : (
                        <Sidebar logo={logo} />
                      )}
                      <App />
                    </Flex>
                  </Router>
                </ErrorBoundary>
              </ChakraProvider>
            </EmotionCacheProvider>
          </LoadingContextProvider>
        </ConfigContextProvider>
      </EnvironmentContextProvider>
    </HelmetProvider>
  );
};
