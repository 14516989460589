import {
  DocumentAnalysisModelEnum,
  DocumentAnalysisProofOfAddressDocumentTypesEnum,
  DocumentAnalysisProofOfAddressInformationModel,
} from '../../../types';
import { DocumentAnalysisDefinition } from '../document-analysis-definition';

export class DocumentAnalysisProofOfAddressDefinition extends DocumentAnalysisDefinition<DocumentAnalysisProofOfAddressInformationModel> {
  override get model(): DocumentAnalysisModelEnum {
    return DocumentAnalysisModelEnum.proof_of_address;
  }

  override get information(): Record<
    keyof Omit<DocumentAnalysisProofOfAddressInformationModel, 'model'>,
    { label: string; translateKey: string }
  > {
    return {
      address: {
        label: 'Address',
        translateKey: 'steps.checks_list.document_analysis.information.address',
      },
      issuing_date: {
        label: 'Issuing date',
        translateKey:
          'steps.checks_list.document_analysis.information.issuing_date',
      },
      name: {
        label: 'Name',
        translateKey: 'steps.checks_list.document_analysis.information.name',
      },
      document_type: {
        label: 'Document type',
        translateKey:
          'steps.checks_list.document_analysis.information.document_type',
      },
      other_document_type: {
        label: 'Other Document type',
        translateKey:
          'steps.checks_list.document_analysis.information.other_document_type',
      },
      country: {
        label: 'Country',
        translateKey: 'steps.checks_list.document_analysis.information.country',
      },
      issuer: {
        label: 'Issuer',
        translateKey: 'steps.checks_list.document_analysis.information.issuer',
      },
    };
  }
  get documentTypes(): Record<
    DocumentAnalysisProofOfAddressDocumentTypesEnum,
    { label: string; translateKey: string }
  > {
    return {
      energy_bill: {
        label: 'Energy bill',
        translateKey:
          'steps.checks_list.document_analysis.document_types.energy_bill',
      },
      official_document: {
        label: 'Official document',
        translateKey:
          'steps.checks_list.document_analysis.document_types.official_document',
      },
      telco_bill: {
        label: 'Telecom bill',
        translateKey:
          'steps.checks_list.document_analysis.document_types.telco_bill',
      },
      other: {
        label: 'Other',
        translateKey:
          'steps.checks_list.document_analysis.document_types.other',
      },
    };
  }
}

export const documentAnalysisProofOfAddressDefinition =
  new DocumentAnalysisProofOfAddressDefinition();
