import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { v4 as uuidV4 } from 'uuid';
import { Action, AffiliatedCompanyInput, State } from 'shared-domain';

const initialState: State = {
  queryParams: {
    caseId: null,
    externalId: null,
    email: null,
    company: null,
    registrationNumber: null,
    country: null,
    lng: null,
    templateId: null,
    firstName: null,
    lastName: null,
  },
  totalSteps: 1,
  currentStep: 1,
  caseId: null,
  externalId: null,
  resumeFlow: false,
  showHeader: true,
  flags: [],
  metadata: {},
  customProperties: {},
  company: null,
  affiliatedCompanies: [],
  childrenCases: [],
  individuals: [],
  companies: [],
};

export const useStore = create<State & Action>()(
  devtools(
    (set, get) => ({
      ...initialState,
      // actions + selectors
      updateQueryParams: (queryParams) =>
        set(() => ({ queryParams }), false, {
          type: 'updateQueryParams', // @see https://github.com/pmndrs/zustand#logging-actions
          queryParams,
        }),
      updateShowHeader: (showHeader) =>
        set(() => ({ showHeader }), false, {
          type: 'updateShowHeader',
          showHeader,
        }),

      updateTotalSteps: (totalSteps) =>
        set(() => ({ totalSteps }), false, {
          type: 'updateTotalSteps',
          totalSteps,
        }),
      updateCurrentStep: (currentStep) =>
        set(() => ({ currentStep }), false, {
          type: 'updateCurrentStep',
          currentStep,
        }),
      increaseCurrentStep: () =>
        set((state) => ({ currentStep: state.currentStep + 1 }), false, {
          type: 'increaseCurrentStep',
        }),
      decreaseCurrentStep: () =>
        set((state) => ({ currentStep: state.currentStep - 1 }), false, {
          type: 'decreaseCurrentStep',
        }),

      updateCaseId: (caseId) =>
        set(() => ({ caseId }), false, {
          type: 'updateCaseId',
          caseId,
        }),
      updateExternalId: (externalId) =>
        set(() => ({ externalId }), false, {
          type: 'updateExternalId',
          externalId,
        }),
      updateResumeFlow: (resumeFlow: boolean) =>
        set(() => ({ resumeFlow }), false, {
          type: 'updateResumeFlow',
          resumeFlow,
        }),
      updateFlags: (flags) =>
        set(() => ({ flags }), false, {
          type: 'updateFlags',
          flags,
        }),
      updateMetadata: (metadata) =>
        set(() => ({ metadata }), false, {
          type: 'updateMetadata',
          metadata,
        }),
      updateCustomProperties: (customProperties) =>
        set(() => ({ customProperties }), false, {
          type: 'updateCustomProperties',
          customProperties,
        }),

      updateCompany: (company) =>
        set(() => ({ company }), false, {
          type: 'updateCompany',
          company,
        }),

      addAffiliatedCompanies: (affiliatedCompanies: AffiliatedCompanyInput[]) =>
        set(
          () => ({
            affiliatedCompanies: affiliatedCompanies.map(
              (affiliatedCompany) => {
                return {
                  ...affiliatedCompany,
                  id: affiliatedCompany.id ?? uuidV4(),
                };
              },
            ),
          }),
          false,
          {
            type: 'addAffiliatedCompanies',
            affiliatedCompanies,
          },
        ),
      addAffiliatedCompany: (affiliatedCompany) =>
        set(
          (state) => ({
            affiliatedCompanies: [
              ...state.affiliatedCompanies,
              {
                ...affiliatedCompany,
                id: affiliatedCompany.id ?? uuidV4(),
              },
            ],
          }),
          false,
          {
            type: 'addAffiliatedCompany',
            affiliatedCompany,
          },
        ),
      getAffiliatedCompany: (id) => {
        return get().affiliatedCompanies.find(
          (affiliatedCompany) => affiliatedCompany.id === id,
        );
      },
      updateAffiliatedCompany: (id, affiliatedCompany) =>
        set(
          (state) => ({
            affiliatedCompanies: state.affiliatedCompanies.map((ac) =>
              ac.id === id ? { ...affiliatedCompany, id } : ac,
            ),
          }),
          false,
          {
            type: 'updateAffiliatedCompany',
            id,
            affiliatedCompany,
          },
        ),
      removeAffiliatedCompany: (id) =>
        set(
          (state) => ({
            affiliatedCompanies: state.affiliatedCompanies.filter(
              (affiliatedCompany) => affiliatedCompany.id !== id,
            ),
          }),
          false,
          {
            type: 'removeAffiliatedCompany',
            id,
          },
        ),
      resetAffiliatedCompanies: () => set(() => ({ affiliatedCompanies: [] })),

      addIndividuals: (individuals) =>
        set(
          () => ({
            individuals: individuals.map((individual) => {
              return {
                ...individual,
                id: individual.id ?? uuidV4(),
              };
            }),
          }),
          false,
          {
            type: 'addIndividuals',
            individuals,
          },
        ),
      addIndividual: (individual) =>
        set(
          (state) => ({
            individuals: [
              ...state.individuals,
              {
                ...individual,
                id: individual.id ?? uuidV4(),
              },
            ],
          }),
          false,
          {
            type: 'addIndividual',
            individual,
          },
        ),
      getIndividual: (id) =>
        get().individuals.find((individual) => individual.id === id) ?? null,
      updateIndividual: (id, individual) =>
        set(
          (state) => ({
            individuals: state.individuals.map((ind) =>
              ind.id === id ? { ...individual, id } : ind,
            ),
          }),
          false,
          {
            type: 'updateIndividual',
            id,
            individual,
          },
        ),
      removeIndividual: (id) =>
        set(
          (state) => ({
            individuals: state.individuals.filter(
              (individual) => individual.id !== id,
            ),
          }),
          false,
          {
            type: 'removeIndividual',
            id,
          },
        ),
      resetIndividuals: () =>
        set(() => ({ individuals: [] }), false, {
          type: 'resetIndividuals',
        }),

      addCompanies: (companies) =>
        set(() => ({ companies }), false, {
          type: 'addCompanies',
          companies,
        }),

      addChildrenCases: (childrenCases) =>
        set(() => ({ childrenCases }), false, {
          type: 'addChildrenCases',
          childrenCases,
        }),
    }),
    {
      // @TODO - TDB - Properly detect dev mode for dev tools
      // The process.env is injected in rspack config in `tools/rspack/rspack-react.config.js`
      // But only some values are available
      // Since the store is created outside the apps and react context, it's not possible
      // to use the environment/EnvEnum to detect the developpment mode
      // Currently this use the presence of RENDER_GIT_COMMIT to detect it but this is not optimal
      enabled: !process.env['RENDER_GIT_COMMIT'],
      name: 'Onboarding flow',
    },
  ),
);
