import {
  DocumentAnalysisIBANInformationModel,
  DocumentAnalysisModelEnum,
} from '../../../types';
import { DocumentAnalysisDefinition } from '../document-analysis-definition';

export class DocumentAnalysisIBANDefinition extends DocumentAnalysisDefinition<DocumentAnalysisIBANInformationModel> {
  override get model(): DocumentAnalysisModelEnum {
    return DocumentAnalysisModelEnum.iban;
  }

  override get information(): Record<
    keyof Omit<DocumentAnalysisIBANInformationModel, 'model'>,
    { label: string; translateKey: string }
  > {
    return {
      holder_name: {
        label: 'Holder name',
        translateKey:
          'steps.checks_list.document_analysis.information.holder_name',
      },
      bank_name: {
        label: 'Bank name',
        translateKey:
          'steps.checks_list.document_analysis.information.bank_name',
      },
      bic: {
        label: 'BIC',
        translateKey: 'steps.checks_list.document_analysis.information.bic',
      },
      iban: {
        label: 'IBAN',
        translateKey: 'steps.checks_list.document_analysis.information.iban',
      },
    };
  }
}

export const documentAnalysisIBANDefinition =
  new DocumentAnalysisIBANDefinition();
