import {
  DocumentAnalysisIBANDetailedResultsModel,
  DocumentAnalysisIBANInformationModel,
  DocumentAnalysisModelEnum,
  getDocumentAnalysisDefinition,
} from 'shared-domain';
import { DataComparisonLabel } from './data-comparison-label';
import { DataValidationLabel } from './data-validation-label';
import { AnalysisDetailedResult } from './types';
import { nameFormat } from 'shared-common';

const definition = getDocumentAnalysisDefinition(
  DocumentAnalysisModelEnum.iban,
);

export const analysisIBANDetailedResults: AnalysisDetailedResult<
  DocumentAnalysisIBANDetailedResultsModel,
  DocumentAnalysisIBANInformationModel
> = {
  valid_iban: ({ detailedResult }) => (
    <DataValidationLabel
      informationDefinition={definition.information.iban}
      detailedResult={detailedResult}
    />
  ),
  valid_bic: ({ detailedResult }) => (
    <DataValidationLabel
      informationDefinition={definition.information.bic}
      detailedResult={detailedResult}
    />
  ),
  iban_matching: ({ detailedResult, entity }) => (
    <DataComparisonLabel
      informationDefinition={definition.information.iban}
      detailedResult={detailedResult}
      entityName={
        entity.company?.name ??
        nameFormat(entity.individual?.first_name, entity.individual?.last_name)
      }
    />
  ),
  bic_matching: ({ detailedResult, entity }) => (
    <DataComparisonLabel
      informationDefinition={definition.information.bic}
      detailedResult={detailedResult}
      entityName={
        entity.company?.name ??
        nameFormat(entity.individual?.first_name, entity.individual?.last_name)
      }
    />
  ),
  name_matching: ({ detailedResult, entity }) => (
    <DataComparisonLabel
      informationDefinition={definition.information.holder_name}
      detailedResult={detailedResult}
      entityName={
        entity.company?.name ??
        nameFormat(entity.individual?.first_name, entity.individual?.last_name)
      }
    />
  ),
};
