import { useEffect, useMemo } from 'react';
import {
  Button,
  VStack,
  Box,
  Checkbox as ChakraCheckbox,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStepId, useStore, useSubmitCustomStepForm } from 'frontend-common';

import { GroupController } from '../form';

type PdfViewerProps = {
  pdfUrl: string;
};

export const PdfViewer = (props: PdfViewerProps) => {
  const { pdfUrl } = props;

  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { metadata } = useStore();
  const stepId = useStepId();

  const fieldId = t(`steps.${stepId}.id`);

  const defaultValues = useMemo(() => {
    return {
      [fieldId]:
        metadata[fieldId] && typeof metadata[fieldId] == 'boolean'
          ? (metadata[fieldId] as boolean)
          : false,
    };
  }, [fieldId, metadata]);

  // @TODO - OPS-9 - Replace Yup by Zod
  const validationSchema = Yup.object({
    [fieldId]: Yup.boolean().oneOf([true], 'You must accept to continue'),
  });

  const methods = useForm({
    mode: 'all',
    criteriaMode: 'all',
    // @TODO - OPS-9 - Replace Yup by Zod
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [stepId, defaultValues, reset]);

  const onSubmit: SubmitHandler<any> = async (formData) => {
    submitCustomStepForm({ caseMetadata: formData });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        {pdfUrl && (
          <object
            data={pdfUrl}
            type="application/pdf"
            width="100%"
            height="500px"
          >
            <p>
              Unable to display PDF file. <a href={pdfUrl}>Download</a> instead.
            </p>
          </object>
        )}
        <GroupController
          name={fieldId}
          isRequired={true}
          control={control}
          render={(f) => {
            return (
              <ChakraCheckbox
                isChecked={f.value}
                onChange={(e) => {
                  setValue(fieldId, e.target.checked, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
              >
                {t(`steps.${stepId}.label`)}
              </ChakraCheckbox>
            );
          }}
        />

        <Box>
          <Button variant="next" isDisabled={!isValid} type="submit">
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
