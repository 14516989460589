/**
 * @deprecated
 */
// @TODO - E-3948 - OBF - Clean up AML / usage of is_internal
// Remove this
export enum AmlListTypeEnum {
  sanction = 'sanction',
  warning = 'warning',
  fitness_probity = 'fitness_probity',
  pep = 'pep',
  adverse_media = 'adverse_media',
}
