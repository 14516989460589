import { FunctionComponent, ReactElement } from 'react';
import { CustomField, FieldTypeEnum } from 'shared-domain';
import { ControlledInputProps } from '../type';
import { match } from 'ts-pattern';
import { ControlledSelect } from './controlled-select';
import { ControlledRadio } from './controlled-radio';
import { ControlledCheckbox } from './controlled-checkbox';
import { ControlledPhoneNumberInput } from './controlled-phone-number-input';
import { ControlledCountryInput } from './controlled-country-input';
import { ControlledTextInput, InputTextType } from './controlled-text-input';
import { ControlledTextArea } from './controlled-textarea';
import { ControlledAffiliatedSelect } from './controlled-affiliated-select';
import { ControlledNumberInput } from './controlled-number-input';

export const OTHER = 'other';

// TODO: TBD
// fallback props and fallbackName props should be remove
export const ControlledFields = ({
  field,
  stepId,
  Fallback,
}: {
  field: CustomField;
  stepId: string;
  Fallback: FunctionComponent<ControlledInputProps>;
}): ReactElement => {
  const props = { field, stepId };
  return match(field.type)
    .with(FieldTypeEnum.select, () => <ControlledSelect {...props} />)
    .with(FieldTypeEnum.number, () => <ControlledNumberInput {...props} />)
    .with(FieldTypeEnum.radio, () => <ControlledRadio {...props} />)
    .with(FieldTypeEnum.checkbox, () => <ControlledCheckbox {...props} />)
    .with(FieldTypeEnum.tel, () => <ControlledPhoneNumberInput {...props} />)
    .with(FieldTypeEnum.country, () => <ControlledCountryInput {...props} />)
    .with(FieldTypeEnum.url, () => (
      <ControlledTextInput {...props} type={InputTextType.url} />
    ))
    .with(FieldTypeEnum.textarea, () => <ControlledTextArea {...props} />)
    .with(FieldTypeEnum.iban, () => (
      <ControlledTextInput {...props} type={InputTextType.text} />
    ))
    .with(FieldTypeEnum.bic, () => (
      <ControlledTextInput {...props} type={InputTextType.text} />
    ))
    .with(FieldTypeEnum.affiliated, () => (
      <ControlledAffiliatedSelect {...props} />
    ))
    .otherwise(() => <Fallback {...props} />);
};
