import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'shared-common';
import {
  CheckResultEnum,
  DocumentAnalysisDetailedResultModel,
} from 'shared-domain';
import { DataComparisonNoLabel } from './data-comparison-no-data';

export function ValidAge({
  detailedResult,
  issuingDate,
  informationDefinition,
}: {
  detailedResult: DocumentAnalysisDetailedResultModel;
  issuingDate: string | null;
  informationDefinition: { label: string; translateKey: string };
}) {
  const { t, i18n } = useTranslation();

  const receivedDays =
    detailedResult.received !== null
      ? parseInt(detailedResult.received, 10)
      : null;
  const expectedDays =
    detailedResult.expected !== null
      ? parseInt(detailedResult.expected, 10)
      : null;

  if (
    detailedResult.result === CheckResultEnum.approved &&
    receivedDays !== null
  ) {
    return (
      <Text fontSize="sm" color="black">
        {t('steps.checks_list.document_analysis.age_validation_approved', {
          defaultValue: 'Document age is valid',
        })}
        :&nbsp;
        <Text fontSize="sm" color="brand.secondary" as="span">
          {t(`steps.checks_list.document_analysis.age_day_received`, {
            count: receivedDays,
            defaultValue: `(${receivedDays} day${
              receivedDays > 1 ? 's' : ''
            } old)`,
          })}
        </Text>
      </Text>
    );
  }

  if (
    detailedResult.result === CheckResultEnum.rejected &&
    receivedDays !== null &&
    expectedDays !== null &&
    issuingDate
  ) {
    return (
      <Text fontSize="sm" color="black">
        {t('steps.checks_list.document_analysis.age_validation_rejected', {
          defaultValue: 'Document is too old',
        })}
        :&nbsp;
        <Text fontSize="sm" color="brand.secondary" as="span">
          {formatDate(new Date(issuingDate), {
            locale: i18n.language,
          })}
        </Text>
        &nbsp;
        {t(`steps.checks_list.document_analysis.age_day_received`, {
          count: receivedDays,
          defaultValue: `(${receivedDays} day${
            receivedDays > 1 ? 's' : ''
          } old)`,
        })}
        &nbsp;-&nbsp;
        {t(`steps.checks_list.document_analysis.age_day_expected`, {
          count: expectedDays,
          defaultValue: `Expected to be under ${expectedDays} day${
            expectedDays > 1 ? 's' : ''
          }`,
        })}
      </Text>
    );
  }

  if (detailedResult.result === CheckResultEnum.error) {
    return (
      <Text fontSize="sm" color="black">
        {t('steps.checks_list.document_analysis.age_validation_error', {
          defaultValue: 'Document age not found',
        })}
      </Text>
    );
  }

  return (
    <DataComparisonNoLabel informationDefinition={informationDefinition} />
  );
}
