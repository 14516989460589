import { z } from 'zod';
import {
  AmlListTypeEnum,
  CheckTypeEnum,
  DocumentTypeEnum,
  IdVerificationDataModeEnum,
} from '../types';

export const checkSchema = z.array(
  z
    .object({
      company_id: z.string().uuid().nullish(),
      individual_id: z.string().uuid().nullish(),
      type: z.nativeEnum(CheckTypeEnum),
      settings: z
        .object({
          document_type: z.nativeEnum(DocumentTypeEnum),
          custom_document_type_id: z.string().uuid(),
          // @TODO - E-3948 - OBF - Clean up AML / usage of is_internal
          // Remove fuzziness, list_types, enable_entity_filter
          fuzziness: z.number().min(0).max(1),
          list_types: z.array(z.nativeEnum(AmlListTypeEnum)),
          enable_entity_filter: z.boolean(),
          manual_review: z.boolean(),
          mode: z.nativeEnum(IdVerificationDataModeEnum),
          redirect_url: z.string().url(),
          document_analysis: z.object({}),
        })
        .partial()
        .optional(),
    })
    .superRefine((values, context) => {
      if (
        values['company_id'] &&
        [CheckTypeEnum.id_verification, CheckTypeEnum.id_document].includes(
          values['type'],
        )
      ) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Check not available for a company',
          path: ['company_id'],
        });
      }
      if (values['company_id'] && values['individual_id']) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Only one entity can be set',
          path: ['individual_id', 'company_id'],
        });
      }
      if (!values['individual_id'] && !values['company_id']) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Entity is required',
          path: ['individual_id', 'company_id'],
        });
      }
      if (values['type'] === CheckTypeEnum.document && !values['settings']) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Settings is required',
          path: ['type'],
        });
      }
      if (
        values['type'] === CheckTypeEnum.document &&
        values['settings'] &&
        !values['settings']['custom_document_type_id'] &&
        !values['settings']['document_type']
      ) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            'document_type or custom_document_type_id is required for a document check',
          path: ['type', 'settings'],
        });
      }
    }),
);
