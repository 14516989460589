import { useEffect } from 'react';
import { Heading, Text, AbsoluteCenter, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'frontend-common';
import { LoadingSpinner } from '../feedback';

type RedirectPageProps = {
  redirectUrl: string;
};

export const RedirectPage = (props: RedirectPageProps) => {
  const { redirectUrl } = props;

  const { t } = useTranslation();
  const { updateShowHeader } = useStore();

  useEffect(() => {
    setTimeout(() => {
      window.location.replace(redirectUrl);
    }, 5000);
  }, []);

  useEffect(() => {
    updateShowHeader(false);
  }, []);

  return (
    <Box position="relative" h="80vh">
      <AbsoluteCenter w="100%">
        <Heading mb={8} size="lg" textAlign="center">
          {t('steps.checks_list.redirect_page.title')}
        </Heading>
        <Text mb={2} textAlign="center">
          {t('steps.checks_list.redirect_page.information')}
        </Text>
        <Text textAlign="center">
          {t('steps.checks_list.redirect_page.waiting')}{' '}
          <a href={redirectUrl}>{t('steps.checks_list.redirect_page.link')}</a>
        </Text>
        <LoadingSpinner />
      </AbsoluteCenter>
    </Box>
  );
};
