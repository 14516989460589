import { ReactElement } from 'react';

export const DotfileLogo = ({ color }: { color: string }): ReactElement => {
  return (
    <svg
      width="94"
      height="24"
      viewBox="0 0 94 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.8741 8.67218C43.3153 8.67218 40.7581 11.261 40.7581 14.6575C40.7581 18.054 43.3153 20.6662 46.8741 20.6662C50.4328 20.6662 52.9924 18.0517 52.9924 14.6575C52.9924 11.2633 50.4328 8.67218 46.8741 8.67218ZM46.8741 17.9536C45.0947 17.9536 43.8044 16.6114 43.8044 14.6575C43.8044 12.7036 45.0947 11.359 46.8741 11.359C48.6534 11.359 49.9461 12.727 49.9461 14.6575C49.9461 16.588 48.6534 17.9536 46.8741 17.9536Z"
        fill={color}
      />
      <path
        d="M57.8577 15.2434V11.4571H61.2954V8.9173H57.8577V5.22665H54.7625V15.5609C54.7625 18.9808 56.8097 20.5938 59.6371 20.5938C60.3428 20.5938 60.8063 20.5215 61.3676 20.3977V17.8323C60.953 17.8556 60.8319 17.8556 60.5384 17.8556C58.4423 17.8556 57.8577 17.0993 57.8577 15.2411V15.2434Z"
        fill={color}
      />
      <path
        d="M71.3894 8.91496H66.4239V8.15862C66.4239 6.86304 67.083 6.17907 68.4967 6.17907C68.7157 6.17907 68.9346 6.20242 69.2024 6.22809V3.76066C68.6178 3.63928 68.0822 3.56458 67.5698 3.56458C65.1313 3.56458 63.3542 5.07958 63.3542 8.25433V20.3954H66.4262V11.3824H71.3917V20.3977H74.4637V8.91496H71.3917H71.3894Z"
        fill={color}
      />
      <path
        d="M80.1301 3.73734H77.0582V20.3978H80.1301V3.73734Z"
        fill={color}
      />
      <path
        d="M93.9832 14.6575C93.9832 11.2353 91.5703 8.67218 88.2305 8.67218C84.8907 8.67218 82.3567 11.212 82.3567 14.6575C82.3567 18.103 84.7463 20.6662 88.305 20.6662C90.6201 20.6662 92.9351 19.4687 93.6431 16.9289H90.7901C90.3266 17.7342 89.572 18.1007 88.2305 18.1007C86.7446 18.1007 85.6476 17.2697 85.2819 15.708H93.9343C93.9832 15.2668 93.9832 15.0964 93.9832 14.6575ZM85.2587 13.558C85.5987 12.1644 86.6957 11.2353 88.2305 11.2353C89.7653 11.2353 90.8879 12.2134 91.0835 13.558H85.2587Z"
        fill={color}
      />
      <path
        d="M72.9218 3.50156C71.9669 3.50156 71.1844 4.2649 71.1844 5.20098C71.1844 6.13706 71.9669 6.87706 72.9218 6.87706C73.8767 6.87706 74.6383 6.13706 74.6383 5.20098C74.6383 4.2649 73.8558 3.50156 72.9218 3.50156V3.50156Z"
        fill={color}
      />
      <path
        d="M35.9602 9.4192C35.0752 8.94065 34.0318 8.6722 32.8859 8.6722C29.3272 8.6722 26.7676 11.261 26.7676 14.6575C26.7676 18.054 29.3272 20.6662 32.8859 20.6662C36.4447 20.6662 38.8622 18.1918 38.9949 14.933C38.9996 14.8419 39.0019 14.7509 39.0019 14.6575V3.742H35.9602V9.4192ZM32.8859 17.9537C31.1065 17.9537 29.8139 16.6114 29.8139 14.6575C29.8139 12.7037 31.1065 11.3591 32.8859 11.3591C34.6653 11.3591 35.9556 12.727 35.9556 14.6575C35.9556 16.5881 34.6653 17.9537 32.8859 17.9537Z"
        fill={color}
      />
      <path
        d="M5.98625 6V18C2.68007 18 0 15.3138 0 12C0 8.68623 2.68007 6 5.98625 6Z"
        fill={color}
      />
      <path
        d="M17.9588 12C17.9588 15.183 16.6977 18.2351 14.4524 20.4856C12.2296 22.7134 9.22277 23.9754 6.08146 24C6.02945 24.001 5.98627 23.9577 5.98627 23.9046V18C9.29244 18 11.9725 15.3138 11.9725 12C11.9725 8.68623 9.29244 6 5.98627 6V0.0963934C5.98627 0.0432787 6.02945 0 6.08146 0C9.22277 0.0255738 12.2296 1.28656 14.4524 3.51443C16.6977 5.76492 17.9588 8.81803 17.9588 12Z"
        fill={color}
      />
    </svg>
  );
};
