import { Text, Box, Button, Icon } from '@chakra-ui/react';
import { ArrowRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';

type TabButtonProps = {
  data?: {
    label: string;
    tabIndex: number;
  };

  onClick: (tabIndex: number) => void;
};

export const TabButton = (props: TabButtonProps) => {
  const { data, onClick } = props;

  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  return (
    <Box mt="6">
      <Text color="gray.600">{t('steps.checks_list.tab_button.content')}</Text>
      <Button
        onClick={() => onClick(data.tabIndex)}
        variant="link"
        color="black"
        pt="1"
        lineHeight="6"
        rightIcon={<Icon size="16" as={ArrowRight} />}
      >
        {data.label}
      </Button>
    </Box>
  );
};
