import { FunctionComponent } from 'react';
import { GroupController, Radio } from '../../form';
import { ControlledInputProps } from '../type';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatedFieldName } from 'frontend-common';
import { FieldPropertyTypeEnum } from 'shared-domain';

export const ControlledRadio: FunctionComponent<ControlledInputProps> = ({
  stepId,
  field,
}) => {
  const { setValue, control } = useFormContext();
  const { t } = useTranslation();

  const fieldName = generatedFieldName(field);

  return (
    <GroupController
      key={generatedFieldName(field)}
      name={generatedFieldName(field)}
      label={t(`steps.${stepId}.${fieldName}.label`) || fieldName}
      helper={field.hasHelper ? t(`steps.${stepId}.${fieldName}.helper`) : null}
      isRequired={field.isRequired}
      control={control}
      render={(f) => (
        <Radio
          stepId={stepId}
          name={fieldName}
          onChange={(value: string) => {
            setValue(
              fieldName,
              field.propertyType === FieldPropertyTypeEnum.custom &&
                !['true', 'false'].includes(value)
                ? [value]
                : value ?? '',
              {
                shouldDirty: true,
                shouldValidate: true,
              },
            );
          }}
          options={field.options || []}
          defaultValue={f.value}
        />
      )}
    />
  );
};
