import { format } from 'date-fns';
import { CompanyInput, CustomProperties, Metadata } from 'shared-domain';
import { useStore } from './use-store';
import { useSubmit } from './use-submit';

type SubmitCompanyForm = {
  companyData: CompanyInput;
  caseMetadata?: Metadata;
  caseCustomProperties?: CustomProperties;
};

export const useSubmitCompanyForm = (): {
  submitCompanyForm: (input: SubmitCompanyForm) => void;
} => {
  const submitStep = useSubmit();
  const {
    company,
    metadata,
    customProperties,
    updateCompany,
    updateMetadata,
    updateCustomProperties,
  } = useStore();

  const submitCompanyForm = (input: SubmitCompanyForm) => {
    // Save company data
    const { registration_date, ...companyData } = input.companyData;

    const custom_properties = {
      ...company?.custom_properties,
      ...input.companyData.custom_properties,
    };

    if (registration_date) {
      const registrationDateFormatted = format(
        Date.parse(registration_date),
        'yyyy-MM-dd',
      );
      updateCompany({
        ...company,
        ...companyData,
        registration_date: registrationDateFormatted,
        custom_properties,
      });
    } else {
      updateCompany({
        ...company,
        ...input.companyData,
        custom_properties,
      });
    }

    // Save case metadata
    if (input.caseMetadata) {
      updateMetadata({ ...metadata, ...input.caseMetadata });
    }

    // Save case custom properties
    if (input.caseCustomProperties) {
      updateCustomProperties({
        ...customProperties,
        ...input.caseCustomProperties,
      });
    }

    // Submit step
    submitStep();
  };

  return { submitCompanyForm };
};
