import i18next from 'i18next';
import { upperFirst } from 'lodash';
import { initReactI18next } from 'react-i18next';
import merge from 'deepmerge';
import {
  baseDe,
  baseEn,
  baseEs,
  baseFr,
  baseIt,
  baseNl,
  Locales,
} from 'shared-domain';

import { languageDetector } from './language-detector';

export const configurationI18n = ({
  debug,
  lng,
  translations,
  languages,
}: {
  debug: boolean;
  lng: string | undefined;
  translations: Locales;
  languages: string[];
}): void => {
  if (!i18next.isInitialized) {
    const resources = {
      ...(languages.includes('en') && {
        en: { translation: merge(baseEn, translations['en']) },
      }),
      ...(languages.includes('fr') && {
        fr: { translation: merge(baseFr, translations['fr']) },
      }),
      ...(languages.includes('de') && {
        de: { translation: merge(baseDe, translations['de']) },
      }),
      ...(languages.includes('es') && {
        es: { translation: merge(baseEs, translations['es']) },
      }),
      ...(languages.includes('it') && {
        it: { translation: merge(baseIt, translations['it']) },
      }),
      ...(languages.includes('nl') && {
        nl: { translation: merge(baseNl, translations['nl']) },
      }),
    };

    i18next
      .use(languageDetector)
      .use(initReactI18next)
      .init({
        debug,
        lng,
        resources,
        fallbackLng: languages.includes('en') ? 'en' : languages[0],
        react: {
          transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'a'],
        },
        interpolation: {
          escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
      });

    i18next.services.formatter?.add('upperFirst', (value, lng, options) => {
      return upperFirst(value);
    });
  }
};
