export enum CheckTypeEnum {
  id_verification = 'id_verification',
  id_document = 'id_document',
  /**
   * @deprecated
   */
  // @TODO - E-3948 - OBF - Clean up AML / usage of is_internal
  // Remove this value since aml are always internal and not used in the OBF
  aml = 'aml',
  document = 'document',
}
