import * as Yup from 'yup';
import { CustomField } from '../types';
import {
  FieldEntityTypeEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
} from '../types';
import { validateIBAN, validateBIC } from 'ibantools';
import { CUSTOM_PROPERTY_TEXT_VALUE_MAX_LENGTH } from '../constants';

Yup.addMethod(Yup.string, 'iban', function (errorMessage) {
  return this.test(`test-iban`, errorMessage, function (value) {
    const { path, createError } = this;

    if (!value) return true;

    const { valid } = validateIBAN((value || '').replace(/\s/g, ''));

    return valid || createError({ path, message: errorMessage });
  });
});

Yup.addMethod(Yup.string, 'bic', function (errorMessage) {
  return this.test(`test-bic`, errorMessage, function (value) {
    const { path, createError } = this;

    if (!value) return true;

    const { valid } = validateBIC((value || '').replace(/\s/g, ''));

    return valid || createError({ path, message: errorMessage });
  });
});

// Extend customFields with validation based on type
export const customFieldsExtendValidation = (
  fields: CustomField[],
  t: any,
  stepId?: string,
) => {
  const validationFields: any[] = [];

  fields.forEach((field: CustomField) => {
    let id = field.id;
    if (field.nested) {
      if (field.nested === 'classifications') {
        id = `${field.nested}.0.${field.id}`;
      } else {
        id = `${field.nested}.${field.id}`;
      }
    }
    if (
      field.propertyType === FieldPropertyTypeEnum.custom &&
      (!field.entityType ||
        field.entityType === FieldEntityTypeEnum.company ||
        field.entityType === FieldEntityTypeEnum.individual)
    ) {
      id = `custom_properties.${field.id}`;
    }

    const f = {
      id,
      label: t(`steps.${stepId}.${id}.label`),
      type: field.type,
      required: field.isRequired,
    };

    const requiredFieldValidation = field.isRequired
      ? [
          {
            type: 'required',
            params: [],
          },
        ]
      : [
          {
            type: 'optional',
            params: [],
          },
          {
            type: 'nullable',
            params: [],
          },
          {
            type: 'default',
            params: [null],
          },
          {
            type: 'transform',
            params: [(v: any) => (v === '' ? null : v)],
          },
        ];

    switch (field.type) {
      case FieldTypeEnum.email:
        validationFields.push({
          ...f,
          validationType: 'string',
          validations: [
            ...requiredFieldValidation,
            {
              type: 'email',
              params: [],
            },
          ],
        });
        break;
      case FieldTypeEnum.url:
        validationFields.push({
          ...f,
          validationType: 'string',
          validations: [
            ...requiredFieldValidation,
            {
              type: 'url',
              params: [
                'Website must be a valid URL with protocol (http or https)',
              ],
            },
            {
              type: 'max',
              params: [CUSTOM_PROPERTY_TEXT_VALUE_MAX_LENGTH],
            },
          ],
        });
        break;
      case FieldTypeEnum.number:
        validationFields.push({
          ...f,
          validationType: 'number',
          validations: [
            ...requiredFieldValidation,
            {
              type: 'transform',
              params: [(v: any) => (isNaN(v) || v === '' ? null : v)],
            },
          ],
        });
        break;
      case FieldTypeEnum.tel:
        validationFields.push({
          ...f,
          validationType: 'string',
          validations: [
            ...requiredFieldValidation,
            {
              type: 'matches',
              params: [
                /^\+(?:[0-9] ?){6,14}[0-9]$/,
                'Phone number should follow international standard format',
              ],
            },
          ],
        });
        break;
      case FieldTypeEnum.radio:
        if (
          field.propertyType === FieldPropertyTypeEnum.custom &&
          !field.options?.includes('true') &&
          !field.options?.includes('false')
        ) {
          validationFields.push({
            ...f,
            validationType: 'array',
            validations: field.isRequired
              ? [
                  {
                    type: 'default',
                    params: [null],
                  },
                  {
                    type: 'transform',
                    params: [
                      (v: any) => (v === null || v?.length === 0 ? [] : v),
                    ],
                  },
                  {
                    type: 'min',
                    params: [1],
                  },
                ]
              : [
                  {
                    type: 'optional',
                    params: [],
                  },
                  {
                    type: 'nullable',
                    params: [],
                  },
                  {
                    type: 'default',
                    params: [null],
                  },
                  {
                    type: 'transform',
                    params: [
                      (v: any) => (v === null || v?.length === 0 ? [] : v),
                    ],
                  },
                ],
          });
          break;
        } else {
          validationFields.push({
            ...f,
            validationType: 'mixed',
            validations: [
              ...requiredFieldValidation,
              {
                type: 'oneOf',
                params: [field.options],
              },
            ],
          });
        }
        break;
      case FieldTypeEnum.checkbox:
        validationFields.push({
          ...f,
          validationType: 'array',
          validations: field.isRequired
            ? [
                {
                  type: 'default',
                  params: [null],
                },
                {
                  type: 'transform',
                  params: [(v: any) => (v === '' ? null : v)],
                },
                {
                  type: 'min',
                  params: [1],
                },
              ]
            : [
                {
                  type: 'optional',
                  params: [],
                },
                {
                  type: 'nullable',
                  params: [],
                },
                {
                  type: 'default',
                  params: [null],
                },
                {
                  type: 'transform',
                  params: [(v: any) => (v === '' ? null : v)],
                },
              ],
        });
        if (field.hasOtherOption) {
          validationFields.push({
            id: `${field.id}_other`,
            label: t(`steps.${stepId}.${field.id}.other_option.label`),
            type: 'text',
            required: true,
            validationType: 'string',
            validations: [
              {
                type: 'when',
                params: [
                  field.id,
                  (val: string[], schema: any) => {
                    return val[0]?.includes('other')
                      ? schema.required()
                      : schema
                          .optional()
                          .nullable()
                          .default(null)
                          .transform(() => null);
                  },
                ],
              },
            ],
          });
        }
        break;
      case FieldTypeEnum.country:
        if (
          field.isMulti ||
          field.propertyType === FieldPropertyTypeEnum.custom
        ) {
          validationFields.push({
            ...f,
            validationType: 'array',
            validations: field.isRequired
              ? [
                  {
                    type: 'default',
                    params: [null],
                  },
                  {
                    type: 'transform',
                    params: [
                      (v: any) => (v === null || v?.length === 0 ? [] : v),
                    ],
                  },
                  {
                    type: 'min',
                    params: [1],
                  },
                ]
              : [
                  {
                    type: 'optional',
                    params: [],
                  },
                  {
                    type: 'nullable',
                    params: [],
                  },
                  {
                    type: 'default',
                    params: [null],
                  },
                  {
                    type: 'transform',
                    params: [
                      (v: any) => (v === null || v?.length === 0 ? [] : v),
                    ],
                  },
                ],
          });
          break;
        }
        validationFields.push({
          ...f,
          validationType: 'string',
          validations: [...requiredFieldValidation],
        });
        break;
      case FieldTypeEnum.select:
        if (field.hasOtherOption) {
          validationFields.push({
            id: `${field.id}_other`,
            label: t(`steps.${stepId}.${field.id}.other_option.label`),
            type: 'text',
            required: true,
            validationType: 'string',
            validations: [
              {
                type: 'when',
                params: [
                  field.id,
                  (val: string[], schema: any) => {
                    return val[0]?.includes('other')
                      ? schema.required()
                      : schema
                          .optional()
                          .nullable()
                          .default(null)
                          .transform(() => null);
                  },
                ],
              },
            ],
          });
        }
        if (
          field.isMulti ||
          field.propertyType === FieldPropertyTypeEnum.custom
        ) {
          validationFields.push({
            ...f,
            validationType: 'array',
            validations: field.isRequired
              ? [
                  {
                    type: 'default',
                    params: [null],
                  },
                  {
                    type: 'transform',
                    params: [
                      (v: any) => (v === null || v?.length === 0 ? [] : v),
                    ],
                  },
                  {
                    type: 'min',
                    params: [1],
                  },
                ]
              : [
                  {
                    type: 'optional',
                    params: [],
                  },
                  {
                    type: 'nullable',
                    params: [],
                  },
                  {
                    type: 'default',
                    params: [null],
                  },
                  {
                    type: 'transform',
                    params: [
                      (v: any) => (v === null || v?.length === 0 ? [] : v),
                    ],
                  },
                ],
          });
          break;
        }
        validationFields.push({
          ...f,
          validationType: 'mixed',
          validations: [
            ...requiredFieldValidation,
            {
              type: 'oneOf',
              params: [
                field.hasOtherOption && field.options
                  ? [...field.options, 'other']
                  : field.options,
              ],
            },
          ],
        });
        break;
      case FieldTypeEnum.iban: {
        validationFields.push({
          ...f,
          validationType: 'string',
          validations: [
            ...(field.isRequired
              ? [
                  {
                    type: 'required',
                    params: ['IBAN is a required field'],
                  },
                ]
              : [
                  {
                    type: 'optional',
                    params: [],
                  },
                  {
                    type: 'nullable',
                    params: [],
                  },
                  {
                    type: 'default',
                    params: [null],
                  },
                  {
                    type: 'transform',
                    params: [(v: any) => (v === '' ? null : v)],
                  },
                ]),
            {
              type: 'iban',
              params: ['IBAN is invalid'],
            },
          ],
        });
        break;
      }
      case FieldTypeEnum.bic: {
        validationFields.push({
          ...f,
          validationType: 'string',
          validations: [
            ...(field.isRequired
              ? [
                  {
                    type: 'required',
                    params: ['BIC is a required field'],
                  },
                ]
              : [
                  {
                    type: 'optional',
                    params: [],
                  },
                  {
                    type: 'nullable',
                    params: [],
                  },
                  {
                    type: 'default',
                    params: [null],
                  },
                  {
                    type: 'transform',
                    params: [(v: any) => (v === '' ? null : v)],
                  },
                ]),
            {
              type: 'bic',
              params: ['BIC is invalid'],
            },
          ],
        });
        break;
      }
      default:
        validationFields.push({
          ...f,
          validationType: 'string',
          validations: [
            ...requiredFieldValidation,
            {
              type: 'max',
              params: [CUSTOM_PROPERTY_TEXT_VALUE_MAX_LENGTH],
            },
          ],
        });
    }
  });

  return validationFields;
};
