import { merge } from 'lodash';
import { useState, useEffect } from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  Heading,
  HStack,
  Link,
  Show,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ExternalLinkIcon } from 'lucide-react';
import {
  useConfigContext,
  useDetailedCase,
  useHasActions,
  useStore,
} from 'frontend-common';
import type { Check, Company, CompanyInput, Individual } from 'shared-domain';
import {
  CaseFlagEnum,
  CheckTypeEnum,
  resolveRedirectUrlHelper,
} from 'shared-domain';

import { NotFound } from '../error';
import { ModalDocument } from '../modal';
import { TabButton } from '../disclosure';
import { Indicator, LoadingSpinner } from '../feedback';
import {
  CopyableText,
  EmptyState,
  UnderReview,
  RedirectPage,
} from '../data-display';
import { CheckCard } from './check-card';

export const ChecksList = () => {
  const { t, i18n } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const config = useConfigContext();
  const { fetchCase, error, loading: isLoading } = useDetailedCase();
  const [isMobile] = useMediaQuery('(max-width: 48em)');
  const {
    caseId,
    childrenCases,
    externalId,
    company,
    individuals,
    affiliatedCompanies,
    flags,
  } = useStore();

  const [currentCheck, setCurrentCheck] = useState<Check | null>(null);
  const [currentEntity, setCurrentEntity] = useState<
    Company | Individual | null
  >(null);

  const [tabIndex, setTabIndex] = useState<number>(() => {
    const storedIndex = sessionStorage.getItem('tabIndex');
    return storedIndex ? parseInt(storedIndex, 10) : 0;
  });

  useEffect(() => {
    sessionStorage.setItem('tabIndex', String(tabIndex));
  }, [tabIndex]);

  const {
    hasCompaniesActions,
    hasIndividualsActions,
    hasAffiliatedCompaniesActions,
  } = useHasActions();

  const selectCheck = (check: Check) => {
    setCurrentCheck(check);

    if (check.type !== CheckTypeEnum.id_verification) {
      onClose();
    }
  };

  const hasAffiliatedCompanies = affiliatedCompanies.some(
    (x) => (x.checks?.length ?? 0) > 0,
  );

  const hasChildrenCases = childrenCases && childrenCases.length > 0;

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const hasIndividuals = individuals.some((x) => (x.checks?.length ?? 0) > 0);

  const hasCompany = (company && company.checks?.length) ?? 0;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <NotFound />;
  }

  if (
    !hasCompany &&
    !hasChildrenCases &&
    !hasAffiliatedCompanies &&
    !hasIndividuals
  ) {
    return <EmptyState />;
  }

  if (
    caseId &&
    affiliatedCompanies &&
    !hasCompaniesActions &&
    !childrenCases &&
    !hasIndividualsActions &&
    !hasAffiliatedCompaniesActions &&
    flags.includes(CaseFlagEnum.for_review)
  ) {
    const redirectUrl = resolveRedirectUrlHelper(
      config.redirectUrl,
      caseId,
      externalId,
    );

    if (redirectUrl) {
      return <RedirectPage redirectUrl={redirectUrl} />;
    } else {
      return <UnderReview />;
    }
  }

  const tabData = (entityType: 'company' | 'legal-entity' | 'individual') => {
    switch (entityType) {
      case 'company':
        if (hasAffiliatedCompaniesActions) {
          return {
            label: t('steps.checks_list.tab_button.cta_legal_entity'),
            tabIndex: 1,
          };
        } else if (hasIndividualsActions) {
          return {
            label: t('steps.checks_list.tab_button.cta_individual'),
            tabIndex: hasAffiliatedCompanies ? 2 : 1,
          };
        }
        break;
      case 'legal-entity':
        if (hasAffiliatedCompanies) {
          return {
            label: t('steps.checks_list.tab_button.cta_individual'),
            tabIndex: 2,
          };
        } else if (hasCompaniesActions)
          return {
            label: t('steps.checks_list.tab_button.cta_company'),
            tabIndex: 0,
          };
        break;
      case 'individual':
        if (hasCompaniesActions) {
          return {
            label: t('steps.checks_list.tab_button.cta_company'),
            tabIndex: 0,
          };
        } else if (hasAffiliatedCompaniesActions) {
          return {
            label: t('steps.checks_list.tab_button.cta_legal_entity'),
            tabIndex: 1,
          };
        }
        break;
      default:
        return undefined;
    }
    return undefined;
  };

  return (
    <Stack spacing={5} pt={2}>
      <Tabs
        mt="2"
        pb="8"
        // isLazy is necessary for accordion state, otherwise the accordion of the other tabs mount but
        // is not visible and defaultOpen state is lost
        // @see https://chakra-ui.com/docs/components/tabs/usage#lazily-mounting-tab-panels
        isLazy
        isFitted={isMobile}
        index={tabIndex}
        onChange={handleTabsChange}
      >
        <HStack justifyContent="space-between">
          {config.hasKyb && (
            <TabList
              position="sticky"
              top="0"
              zIndex="1"
              display={{ base: 'flex', md: 'inline-flex' }}
              w={{ base: '100%', md: 'auto' }}
            >
              {company && (company.checks?.length ?? 0) > 0 && (
                <Tab color="gray.500">
                  {t('companies')}
                  {company && (
                    <Indicator ml="2" boxSize="8px" color="orange.500" />
                  )}
                </Tab>
              )}
              {hasAffiliatedCompanies && (
                <Tab color="gray.500">
                  {t('affiliated_companies')}
                  {hasAffiliatedCompaniesActions && (
                    <Indicator ml="2" boxSize="8px" color="orange.500" />
                  )}
                </Tab>
              )}
              {hasChildrenCases && (
                <Tab color="gray.500">{t('children_cases')}</Tab>
              )}
              {hasIndividuals && (
                <Tab color="gray.500">
                  {t('individuals')}
                  {hasIndividualsActions && (
                    <Indicator ml="2" boxSize="8px" color="orange.500" />
                  )}
                </Tab>
              )}
            </TabList>
          )}
          <Show above="md">
            <CopyableText
              label={t('steps.checks_list.copy.label')}
              value={
                externalId
                  ? `${window.location.origin}?caseId=${caseId}&externalId=${externalId}`
                  : `${window.location.origin}?caseId=${caseId}`
              }
            />
          </Show>
        </HStack>

        <TabPanels>
          {company && (company.checks?.length ?? 0) > 0 && (
            <TabPanel p={{ base: '0', md: '4' }}>
              <Stack spacing={5} pt={2}>
                <CheckCard
                  entity={company}
                  entityType="company"
                  checks={company.checks ?? []}
                  currentCheck={currentCheck}
                  selectCheck={selectCheck}
                  setCurrentEntity={setCurrentEntity}
                  onOpen={onOpen}
                  fetchMyAPI={() => fetchCase(caseId)}
                />
              </Stack>

              <TabButton onClick={handleTabsChange} data={tabData('company')} />
            </TabPanel>
          )}
          {hasAffiliatedCompanies && (
            <TabPanel p={{ base: '0', md: '4' }}>
              <Stack spacing={5} pt={2}>
                {affiliatedCompanies.map(
                  (company: CompanyInput & { id: string }) => (
                    <CheckCard
                      key={company.id}
                      entity={company}
                      entityType="company"
                      checks={company.checks ?? []}
                      currentCheck={currentCheck}
                      selectCheck={selectCheck}
                      setCurrentEntity={setCurrentEntity}
                      onOpen={onOpen}
                      fetchMyAPI={() => fetchCase(caseId)}
                    />
                  ),
                )}
              </Stack>

              <TabButton
                onClick={handleTabsChange}
                data={tabData('legal-entity')}
              />
            </TabPanel>
          )}
          {hasChildrenCases && (
            <TabPanel p={{ base: '0', md: '4' }}>
              <Stack spacing={5} pt={2}>
                {childrenCases.map((child: { id: string; name: string }) => (
                  <Box
                    borderWidth="1px"
                    borderRadius="lg"
                    background="white"
                    boxShadow="1px 1px 16px rgba(153, 153, 153, 0.1)"
                    padding={5}
                    key={child.id}
                  >
                    <Flex
                      direction={{ base: 'column', sm: 'row' }}
                      alignItems={{ base: 'start', sm: 'center' }}
                    >
                      <Box>
                        <Heading size="sm" textAlign="left">
                          {child.name}
                        </Heading>
                      </Box>
                      <Spacer />
                      <Box mt={{ base: '12px', md: '0' }}>
                        <Link
                          href={`${window.location.origin}?caseId=${child.id}`}
                          isExternal
                          variant="link"
                          size="sl"
                          target="_blank"
                        >
                          {t('steps.checks_list.proceed_to_verification')}
                          <ExternalLinkIcon
                            size={16}
                            style={{
                              width: '1em',
                              height: '1em',
                              display: 'inline-block',
                              lineHeight: '1em',
                              flexShrink: '0',
                              verticalAlign: 'middle',
                              marginInline: '4px',
                            }}
                          />
                        </Link>
                      </Box>
                    </Flex>
                  </Box>
                ))}
              </Stack>
            </TabPanel>
          )}
          {hasIndividuals && (
            <TabPanel p={{ base: '0', md: '4' }}>
              <Stack spacing={5} pt={2}>
                {individuals.map((individual) => (
                  <CheckCard
                    key={individual.id}
                    entity={individual as Individual}
                    entityType="individual"
                    checks={(individual as Individual).checks ?? []}
                    currentCheck={currentCheck}
                    selectCheck={selectCheck}
                    setCurrentEntity={setCurrentEntity}
                    onOpen={onOpen}
                    fetchMyAPI={() => fetchCase(caseId)}
                  />
                ))}
              </Stack>

              <TabButton
                onClick={handleTabsChange}
                data={tabData('individual')}
              />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>

      {i18n.exists(`steps.checks_list.callout`) && (
        <Alert status="info" mb="6">
          <AlertIcon />
          <Text
            dangerouslySetInnerHTML={{
              __html: t(`steps.checks_list.callout`) ?? '',
            }}
          ></Text>
        </Alert>
      )}

      {isOpen && currentCheck && currentEntity && (
        <ModalDocument
          currentEntity={currentEntity}
          currentCheck={currentCheck}
          fetchMyAPI={() => fetchCase(caseId)}
          onDocumentAnalysisUpdate={(updatedCheck: Check) => {
            setCurrentCheck((currentCheck) =>
              merge(currentCheck, updatedCheck),
            );
          }}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </Stack>
  );
};
