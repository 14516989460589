import {
  CustomField,
  CustomProperties,
  FieldEntityTypeEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
} from 'shared-domain';

export const defaultCaseCustomPropertiesValues = (
  fields: CustomField[],
  customProperties: CustomProperties,
): CustomField[] => {
  return fields
    .filter(
      (f: CustomField) =>
        f.propertyType === FieldPropertyTypeEnum.custom &&
        f.entityType === FieldEntityTypeEnum.case,
    )
    .reduce((acc: any, cur: CustomField) => {
      const defaultValue =
        cur.type &&
        [FieldTypeEnum.select, FieldTypeEnum.country].includes(cur.type)
          ? []
          : '';
      let valueCP = customProperties[cur.id];

      // Convert boolean (yes/no custom props) to string to be set as form value
      if (typeof valueCP === 'boolean') {
        valueCP = valueCP.toString();
      }

      return {
        ...acc,
        [cur.id]: valueCP || defaultValue,
      };
    }, {});
};
