import { z } from 'zod';

export const appConfigSchema = z.object({
  websiteTitle: z.string(),
  showPoweredLogo: z.boolean(),
  hasKyb: z.boolean(),
  queryParamsRequired: z.array(
    z.enum([
      'email',
      'company',
      'country',
      'firstName',
      'lastName',
      'registrationNumber',
      'externalId',
      'lng',
      'templateId',
      'caseId',
    ]),
  ),
  supportLink: z.string(),
  redirectUrl: z.string().nullable(),
  languages: z.array(z.enum(['fr', 'en', 'de', 'es', 'it', 'nl'])).min(1),
  countries: z.array(
    z.object({
      code: z.string(),
      iso3: z.string(),
      name: z.string(),
      localName: z.string(),
      continent: z.string(),
      emoji: z.string(),
      emojiCode: z.string(),
    }),
  ),
  companyFields: z.array(
    z.object({
      id: z.string(),
      isRequired: z.boolean(),
      isEnabled: z.boolean(),
      hasHelper: z.boolean().optional(),
      hasPlaceholder: z.boolean().optional(),
      nested: z
        .enum(['address', 'banking_information', 'classifications'])
        .optional(),
      options: z.array(z.string()).optional(),
      hasOtherOption: z.boolean().optional(),
      isMulti: z.boolean().optional(),
      addons: z
        .object({
          left: z.string().optional(),
          right: z.string().optional(),
        })
        .optional(),
      type: z
        .enum([
          'radio',
          'select',
          'checkbox',
          'date',
          'url',
          'country',
          'text',
          'textarea',
          'number',
          'tel',
          'email',
          'affiliated',
        ])
        .optional(),
    }),
  ),
  affiliatedCompanyFields: z.array(
    z.object({
      id: z.string(),
      isRequired: z.boolean(),
      isEnabled: z.boolean(),
      hasHelper: z.boolean().optional(),
      hasPlaceholder: z.boolean().optional(),
      nested: z
        .enum(['address', 'banking_information', 'classifications'])
        .optional(),
      options: z.array(z.string()).optional(),
      type: z
        .enum(['country', 'text', 'number', 'checkbox', 'date'])
        .optional(),
    }),
  ),
  individualFields: z.array(
    z.object({
      id: z.string(),
      isRequired: z.boolean(),
      isEnabled: z.boolean(),
      hasHelper: z.boolean().optional(),
      hasPlaceholder: z.boolean().optional(),
      nested: z
        .enum(['address', 'banking_information', 'classifications'])
        .optional(),
      options: z.array(z.string()).optional(),
      hasOtherOption: z.boolean().optional(),
      isMulti: z.boolean().optional(),
      addons: z
        .object({
          left: z.string().optional(),
          right: z.string().optional(),
        })
        .optional(),
      type: z
        .enum([
          'radio',
          'select',
          'checkbox',
          'date',
          'url',
          'country',
          'text',
          'textarea',
          'number',
          'tel',
          'email',
          'affiliated',
        ])
        .optional(),
    }),
  ),
});
