import LogoSVG from '../assets/logo.svg';

export const Logo = () => {
  return (
    <LogoSVG
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      style={{
        maxWidth: '100%',
        maxHeight: '100%',
        margin: 'auto',
      }}
    />
  );
};
