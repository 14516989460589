import { BaseStepIdEnum, CustomField } from 'shared-domain';
import { useSearchParams } from 'react-router-dom';
import { useConfigContext } from '../contexts';
import { useStore } from './use-store';

type UseNavigationReturn = {
  skipCompanySearchStep: () => void;
  skipCompanyEditStep: () => void;
};

export const useNavigation = (): UseNavigationReturn => {
  const [searchParams] = useSearchParams();
  const config = useConfigContext();
  const {
    currentStep,
    queryParams,
    company,
    updateCurrentStep,
    increaseCurrentStep,
  } = useStore();

  const skipCompanySearchStep = () => {
    if (
      config.stepsConfig[currentStep - 1]?.id ===
        BaseStepIdEnum.company_search &&
      company?.registration_number &&
      company?.country &&
      queryParams.registrationNumber
    ) {
      increaseCurrentStep();
    }
  };

  const skipCompanyEditStep = () => {
    let hasAllFieldsFilled = true;

    config.companyFields
      .filter((field: CustomField) => field.isEnabled)
      .forEach((field: CustomField) => {
        const fieldId =
          field.id === 'name'
            ? 'company'
            : field.id
                .toLowerCase()
                .replace(/([-_][a-z])/g, (fieldId: string) =>
                  fieldId.toUpperCase().replace('-', '').replace('_', ''),
                );

        if (!searchParams.has(fieldId) || searchParams.get(fieldId) === '') {
          hasAllFieldsFilled = false;
        }
      });

    if (hasAllFieldsFilled) {
      updateCurrentStep(currentStep + 1);
    }
  };

  return { skipCompanySearchStep, skipCompanyEditStep };
};
