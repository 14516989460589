import { FunctionComponent, useEffect, useMemo } from 'react';
import { Box, Button, Heading, Input, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  BaseStepIdEnum,
  COMPANY_NAME_MAX_LENGTH,
  companySchema,
  CompanyStatusEnum,
  CustomField,
  customFieldsExtendValidation,
  FieldPropertyTypeEnum,
} from 'shared-domain';
import {
  defaultCaseCustomPropertiesValues,
  defaultCaseMetadataValues,
  defaultEntityCustomPropertiesValues,
  generatedFieldName,
  preSubmitHelper,
  useConfigContext,
  useStore,
  useSubmitCompanyForm,
} from 'frontend-common';
import { createYupSchema } from 'shared-common';

import { GroupController } from '../form';
import { CompanyEditFormValues, ControlledInputProps } from './type';
import { ControlledFields } from './controlled-fields';

const CompanyEditDefaultInput: FunctionComponent<ControlledInputProps> = ({
  stepId,
  field,
}) => {
  const { getValues, control } = useFormContext();
  const { t } = useTranslation();
  const fieldName = generatedFieldName(field);

  return (
    <Box w="100%" key={fieldName}>
      {field.nested &&
        t(`steps.${stepId}.nested.${field.nested}`) !== '' &&
        (field.id === 'code' ||
          field.id === 'street_address' ||
          field.id === 'iban') && (
          <Heading
            as="h3"
            pt="5"
            pb="2"
            fontWeight={600}
            color="brand.main-3"
            fontSize={{ base: 'xl', md: '2xl' }}
          >
            {t(`steps.${stepId}.nested.${field.nested}`)}
          </Heading>
        )}
      <GroupController
        name={fieldName}
        label={
          t(
            `steps.${stepId}.${
              fieldName.includes('.0.')
                ? `${field.nested}.${field.id}`
                : fieldName
            }.label`,
          ) || fieldName
        }
        helper={
          field.hasHelper ? t(`steps.${stepId}.${fieldName}.helper`) : null
        }
        isRequired={
          field.isRequired ||
          (fieldName === 'iban' && !!getValues('banking_information.bic')) ||
          (fieldName === 'bic' && !!getValues('banking_information.iban'))
        }
        control={control}
        render={(f) => {
          return (
            <Input
              type={field.type}
              maxW="400px"
              placeholder={
                field.hasPlaceholder
                  ? t(`steps.${stepId}.${fieldName}.placeholder`)
                  : undefined
              }
              {...f}
            />
          );
        }}
      />
    </Box>
  );
};

const stepId = BaseStepIdEnum.company_edit;

export const CompanyEdit = () => {
  const config = useConfigContext();
  const { t } = useTranslation();
  const { submitCompanyForm } = useSubmitCompanyForm();
  const { company, metadata, customProperties, queryParams } = useStore();

  const defaultValues = useMemo(() => {
    const defaultCustomPropertiesValues = defaultEntityCustomPropertiesValues(
      config.companyFields,
      company,
    );

    const defaultCompanyValues: CompanyEditFormValues = {
      name: company?.name || '',
      commercial_name: company?.commercial_name || '',
      registration_number: company?.registration_number || '',
      country: company?.country || '',
      legal_form: company?.legal_form || '',
      status: company?.status || CompanyStatusEnum.not_reported,
      registration_date: company?.registration_date || '',
      address: {
        street_address: company?.address?.street_address || '',
        street_address_2: company?.address?.street_address_2 || '',
        postal_code: company?.address?.postal_code || '',
        city: company?.address?.city || '',
        state: company?.address?.state || '',
        region: company?.address?.region || '',
        country: company?.address?.country || '',
      },
      banking_information: {
        iban: company?.banking_information?.iban || '',
        bic: company?.banking_information?.bic || '',
      },
      tax_identification_number: company?.tax_identification_number || '',
      website_url: company?.website_url || '',
      employer_identification_number:
        company?.employer_identification_number || '',
      share_capital: company?.share_capital || '',
      classifications: [
        {
          code: company?.classifications
            ? company?.classifications[0]?.code || ''
            : '',
          description: company?.classifications
            ? company?.classifications[0]?.description || ''
            : '',
        },
      ],
      custom_properties: defaultCustomPropertiesValues,
    };

    return {
      ...defaultCompanyValues,
      ...defaultCaseMetadataValues(config.companyFields, metadata),
      ...defaultCaseCustomPropertiesValues(
        config.companyFields,
        customProperties,
      ),
    };
  }, [config.companyFields, company, queryParams]);

  const dynamicValidationSchema = useMemo(() => {
    // Extend fields with our validations
    const dynamicFormData = customFieldsExtendValidation(
      config.companyFields.filter(
        (f: CustomField) =>
          f.propertyType === FieldPropertyTypeEnum.metadata ||
          f.propertyType === FieldPropertyTypeEnum.custom,
      ),
      t,
      BaseStepIdEnum.company_edit,
    );
    // Create schema based on added validations
    // @TODO - OPS-9 - Replace Yup by Zod
    return dynamicFormData.reduce(createYupSchema, {});
  }, [config.companyFields]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    // @TODO - OPS-9 - Replace Yup by Zod
    resolver: yupResolver(
      companySchema(
        config.companyFields.filter(
          (f: CustomField) =>
            f.propertyType !== FieldPropertyTypeEnum.metadata &&
            f.propertyType !== FieldPropertyTypeEnum.custom,
        ),
      ).shape(dynamicValidationSchema),
    ),
    defaultValues,
  });

  const {
    handleSubmit,
    getValues,
    reset,
    setError,
    formState: { isValid, isSubmitting },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [reset, queryParams]);

  useEffect(() => {
    if (getValues('name').length > COMPANY_NAME_MAX_LENGTH) {
      setError('name', {
        type: 'manual',
        message: 'Name must be at most 160 characters',
      });
    }
  }, [setError, getValues]);

  const onSubmit: SubmitHandler<any> = async (formData) => {
    const { companyData, caseMetadata, caseCustomProperties } = preSubmitHelper(
      config.companyFields,
      formData,
      'company',
    );

    submitCompanyForm({
      companyData,
      caseMetadata,
      caseCustomProperties,
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing="6">
          {config.companyFields
            .filter((field: CustomField) => field.isEnabled)
            .map((field: CustomField, idx) => (
              <ControlledFields
                key={idx}
                field={field}
                stepId={stepId}
                Fallback={CompanyEditDefaultInput}
              ></ControlledFields>
            ))}
        </VStack>

        <Box mt="6">
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};
