import { upperFirst } from 'lodash';
import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export function DataComparisonNoLabel({
  informationDefinition,
}: {
  informationDefinition: { label: string; translateKey: string };
}) {
  const { t } = useTranslation();

  return (
    <Text color="black" fontSize="sm">
      {t('steps.checks_list.document_analysis.data_comparison_no_data', {
        label: t(informationDefinition.translateKey),
        defaultValue: `${upperFirst(
          informationDefinition.label,
        )}: no data extracted or to compare with`,
      })}
    </Text>
  );
}
