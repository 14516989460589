import { Text } from '@chakra-ui/react';
import {
  CheckResultEnum,
  DocumentAnalysisDetailedResultModel,
} from 'shared-domain';
import { useTranslation } from 'react-i18next';
import { upperFirst } from 'lodash';
import { DataComparisonNoLabel } from './data-comparison-no-data';

export function DataComparisonLabel({
  detailedResult,
  informationDefinition,
  entityName,
}: {
  detailedResult: DocumentAnalysisDetailedResultModel;
  informationDefinition: { label: string; translateKey: string };
  entityName: string;
}) {
  const { t } = useTranslation();

  if (detailedResult.result === CheckResultEnum.approved) {
    return (
      <Text color="black" fontSize="sm">
        {t(
          'steps.checks_list.document_analysis.data_comparison_expected_detected',
          {
            label: t(informationDefinition.translateKey).toLowerCase(),
            defaultValue: `Expected ${informationDefinition.label.toLowerCase()} detected`,
          },
        )}
        :&nbsp;
        <Text color="brand.secondary" fontSize="sm" as="span">
          {detailedResult.received}
        </Text>
      </Text>
    );
  }

  if (detailedResult.result === CheckResultEnum.rejected) {
    return (
      <Text color="black" fontSize="sm">
        {t(
          'steps.checks_list.document_analysis.data_comparison_unexpected_detected',
          {
            label: t(informationDefinition.translateKey).toLowerCase(),
            defaultValue: `Unexpected ${informationDefinition.label.toLowerCase()} detected`,
          },
        )}
        :&nbsp;
        <Text color="brand.secondary" fontSize="sm" as="span">
          {detailedResult.received}
        </Text>
        &nbsp;-&nbsp;
        {t('steps.checks_list.document_analysis.data_comparison_expected', {
          value: detailedResult.expected,
          defaultValue: `Expected: ${detailedResult.expected}`,
        })}
      </Text>
    );
  }

  if (detailedResult.result === CheckResultEnum.error) {
    if (!detailedResult.expected) {
      return (
        <Text color="black" fontSize="sm">
          {t(
            'steps.checks_list.document_analysis.data_comparison_not_found_entity',
            {
              label: t(informationDefinition.translateKey).toLowerCase(),
              name: entityName,
              defaultValue: `${upperFirst(
                informationDefinition.label,
              )} not found on ${entityName}`,
            },
          )}
        </Text>
      );
    }

    if (!detailedResult.received) {
      return (
        <Text color="black" fontSize="sm">
          {t(
            'steps.checks_list.document_analysis.data_comparison_not_found_document',
            {
              label: t(informationDefinition.translateKey).toLowerCase(),
              defaultValue: `${upperFirst(
                informationDefinition.label,
              )} not found on document`,
            },
          )}
        </Text>
      );
    }
  }

  return (
    <DataComparisonNoLabel informationDefinition={informationDefinition} />
  );
}
