import {
  format,
  formatDistanceToNowStrict,
  compareAsc,
  formatDistanceToNow,
} from 'date-fns';
import { isNotNullNotUndefined } from './type-guards';
import { fr, enUS } from 'date-fns/locale';

/**
 * produce format like Oct 8, 2021 09:06
 */
export const SHORT_DATE_TIME_FORMAT = 'MMM d, y HH:mm';

/**
 * produce format like Oct 8, 2021 09:06:59
 */
export const SECOND_DATE_TIME_FORMAT = 'MMM d, y HH:mm:ss';

/**
 * produce format like October 8, 2021 09:06
 */
export const DATE_TIME_FORMAT = 'MMMM d, y HH:mm';

/**
 * Format a date to `MMM d, y HH:mm` eg `Oct 8, 2021 09:06`
 * @param dateOrString
 * @returns
 */
export const formatDateTime = (
  dateOrString: string | Date,
  fmt: string = SHORT_DATE_TIME_FORMAT,
): string => {
  const date =
    dateOrString instanceof Date ? dateOrString : new Date(dateOrString);
  return format(date, fmt);
};

// produce format like October 8, 2021
const DATE_FORMAT = 'MMMM d, y';

/**
 * Format a date to `MMMM d, y` eg `Oct 8, 2021`
 * @param dateOrString
 * @returns
 */
export const formatDate = (
  dateOrString: string | Date,
  options?: { locale: string },
): string => {
  const date =
    dateOrString instanceof Date ? dateOrString : new Date(dateOrString);
  return format(date, DATE_FORMAT, {
    locale: options?.locale === 'fr' ? fr : enUS,
  });
};

export const formatRelativeDateStrict = (
  dateOrString: string | Date,
): string => {
  const date =
    dateOrString instanceof Date ? dateOrString : new Date(dateOrString);

  return formatDistanceToNowStrict(date, {
    addSuffix: true,
  });
};

export const formatRelativeDate = (dateOrString: string | Date): string => {
  const date =
    dateOrString instanceof Date ? dateOrString : new Date(dateOrString);

  return formatDistanceToNow(date, {
    addSuffix: true,
  });
};

export const getLatestDate = (dates: (string | Date | null)[]): Date | null => {
  const latestDates = dates
    .filter(isNotNullNotUndefined)
    .map((dateOrString) =>
      dateOrString instanceof Date ? dateOrString : new Date(dateOrString),
    )
    .sort(compareAsc);

  return latestDates.length > 0 ? latestDates[0] : null;
};
