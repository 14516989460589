import { useMemo } from 'react';
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  Modal,
  Text,
  Stack,
  Button,
  ModalFooter,
  Input,
  Box,
  Heading,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import type { AffiliatedCompanyFields } from 'shared-domain';
import { affiliatedCompanySchema, FieldTypeEnum } from 'shared-domain';
import {
  generatedFieldName,
  useConfigContext,
  useStore,
  useSubmitAffiliatedCompanyForm,
} from 'frontend-common';
import { format } from 'date-fns';
import { AffiliatedCompanyEditFormValues } from '../steps/type';
import { Checkbox, CountrySelect, GroupController } from '../form';

type ModalAffiliatedCompanyProps = {
  selectedAffiliatedCompanyId: string | null;
  onClose: () => void;
  validAffiliatedCompanies: () => void;
};

export const ModalAffiliatedCompany = (props: ModalAffiliatedCompanyProps) => {
  const { selectedAffiliatedCompanyId, onClose, validAffiliatedCompanies } =
    props;

  const { t, i18n } = useTranslation();
  const { submitAffiliatedCompanyForm } = useSubmitAffiliatedCompanyForm();
  const config = useConfigContext();
  const { getAffiliatedCompany } = useStore();

  const selectedAffiliatedCompany = useMemo(
    () =>
      selectedAffiliatedCompanyId
        ? getAffiliatedCompany(selectedAffiliatedCompanyId)
        : null,
    [selectedAffiliatedCompanyId],
  );

  const defaultValues = useMemo(() => {
    const defaultValues: AffiliatedCompanyEditFormValues = {
      name: selectedAffiliatedCompany?.name || '',
      registration_number: selectedAffiliatedCompany?.registration_number || '',
      country: selectedAffiliatedCompany?.country || '',
      legal_form: selectedAffiliatedCompany?.legal_form || '',
      registration_date: selectedAffiliatedCompany?.registration_date || '',
      classifications: [
        {
          code: selectedAffiliatedCompany?.classifications
            ? selectedAffiliatedCompany?.classifications[0]?.code || ''
            : '',
          description: selectedAffiliatedCompany?.classifications
            ? selectedAffiliatedCompany?.classifications[0]?.description || ''
            : '',
        },
      ],
      roles: selectedAffiliatedCompany?.roles || [],
      address: {
        street_address:
          selectedAffiliatedCompany?.address?.street_address || '',
        street_address_2:
          selectedAffiliatedCompany?.address?.street_address_2 || '',
        postal_code: selectedAffiliatedCompany?.address?.postal_code || '',
        state: selectedAffiliatedCompany?.address?.state || '',
        region: selectedAffiliatedCompany?.address?.region || '',
        city: selectedAffiliatedCompany?.address?.city || '',
        country: selectedAffiliatedCompany?.address?.country || '',
      },
      position: selectedAffiliatedCompany?.position || '',
      ownership_percentage:
        selectedAffiliatedCompany?.ownership_percentage ?? null,
    };

    return defaultValues;
  }, [selectedAffiliatedCompany]);

  const methods = useForm<AffiliatedCompanyEditFormValues>({
    mode: 'all',
    criteriaMode: 'all',
    // @TODO - OPS-9 - Replace Yup by Zod
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(
      affiliatedCompanySchema(config.affiliatedCompanyFields),
    ),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid, isSubmitting, isDirty },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    // @TODO: move logic in the hook
    const { registration_date, ...rest } = formData;
    const registrationDate = registration_date
      ? format(registration_date, 'yyyy-MM-dd')
      : null;

    validAffiliatedCompanies();

    submitAffiliatedCompanyForm({
      affiliatedCompanyData: { ...rest, registration_date: registrationDate },
      affiliatedCompanyId: selectedAffiliatedCompanyId,
      onClose,
    });
  };

  return (
    <Modal
      isOpen={true}
      size={['full', 'md']}
      onClose={() => {
        onClose();
      }}
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('steps.affiliated_company_edit.title')}</ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody m="0" p="6">
          {i18n.exists(`steps.affiliated_company_edit.subtitle`) && (
            <Text
              mb="6"
              dangerouslySetInnerHTML={{
                __html: t('steps.affiliated_company_edit.subtitle') ?? '',
              }}
            ></Text>
          )}
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <VStack spacing="6">
                {config.affiliatedCompanyFields
                  .filter((field: AffiliatedCompanyFields) => field.isEnabled)
                  .map((field: AffiliatedCompanyFields) => {
                    if (field.type === FieldTypeEnum.country) {
                      return (
                        <GroupController
                          key={
                            field.nested
                              ? `affiliated_company_${field.nested}_${field.id}`
                              : `affiliated_company_${field.id}`
                          }
                          data-testid={
                            field.nested
                              ? `affiliated_company_${field.nested}_${field.id}`
                              : `affiliated_company_${field.id}`
                          }
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          name={
                            field.nested
                              ? `${field.nested}.${field.id}`
                              : field.id
                          }
                          label={
                            t(
                              `steps.affiliated_company_edit.${generatedFieldName(
                                field,
                              )}.label`,
                            ) || generatedFieldName(field)
                          }
                          helper={
                            field.hasHelper
                              ? t(
                                  `steps.affiliated_company_edit.${field.id}.helper`,
                                )
                              : null
                          }
                          isRequired={field.isRequired}
                          control={control}
                          render={(f) => (
                            <CountrySelect
                              onChange={(value: string) => {
                                setValue(
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  field.nested
                                    ? `${field.nested}.${field.id}`
                                    : field.id,
                                  value ?? '',
                                  {
                                    shouldDirty: true,
                                    shouldValidate: true,
                                  },
                                );
                              }}
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              defaultValue={f.value}
                              placeholder={
                                field.hasPlaceholder
                                  ? t(
                                      `steps.affiliated_company_edit.${field.id}.placeholder`,
                                    )
                                  : undefined
                              }
                            />
                          )}
                        />
                      );
                    }

                    if (field.type === FieldTypeEnum.checkbox) {
                      return (
                        <GroupController
                          key={
                            field.nested
                              ? `affiliated_company_${field.nested}_${field.id}`
                              : `affiliated_company_${field.id}`
                          }
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          name={
                            field.nested
                              ? `${field.nested}.${field.id}`
                              : field.id
                          }
                          label={`${
                            t(
                              `steps.affiliated_company_edit.${field.id}.label`,
                            ) || field.id
                          } ${field.isRequired ? '*' : ''}`}
                          helper={
                            field.hasHelper
                              ? t(
                                  `steps.affiliated_company_edit.${field.id}.helper`,
                                )
                              : null
                          }
                          control={control}
                          render={(f) => {
                            return (
                              <Checkbox
                                stepId="affiliated_company_edit"
                                name={field.id}
                                onChange={(values: string[]) => {
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  setValue(field.id, values, {
                                    shouldDirty: true,
                                    shouldValidate: true,
                                  });
                                }}
                                options={field.options || []}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                defaultValue={f.value}
                              />
                            );
                          }}
                        />
                      );
                    }

                    return (
                      <Box w="100%" key={field.id}>
                        {field.nested &&
                          t(
                            `steps.affiliated_company_edit.nested.${field.nested}`,
                          ) !== '' &&
                          (field.id === 'code' ||
                            field.id === 'street_address') && (
                            <Heading
                              pt="5"
                              pb="2"
                              fontWeight={600}
                              color="brand.main-3"
                              fontSize={{ base: 'xl', md: '2xl' }}
                            >
                              {t(
                                `steps.affiliated_company_edit.nested.${field.nested}`,
                              )}
                            </Heading>
                          )}
                        <GroupController
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          name={
                            field.nested
                              ? field.nested === 'classifications'
                                ? `${field.nested}.0.${field.id}`
                                : `${field.nested}.${field.id}`
                              : field.id
                          }
                          label={
                            t(
                              `steps.affiliated_company_edit.${generatedFieldName(
                                field,
                              )}.label`,
                            ) || generatedFieldName(field)
                          }
                          helper={
                            field.hasHelper
                              ? t(
                                  `steps.affiliated_company_edit.${field.id}.helper`,
                                )
                              : null
                          }
                          isRequired={field.isRequired}
                          control={control}
                          render={(f) => {
                            return (
                              <Input
                                type={field.type}
                                maxW="400px"
                                {...f}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                value={f.value ?? ''}
                                placeholder={
                                  field.hasPlaceholder
                                    ? t(
                                        `steps.affiliated_company_edit.${field.id}.placeholder`,
                                      )
                                    : undefined
                                }
                              />
                            );
                          }}
                        />
                      </Box>
                    );
                  })}
              </VStack>
              {config.affiliatedCompanyFields
                .filter((field: AffiliatedCompanyFields) => !field.isEnabled)
                .map((field: AffiliatedCompanyFields) => {
                  return (
                    <GroupController
                      key={field.id}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      name={
                        field.nested ? `${field.nested}.${field.id}` : field.id
                      }
                      isRequired={false}
                      control={control}
                      render={(f) => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        return <Input type="hidden" maxW="400px" {...f} />;
                      }}
                    />
                  );
                })}
            </form>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <Stack direction={['column-reverse', 'row']} spacing={4}>
            <Button variant="outline" onClick={onClose}>
              {t('domain.form.cancel')}
            </Button>
            <Button
              variant="solid"
              isLoading={isSubmitting}
              isDisabled={!isValid || !isDirty}
              onClick={handleSubmit(onSubmit)}
              type="submit"
            >
              {t('domain.form.save')}
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
