import { datadogRum } from '@datadog/browser-rum';
import i18next from 'i18next';
import { EnvEnum, Environment } from 'shared-domain';

import { defaultRumConfig } from './rum-config';

export const configurationDatadog = (environment: Environment): void => {
  if (!datadogRum.getInternalContext()) {
    if (environment.env !== EnvEnum.dev) {
      datadogRum.init({
        ...defaultRumConfig,
        service: environment.service,
        env: environment.env,
      });

      datadogRum.setGlobalContextProperty('language', i18next.languages[0]);
    }
  }
};
