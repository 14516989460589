import { z } from 'zod';
import { CustomPropertyEntityTypeEnum } from '../types';

export const dotfileOnboardingApiBootstrapSchema = z
  .object({
    excludeDocumentIds: z.array(z.string()),
    hasAffiliatedCompanies: z.boolean(),
    hasChildrenCases: z.boolean().optional(),
    allowResumingFlow: z.boolean().optional(),
    resumeFlowIgnoredCustomProperties: z
      .object({
        [CustomPropertyEntityTypeEnum.case]: z.array(z.string()).optional(),
        [CustomPropertyEntityTypeEnum.company]: z.array(z.string()).optional(),
        [CustomPropertyEntityTypeEnum.individual]: z
          .array(z.string())
          .optional(),
      })
      .optional(),
    templateEngineConfig: z.function().returns(z.string().nullable()),
    tagsEngineConfig: z
      .function()
      .returns(z.array(z.string()).nullable())
      .optional(),
    affiliatedCompanyTemplateEngineConfig: z
      .function()
      .returns(z.string().nullable()),
    checkEngineConfig: z.function().returns(z.array(z.any())),
    isWebhooksEnabled: z.boolean().optional(),
    emailConfigs: z.array(z.object({ template: z.string() })).optional(),
    locales: z
      .object({
        fr: z.object({}),
        es: z.object({}),
        en: z.object({}),
        de: z.object({}),
        it: z.object({}),
        nl: z.object({}),
      })
      .optional(),
  })
  .refine((input) => {
    if (
      input.isWebhooksEnabled &&
      (input.emailConfigs === undefined || input.locales === undefined)
    ) {
      return false;
    }
    if (!input.isWebhooksEnabled && (input.emailConfigs || input.locales)) {
      return false;
    }

    return true;
  });
