import {
  StepConfig,
  StepTypeEnum,
  TermsAndConditionsStepConfig,
} from 'shared-domain';

/**
 * Retrieve all pdfUrl to whitelist them for CSP
 * @param stepsConfig
 * @returns
 */
export const extractObjectSrcFromStepConfig = (stepsConfig?: StepConfig[]) => {
  if (!stepsConfig) return undefined;

  return stepsConfig
    .filter(
      (step): step is TermsAndConditionsStepConfig =>
        step.type === StepTypeEnum.terms_and_conditions,
    )
    .map((stepPDF) => stepPDF.config.pdfUrl)
    .join(' ');
};
