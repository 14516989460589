export enum FieldTypeEnum {
  radio = 'radio',
  select = 'select',
  checkbox = 'checkbox',
  date = 'date',
  url = 'url',
  country = 'country',
  text = 'text',
  textarea = 'textarea',
  number = 'number',
  tel = 'tel',
  email = 'email',
  affiliated = 'affiliated',
  iban = 'iban',
  bic = 'bic',
}
