import { useMemo } from 'react';
import { Check, CheckStatusEnum, Company, Individual } from 'shared-domain';
import { useStore } from './use-store';

export function useHasActions(): {
  hasCompaniesActions: boolean;
  hasIndividualsActions: boolean;
  hasAffiliatedCompaniesActions: boolean;
} {
  const { company, individuals, affiliatedCompanies } = useStore();

  const hasCompaniesActions = useMemo(
    () =>
      (company?.checks?.filter((check: Check) =>
        [CheckStatusEnum.in_progress, CheckStatusEnum.rejected].includes(
          check.status,
        ),
      ).length ?? 0) > 0,
    [company],
  );

  const hasIndividualsActions = useMemo(
    () =>
      (individuals
        ?.flatMap((individual) => (individual as Individual).checks)
        .filter((check: Check | undefined) => {
          return (
            !!check &&
            [CheckStatusEnum.in_progress, CheckStatusEnum.rejected].includes(
              check?.status,
            )
          );
        }).length ?? 0) > 0,
    [individuals],
  );

  const hasAffiliatedCompaniesActions = useMemo(
    () =>
      (affiliatedCompanies
        ?.flatMap((company: Company) => company.checks)
        .filter((check: Check | undefined) => {
          if (check) {
            return [
              CheckStatusEnum.in_progress,
              CheckStatusEnum.rejected,
            ].includes(check?.status);
          } else {
            return false;
          }
        }).length ?? 0) > 0,
    [affiliatedCompanies],
  );

  return {
    hasCompaniesActions,
    hasIndividualsActions,
    hasAffiliatedCompaniesActions,
  };
}
