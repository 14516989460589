import i18next from 'i18next';
import { useToast } from '@chakra-ui/react';
import { datadogRum } from '@datadog/browser-rum';

import { useLoadingContext } from '../contexts';
import { useStore } from './use-store';
import { useApi } from './use-api';
import { useProgress } from './use-progress';

export const useSubmit = (): (() => void) => {
  const { setLoading } = useLoadingContext();
  const { removeProgress } = useProgress();
  const toast = useToast();
  const api = useApi();
  const {
    queryParams,
    totalSteps,
    currentStep,
    metadata,
    customProperties,
    company,
    affiliatedCompanies,
    individuals,
    increaseCurrentStep,
    decreaseCurrentStep,
    updateCaseId,
    updateQueryParams,
    updateResumeFlow,
    resumeFlow,
  } = useStore();

  const submitStep = async () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    if (
      currentStep > totalSteps ||
      (resumeFlow && currentStep === totalSteps)
    ) {
      try {
        setLoading(true);
        const response = await api.post(`dotfile/cases`, {
          company,
          affiliatedCompanies,
          individuals,
          metadata: {
            ...metadata,
            locale: i18next.languages[0],
            email: queryParams.email,
          },
          customProperties,
          externalId: queryParams.externalId,
          templateId: queryParams.templateId,
        });

        // Remove progress in local storage
        removeProgress();

        // Store caseId in local storage
        localStorage.setItem('caseId', response.data.caseId);

        updateCaseId(response.data.caseId);
        updateQueryParams({ ...queryParams, externalId: null });
        updateResumeFlow(false);
      } catch (err: any) {
        // Return to the last step
        decreaseCurrentStep();

        // Display error
        toast({
          variant: 'toast_error',
          title: 'An error occurred',
          description: `${err.response.data.message}. If the problem persists contact support.`,
          status: 'error',
          isClosable: false,
          position: 'bottom-right',
          duration: 8000,
        });

        const error = {
          type: err.response.status.toString(),
          message: err.response.data.message,
        };
        datadogRum.addError(error);
      } finally {
        setLoading(false);
      }
    } else {
      increaseCurrentStep();
    }
  };

  return submitStep;
};
