import {
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from '@chakra-ui/react';
import { FunctionComponent, ClipboardEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { ControlledInputProps } from '../type';
import { useTranslation } from 'react-i18next';
import { generatedFieldName } from 'frontend-common';
import { GroupController } from '../../form';

export const ControlledNumberInput: FunctionComponent<ControlledInputProps> = ({
  stepId,
  field,
}) => {
  const { setValue, control } = useFormContext();
  const { t } = useTranslation();

  const fieldName = generatedFieldName(field);

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text/plain');
    setValue(fieldName, Number.parseInt(pastedData), {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  return (
    <GroupController
      key={fieldName}
      name={fieldName}
      label={t(`steps.${stepId}.${fieldName}.label`) || fieldName}
      helper={field.hasHelper ? t(`steps.${stepId}.${fieldName}.helper`) : null}
      isRequired={field.isRequired}
      control={control}
      render={(f) => (
        <InputGroup maxW="400px">
          {field.addons?.left && (
            <InputLeftAddon children={field.addons.left} />
          )}
          <Input
            maxW="400px"
            // Do no use type="number" as it update displayed value but
            // does't trigger onChange for letters
            {...f}
            onChange={(event) => {
              // Number is stricter than parseFloat but there still some caveat around string with whitespace
              // @see https://stackoverflow.com/a/13676265
              const value = !event.target.value
                ? null
                : event.target.value.trim() !== event.target.value
                  ? event.target.value
                  : isNaN(Number(event.target.value))
                    ? event.target.value
                    : Number(event.target.value);
              setValue(fieldName, value, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true,
              });
            }}
            onPaste={handlePaste}
            defaultValue={
              ![undefined, null, ''].includes(f.value) ? f.value : ''
            }
            placeholder={
              field.hasPlaceholder
                ? t(`steps.${stepId}.${fieldName}.placeholder`)
                : undefined
            }
          />
          {field.addons?.right && (
            <InputRightAddon children={field.addons.right} />
          )}
        </InputGroup>
      )}
    />
  );
};
