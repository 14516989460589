import { z } from 'zod';
import { parsePhoneNumber } from 'libphonenumber-js';
import { CompanyStatusEnum, IndividualRoleEnum } from '../types';
import {
  COMPANY_COMMERCIAL_NAME_MAX_LENGTH,
  COMPANY_NAME_MAX_LENGTH,
} from '../constants';
import { customPropertiesSchema } from './custom-properties.schema';

const validatePhoneNumber = z.custom<{ arg: string }>((val) => {
  if (!val) {
    return true;
  }
  const parsed = parsePhoneNumber(<string>val);

  return !!parsed && !!val && parsed.isValid();
});

export const caseSchema = z.object({
  company: z
    .object({
      name: z.string().max(COMPANY_NAME_MAX_LENGTH),
      commercial_name: z
        .string()
        .max(COMPANY_COMMERCIAL_NAME_MAX_LENGTH)
        .nullish(),
      registration_number: z.string(),
      registration_date: z.string().nullish(),
      status: z.nativeEnum(CompanyStatusEnum).optional(),
      legal_form: z.string().nullish(),
      country: z.string().length(2).toUpperCase(),
      address: z
        .object({
          street_address: z.string().nullish(),
          street_address_2: z.string().nullish(),
          postal_code: z.string().nullish(),
          city: z.string().nullish(),
          state: z.string().nullish(),
          region: z.string().nullish(),
          country: z.string().length(2).toUpperCase().nullish(),
        })
        .optional(),
      classifications: z
        .object({
          type: z.string().nullish(),
          code: z.string().nullish(),
          description: z.string().nullish(),
        })
        .array()
        .optional(),
      banking_information: z
        .object({
          iban: z.string().min(15).nullish(),
          bic: z.string().min(8).nullish(),
        })
        .optional(),
      tax_identification_number: z.string().nullish(),
      website_url: z.string().nullish(),
      employer_identification_number: z.string().nullish(),
      share_capital: z.string().nullish(),
      custom_properties: customPropertiesSchema.optional(),
    })
    .required({
      name: true,
      registration_number: true,
      country: true,
    })
    .nullish(),
  affiliatedCompanies: z
    .object({
      name: z.string().max(COMPANY_NAME_MAX_LENGTH),
      registration_number: z.string(),
      legal_form: z.string().nullish(),
      country: z.string().length(2).toUpperCase(),
      address: z
        .object({
          street_address: z.string().nullish(),
          street_address_2: z.string().nullish(),
          postal_code: z.string().nullish(),
          city: z.string().nullish(),
          state: z.string().nullish(),
          region: z.string().nullish(),
          country: z.string().length(2).toUpperCase().nullish(),
        })
        .optional(),
      roles: z.nativeEnum(IndividualRoleEnum).optional().array().optional(),
      position: z.string().nullish(),
      ownership_percentage: z.number().nullish(),
      custom_properties: customPropertiesSchema.optional(),
    })
    .required({
      name: true,
      registration_number: true,
      country: true,
    })
    .array(),
  individuals: z
    .object({
      roles: z.nativeEnum(IndividualRoleEnum).optional().array(),
      first_name: z.string(),
      middle_name: z.string().nullish(),
      last_name: z.string(),
      maiden_name: z.string().nullish(),
      email: z.string().email().nullish(),
      birth_date: z.string().nullish(),
      birth_country: z.string().length(2).toUpperCase().nullish(),
      birth_place: z.string().nullish(),
      address: z
        .object({
          street_address: z.string().nullish(),
          street_address_2: z.string().nullish(),
          postal_code: z.string().nullish(),
          city: z.string().nullish(),
          state: z.string().nullish(),
          region: z.string().nullish(),
          country: z.string().length(2).toUpperCase().nullish(),
        })
        .optional(),
      banking_information: z
        .object({
          iban: z.string().min(15).nullish(),
          bic: z.string().min(8).nullish(),
        })
        .optional(),
      tax_identification_number: z.string().nullish(),
      social_security_number: z.string().nullish(),
      phone_number: validatePhoneNumber,
      position: z.string().nullish(),
      ownership_percentage: z.number().nullish(),
      custom_properties: customPropertiesSchema.optional(),
    })
    .required({
      first_name: true,
      last_name: true,
    })
    .array(),
  metadata: z.record(z.string(), z.any().nullish()),
  customProperties: customPropertiesSchema,
  externalId: z.string().nullish(),
  templateId: z
    .string()
    .uuid()
    .nullable()
    .transform((val) => {
      if (val) {
        return val;
      } else {
        return null;
      }
    }),
});
