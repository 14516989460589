import { useConfigContext } from '../contexts';
import { useStore } from './use-store';

/**
 *
 * @returns The id of the current step
 */
export const useStepId = (): string => {
  const { stepsConfig } = useConfigContext();
  const { currentStep } = useStore();

  return stepsConfig[currentStep - 1].id;
};
