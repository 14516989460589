import { ComponentType } from 'react';
import { Flex, Box } from '@chakra-ui/react';

type MobileHeaderProps = {
  logo: ComponentType<{
    css?: string;
  }>;
};

export const MobileHeader = (props: MobileHeaderProps) => {
  const Logo = props.logo;

  return (
    <Flex
      py="2"
      px="4"
      height="20"
      alignItems="center"
      borderBottomWidth="1px"
      position="static"
      justifyContent="center"
      background="sidebar.backgroundColor"
    >
      <Box w="100%" h="100%" display="inline-block">
        <Logo />
      </Box>
    </Flex>
  );
};
