import { useRef } from 'react';
import axios, { AxiosInstance } from 'axios';

import { useEnvironmentContext } from '../contexts';

export const useApi = (): AxiosInstance => {
  const environment = useEnvironmentContext();

  const api = useRef(
    axios.create({
      baseURL: environment.baseUrlApi,
    }),
  );

  return api.current;
};
