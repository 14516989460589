import { FunctionComponent, useEffect, useMemo } from 'react';
import { Box, Heading, Input, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { CustomField, IndividualRoleEnum } from 'shared-domain';
import { generatedFieldName, useConfigContext } from 'frontend-common';

import { GroupController } from '../form';
import { ControlledInputProps } from '../steps/type';
import { ControlledFields } from '../steps/controlled-fields';

type IndividualFormProps = {
  isApplicant: boolean;
};

const IndividualFormDefaultInput: FunctionComponent<ControlledInputProps> = ({
  stepId,
  field,
}) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  const fieldName = generatedFieldName(field);
  return (
    <Box w="100%" key={fieldName}>
      {field.nested &&
        t(`steps.${stepId}.nested.${field.nested}`) !== '' &&
        (field.id === 'street_address' || field.id === 'iban') && (
          <Heading
            pt="5"
            pb="2"
            fontWeight={600}
            color="brand.main-3"
            fontSize={{ base: 'xl', md: '2xl' }}
          >
            {t(`steps.${stepId}.nested.${field.nested}`)}
          </Heading>
        )}
      <GroupController
        name={fieldName}
        label={t(`steps.${stepId}.${fieldName}.label`) || fieldName}
        helper={
          field.hasHelper ? t(`steps.${stepId}.${fieldName}.helper`) : null
        }
        isRequired={field.isRequired}
        control={control}
        render={(f) => {
          return (
            <Input
              type={field.type}
              maxW="400px"
              placeholder={
                field.hasPlaceholder
                  ? t(`steps.${stepId}.${fieldName}.placeholder`)
                  : undefined
              }
              {...f}
              value={f.value ?? ''}
            />
          );
        }}
      />
    </Box>
  );
};

const stepId = 'individual_edit';

export const IndividualForm = (props: IndividualFormProps) => {
  const { isApplicant } = props;

  const config = useConfigContext();
  const { getValues, setError } = useFormContext();

  useEffect(() => {
    if (isApplicant && !getValues('email')) {
      setError('email', {
        type: 'manual',
        message: "Please add applicant's email to continue",
      });
    }
  }, [setError, isApplicant, getValues]);

  const fields = useMemo(() => {
    return config.individualFields.map((field) => {
      const fieldName = generatedFieldName(field);
      return {
        ...field,
        isRequired:
          field.isRequired ||
          (fieldName === 'email' &&
            (isApplicant ||
              getValues('roles').includes(IndividualRoleEnum.applicant))) ||
          (fieldName === 'iban' && !!getValues('banking_information.bic')) ||
          (fieldName === 'bic' && !!getValues('banking_information.iban')),
      };
    });
  }, [config.individualFields]);

  return (
    <VStack spacing="6">
      {fields
        .filter((field: CustomField) => field.isEnabled)
        .map((field: CustomField, idx) => (
          <ControlledFields
            key={idx}
            field={field}
            stepId={stepId}
            Fallback={IndividualFormDefaultInput}
          ></ControlledFields>
        ))}
    </VStack>
  );
};
