import { useConfigContext } from '../contexts';
import { useStore } from './use-store';

export const usePreviousStep = (): (() => void) => {
  const config = useConfigContext();
  const { currentStep, metadata, updateCurrentStep, decreaseCurrentStep } =
    useStore();

  const previousStep = () => {
    if (config.beforePreviousStep) {
      config.beforePreviousStep({
        currentStep,
        metadata,
        decreaseCurrentStep,
        updateCurrentStep,
      });
    } else {
      decreaseCurrentStep();
    }
  };

  return previousStep;
};
