import { analysisProofOfAddressDetailedResults } from './analysis-proof-of-address-detailed-results';
import { analysisIBANDetailedResults } from './analysis-iban-detailed-results';
import { analysisRegistrationCertificateDetailedResults } from './analysis-registration-certificate-detailed-results';
import { DocumentAnalysisModelEnum } from 'shared-domain';

export const analysisDetailedResultsRecord = {
  [DocumentAnalysisModelEnum.iban]: analysisIBANDetailedResults,
  [DocumentAnalysisModelEnum.registration_certificate]:
    analysisRegistrationCertificateDetailedResults,
  [DocumentAnalysisModelEnum.proof_of_address]:
    analysisProofOfAddressDetailedResults,
} as const;

export function getAnalysisDetailedResults<
  Model extends DocumentAnalysisModelEnum,
>(model: Model): (typeof analysisDetailedResultsRecord)[Model] {
  return analysisDetailedResultsRecord[model];
}
