import { FunctionComponent } from 'react';
import { ControlledInputProps } from '../type';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatedFieldName } from 'frontend-common';
import { GroupController } from '../../form';
import {
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from '@chakra-ui/react';

export enum InputTextType {
  url = 'url',
  text = 'text',
}

export const ControlledTextInput: FunctionComponent<
  ControlledInputProps & { type?: InputTextType }
> = ({ stepId, field, type }) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  const fieldName = generatedFieldName(field);

  return (
    <GroupController
      key={fieldName}
      name={fieldName}
      label={t(`steps.${stepId}.${fieldName}.label`) || fieldName}
      helper={field.hasHelper ? t(`steps.${stepId}.${fieldName}.helper`) : null}
      isRequired={field.isRequired}
      control={control}
      render={(f) => {
        return (
          <InputGroup maxW="400px">
            {field.addons?.left && (
              <InputLeftAddon children={field.addons.left} />
            )}
            <Input
              placeholder={
                field.hasPlaceholder
                  ? t(`steps.${stepId}.${fieldName}.placeholder`)
                  : undefined
              }
              {...f}
              // If value is null, input would switch from controlled to uncontrolled
              value={f.value ?? ''}
              type={type ?? field.type}
            />
            {field.addons?.right && (
              <InputRightAddon children={field.addons.right} />
            )}
          </InputGroup>
        );
      }}
    />
  );
};
