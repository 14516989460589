import { generatedFieldName } from 'frontend-common';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledInputProps } from '../type';
import { FunctionComponent } from 'react';
import { CountrySelect, GroupController } from '../../form';
import { FieldPropertyTypeEnum } from 'shared-domain';

export const ControlledCountryInput: FunctionComponent<
  ControlledInputProps
> = ({ stepId, field }) => {
  const { setValue, control } = useFormContext();
  const { t } = useTranslation();

  const fieldName = generatedFieldName(field);

  return (
    <GroupController
      key={fieldName}
      data-testid="country-select"
      name={fieldName}
      label={t(`steps.${stepId}.${fieldName}.label`) || fieldName}
      helper={field.hasHelper ? t(`steps.${stepId}.${fieldName}.helper`) : null}
      isRequired={field.isRequired}
      control={control}
      render={(f) => (
        <CountrySelect
          onChange={(value: string[] | string) => {
            setValue(
              fieldName,
              value ??
                (field.isMulti ||
                field.propertyType === FieldPropertyTypeEnum.custom
                  ? []
                  : ''),
              {
                shouldDirty: true,
                shouldValidate: true,
              },
            );
          }}
          defaultValue={f.value}
          isMulti={field.isMulti}
          isCustomProperty={field.propertyType === FieldPropertyTypeEnum.custom}
          placeholder={
            field.hasPlaceholder
              ? t(`steps.${stepId}.${fieldName}.placeholder`)
              : undefined
          }
        />
      )}
    />
  );
};
