import {
  CheckboxGroup as ChakraCheckboxGroup,
  CheckboxGroupProps as ChakraCheckboxGroupProps,
  Checkbox as ChakraCheckbox,
  VStack,
  Tooltip,
  Icon,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { OTHER } from '../steps/controlled-fields';
import { useState } from 'react';

type CheckboxProps = {
  stepId: string;
  name: string;
  options: string[];
  defaultValue: string[];
  onChange?: ChakraCheckboxGroupProps['onChange'];
  hasOtherOption?: boolean;
  isRequired?: boolean;
  forceAllChecked?: boolean;
};

export const Checkbox = (props: CheckboxProps) => {
  const {
    stepId,
    name,
    onChange,
    options,
    defaultValue,
    hasOtherOption,
    isRequired,
    forceAllChecked = false,
  } = props;

  const { t, i18n } = useTranslation();

  const showTooltip = (option: string) =>
    i18n.exists(`steps.${stepId}.${name}.tooltips.${option}`);
  const [atLeastOneChecked, setAtLeastOneChecked] = useState(
    defaultValue && defaultValue.length > 0,
  );

  return (
    <ChakraCheckboxGroup
      defaultValue={defaultValue}
      onChange={(value) => {
        setAtLeastOneChecked(value.length > 0);
        onChange?.(value);
      }}
    >
      <VStack spacing="5" alignItems="start">
        {options.map((option: string) => (
          <ChakraCheckbox
            key={option}
            value={option}
            // Must be set on checbkox when CheckboxGroup is used inside a FormControl to prevent native html validation to make ALL checkboxes required
            // @see https://github.com/chakra-ui/chakra-ui/issues/5799#issuecomment-1874756752
            isRequired={forceAllChecked || (isRequired && !atLeastOneChecked)}
          >
            {t(`steps.${stepId}.${name}.options.${option}`)}
            {showTooltip(option) && (
              <Tooltip label={t(`steps.${stepId}.${name}.tooltips.${option}`)}>
                <Icon style={{ marginLeft: '8px' }}>
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
                </Icon>
              </Tooltip>
            )}
          </ChakraCheckbox>
        ))}

        {hasOtherOption && (
          <ChakraCheckbox
            value={OTHER}
            isRequired={isRequired && !atLeastOneChecked}
          >
            {t(`steps.${stepId}.${name}.${OTHER}_option.option`)}
          </ChakraCheckbox>
        )}
      </VStack>
    </ChakraCheckboxGroup>
  );
};
