import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  CheckResultEnum,
  DocumentAnalysisDetailedResultModel,
} from 'shared-domain';

export function DataValidationLabel({
  detailedResult,
  informationDefinition,
}: {
  detailedResult: DocumentAnalysisDetailedResultModel;
  informationDefinition: { label: string; translateKey: string };
}) {
  const { t } = useTranslation();

  if (detailedResult.result === CheckResultEnum.approved) {
    return (
      <Text fontSize="sm" color="black">
        {t('steps.checks_list.document_analysis.data_validation_approved', {
          label: t(informationDefinition.translateKey).toLowerCase(),
          defaultValue: `Valid ${informationDefinition.label} detected`,
        })}
      </Text>
    );
  }

  if (detailedResult.result === CheckResultEnum.rejected) {
    return (
      <Text fontSize="sm" color="black">
        {t('steps.checks_list.document_analysis.data_validation_rejected', {
          label: t(informationDefinition.translateKey).toLowerCase(),
          defaultValue: `Invalid ${informationDefinition.label} detected`,
        })}
      </Text>
    );
  }

  if (detailedResult.result === CheckResultEnum.error) {
    return (
      <Text fontSize="sm" color="black">
        {t('steps.checks_list.document_analysis.data_validation_error', {
          label: t(informationDefinition.translateKey).toLowerCase(),
          defaultValue: `No ${informationDefinition.label} detected`,
        })}
      </Text>
    );
  }

  return null;
}
