import { createContext, useContext } from 'react';
import { Environment } from 'shared-domain';

export const EnvironmentContext = createContext<Environment | null>(null);

/**
 * Return the environment from the context
 * @returns Environment
 */
export const useEnvironmentContext = (): Environment => {
  const environment = useContext(EnvironmentContext);

  if (!environment) {
    const error = new Error(
      'useEnvironmentContext: `environment` is falsy. Seems you forgot to wrap component within the EnvironmentContextProvider or initialize the context value',
    );
    error.name = 'EnvironmentContextError';
    Error.captureStackTrace?.(error, useContext);
    throw error;
  }

  return environment;
};

export const EnvironmentContextProvider = EnvironmentContext.Provider;
