import { useMemo } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { COUNTRIES } from 'shared-domain';

export type CountryProps = {
  /**
   * ISO 3166-1 alpha-2 country code
   * @see https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
   */
  code: string;
  /**
   * Only default country flag instead of flag and name
   */
  flagOnly?: boolean;
} & BoxProps;

export const Country = ({
  code,
  flagOnly,
  ...rest
}: CountryProps): // eslint-disable-next-line no-undef
JSX.Element => {
  const children = useMemo(() => {
    const country = COUNTRIES.find((c) => c.code === code);
    if (!country) {
      console.error(`Could not find country from code '${code}'`);
      return '???';
    }

    if (flagOnly) {
      try {
        return country.emoji;
      } catch (error) {
        console.error(error);
        return '???';
      }
    }

    return `${country.emoji} ${country.name}`;
  }, [code, flagOnly]);

  return (
    <Box as="span" {...rest}>
      {children}
    </Box>
  );
};
