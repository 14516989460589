import { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Plus } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from '@chakra-ui/react';
import { FilesFormValues } from '../modal';
import { GroupControl } from './group-control';
import { FormDocumentItem } from './form-document-item';
import { Accept } from 'react-dropzone';

type FormDocumentListProps = {
  accept?: Accept;
  label: string;
  maxItem?: number;
};

export const FormDocumentList = ({
  accept,
  label,
  maxItem,
}: FormDocumentListProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<FilesFormValues>();

  const {
    fields: fieldArray,
    remove,
    append,
  } = useFieldArray({
    control,
    name: 'files',
  });

  const handleAppendValue = useCallback(() => {
    append({
      file: '',
    });
  }, [append]);

  return (
    <>
      <GroupControl>
        {fieldArray?.map((fieldArrayItem, index: number) => (
          <FormDocumentItem
            key={fieldArrayItem.id}
            index={index}
            label={label}
            remove={remove}
            accept={accept}
          />
        ))}
      </GroupControl>
      {(!maxItem || fieldArray.length < maxItem) && (
        <Button
          type="button"
          variant="ghost"
          colorScheme="gray"
          leftIcon={<Icon as={Plus} />}
          size="sm"
          onClick={handleAppendValue}
        >
          {t('document_add')}
        </Button>
      )}
    </>
  );
};
