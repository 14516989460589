import { FunctionComponent } from 'react';
import { ControlledInputProps } from '../type';
import { GroupController, PhoneNumberInput } from '../../form';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { generatedFieldName } from 'frontend-common';

export const ControlledPhoneNumberInput: FunctionComponent<
  ControlledInputProps
> = ({ stepId, field }) => {
  const { setValue, control } = useFormContext();
  const { t } = useTranslation();

  const fieldName = generatedFieldName(field);
  return (
    <GroupController
      key={fieldName}
      name={fieldName}
      label={t(`steps.${stepId}.${fieldName}.label`) || fieldName}
      helper={field.hasHelper ? t(`steps.${stepId}.${fieldName}.helper`) : null}
      isRequired={field.isRequired}
      control={control}
      render={(f) => (
        <PhoneNumberInput
          value={(f.value ?? '').replace('+', '')}
          onChange={(value: string) => {
            setValue(fieldName, value ?? '', {
              shouldDirty: true,
              shouldValidate: true,
            });
          }}
          placeholder={
            field.hasPlaceholder
              ? t(`steps.${stepId}.${fieldName}.placeholder`)
              : undefined
          }
        />
      )}
    />
  );
};
