import { useMemo } from 'react';
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  Modal,
  Text,
  Stack,
  Button,
  ModalFooter,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  BaseStepIdEnum,
  CustomField,
  customFieldsExtendValidation,
  defaultMetadataValuesHelper,
  FieldPropertyTypeEnum,
  IndividualRoleEnum,
  individualSchema,
} from 'shared-domain';
import {
  defaultCaseCustomPropertiesValues,
  defaultEntityCustomPropertiesValues,
  preSubmitHelper,
  useConfigContext,
  useStore,
  useSubmitIndividualForm,
} from 'frontend-common';
import { createYupSchema } from 'shared-common';

import { IndividualEditFormValues } from '../steps/type';
import { IndividualForm } from '../shared';

type ModalIndividualProps = {
  selectedIndividualId: string | null;
  onClose: () => void;
};

export const ModalIndividual = (props: ModalIndividualProps) => {
  const { selectedIndividualId, onClose } = props;

  const { t, i18n } = useTranslation();
  const { submitIndividualForm } = useSubmitIndividualForm();
  const config = useConfigContext();
  const { metadata, customProperties, getIndividual } = useStore();

  const selectedIndividual = useMemo(
    () => (selectedIndividualId ? getIndividual(selectedIndividualId) : null),
    [selectedIndividualId],
  );

  const defaultValues = useMemo(() => {
    const defaultCustomPropertiesValues = defaultEntityCustomPropertiesValues(
      config.individualFields,
      selectedIndividual,
    );

    const defaultIndividualValues: IndividualEditFormValues = {
      first_name: selectedIndividual?.first_name || '',
      middle_name: selectedIndividual?.middle_name || '',
      last_name: selectedIndividual?.last_name || '',
      maiden_name: selectedIndividual?.maiden_name || '',
      email: selectedIndividual?.email || '',
      roles: selectedIndividual?.roles || [],
      birth_date: selectedIndividual?.birth_date || '',
      birth_country: selectedIndividual?.birth_country || '',
      birth_place: selectedIndividual?.birth_place || '',
      holdings: selectedIndividual?.holdings || '',
      address: {
        street_address: selectedIndividual?.address?.street_address || '',
        street_address_2: selectedIndividual?.address?.street_address_2 || '',
        postal_code: selectedIndividual?.address?.postal_code || '',
        city: selectedIndividual?.address?.city || '',
        state: selectedIndividual?.address?.state || '',
        region: selectedIndividual?.address?.region || '',
        country: selectedIndividual?.address?.country || '',
      },
      banking_information: {
        iban: selectedIndividual?.banking_information?.iban || '',
        bic: selectedIndividual?.banking_information?.bic || '',
      },
      tax_identification_number:
        selectedIndividual?.tax_identification_number || '',
      social_security_number: selectedIndividual?.social_security_number || '',
      phone_number: selectedIndividual?.phone_number || '',
      position: selectedIndividual?.position || '',
      ownership_percentage: selectedIndividual?.ownership_percentage ?? null,
      custom_properties: defaultCustomPropertiesValues,
    };

    return {
      ...defaultIndividualValues,
      ...defaultMetadataValuesHelper(
        config.individualFields,
        metadata,
        defaultIndividualValues.first_name,
        defaultIndividualValues.last_name,
      ),
      ...defaultCaseCustomPropertiesValues(
        config.individualFields,
        customProperties,
      ),
    };
  }, [selectedIndividual]);

  const dynamicValidationSchema = useMemo(() => {
    // Extend fields with our validations
    const dynamicFormData = customFieldsExtendValidation(
      config.individualFields.filter(
        (f: CustomField) =>
          f.propertyType === FieldPropertyTypeEnum.metadata ||
          f.propertyType === FieldPropertyTypeEnum.custom,
      ),
      t,
      BaseStepIdEnum.individual_edit,
    );
    // Create schema based on added validations
    // @TODO - OPS-9 - Replace Yup by Zod
    return dynamicFormData.reduce(createYupSchema, {});
  }, [config.individualFields]);

  const methods = useForm<IndividualEditFormValues>({
    mode: 'all',
    criteriaMode: 'all',
    // @TODO - OPS-9 - Replace Yup by Zod
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(
      individualSchema(
        config.individualFields.filter(
          (f: CustomField) =>
            f.propertyType !== FieldPropertyTypeEnum.metadata &&
            f.propertyType !== FieldPropertyTypeEnum.custom,
        ),
      ).shape(dynamicValidationSchema),
    ),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isValid, isSubmitting, isDirty },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    const { individualData, caseMetadata, caseCustomProperties } =
      preSubmitHelper(config.individualFields, formData, 'individual');

    submitIndividualForm({
      individualData,
      individualId: selectedIndividualId,
      caseMetadata,
      caseCustomProperties,
      onClose,
    });
  };

  return (
    <Modal
      isOpen={true}
      size={['full', 'md']}
      onClose={() => {
        onClose();
      }}
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('steps.individual_edit.title')}</ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody m="0" p="6">
          {i18n.exists(`steps.individual_edit.description`) && (
            <Text
              mb="6"
              dangerouslySetInnerHTML={{
                __html: t('steps.individual_edit.description') ?? '',
              }}
            ></Text>
          )}
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <IndividualForm
                isApplicant={
                  selectedIndividual?.roles?.includes(
                    IndividualRoleEnum.applicant,
                  ) || false
                }
              />
            </form>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <Stack direction={['column-reverse', 'row']} spacing={4}>
            <Button variant="outline" onClick={onClose}>
              {t('domain.form.cancel')}
            </Button>
            <Button
              variant="solid"
              isLoading={isSubmitting}
              isDisabled={!isValid || !isDirty}
              onClick={handleSubmit(onSubmit)}
              type="submit"
            >
              {t('domain.form.save')}
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
