import { useRef, useEffect, ReactNode } from 'react';
import {
  Box,
  BoxProps,
  forwardRef,
  useStyleConfig,
  Progress,
  Spinner,
} from '@chakra-ui/react';

export type DropzoneProps = {
  type: 'logo' | 'file';
  children: ReactNode;
  uploadProgress?: number;
  isFocused?: boolean;
  isDisabled?: boolean;
  isDragAccept?: boolean;
} & BoxProps;

export const Dropzone = forwardRef<DropzoneProps, typeof Box>(
  (
    {
      children,
      uploadProgress,
      isFocused,
      isDisabled,
      type,
      isDragAccept,
      ...rest
    },
    ref,
  ) => {
    const dropzoneRef = useRef<HTMLHeadingElement>(null);
    const styles = useStyleConfig('Dropzone', {
      isDragAccept,
    });

    useEffect(() => {
      if (isFocused === false || !dropzoneRef.current) return;
      dropzoneRef.current.focus();
    }, [isFocused, ref]);

    return typeof uploadProgress === 'number' && type === 'file' ? (
      <Box
        h={rest?.h}
        display="flex"
        __css={styles}
        bg="white"
        cursor="not-allowed"
        justifyContent="center"
        alignItems="center"
        data-testid="progress-bar"
      >
        <Progress value={uploadProgress} h="20%" w="80%" hasStripe />
      </Box>
    ) : (
      <Box __css={styles} {...rest} ref={dropzoneRef}>
        {uploadProgress ? <Spinner size="xs" /> : children}
      </Box>
    );
  },
);
