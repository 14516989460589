import {
  CompanyInput,
  CustomProperties,
  IndividualInput,
  Metadata,
} from 'shared-domain';
import { isObjectEmpty } from 'shared-common';
import { useStore } from './use-store';
import { useSubmit } from './use-submit';

type SubmitCustomStepForm = {
  caseMetadata?: Metadata;
  caseCustomProperties?: CustomProperties;
  companyData?: CompanyInput;
  individualData?: IndividualInput;
  individualId?: string | null;
};

export const useSubmitCustomStepForm = (): {
  submitCustomStepForm: (input: SubmitCustomStepForm) => void;
} => {
  const submitStep = useSubmit();
  const {
    metadata,
    customProperties,
    company,
    individuals,
    updateMetadata,
    updateCustomProperties,
    updateCompany,
    updateIndividual,
    addIndividual,
  } = useStore();

  const submitCustomStepForm = (input: SubmitCustomStepForm) => {
    // Save case metadata
    if (input.caseMetadata) {
      updateMetadata({ ...metadata, ...input.caseMetadata });
    }

    // Save case custom properties
    if (input.caseCustomProperties) {
      updateCustomProperties({
        ...customProperties,
        ...input.caseCustomProperties,
      });
    }

    // Save company data
    if (input.companyData && !isObjectEmpty(input.companyData)) {
      const custom_properties = {
        ...company?.custom_properties,
        ...input.companyData.custom_properties,
      };

      updateCompany({
        ...company,
        ...input.companyData,
        custom_properties,
      });
    }

    // Save individual data
    if (input.individualData && !isObjectEmpty(input.individualData)) {
      if (input.individualId) {
        updateIndividual(input.individualId, {
          ...individuals.find((ind) => ind.id === input.individualId),
          ...input.individualData,

          custom_properties: {
            ...individuals.find((ind) => ind.id === input.individualId)
              ?.custom_properties,
            ...input.individualData.custom_properties,
          },
        });
      } else {
        addIndividual(input.individualData);
      }
    }

    // Submit step
    submitStep();
  };

  return {
    submitCustomStepForm,
  };
};
